import { Chip, Container, Fade, Grid, IconButton, InputAdornment, Tooltip, Paper, TextField, Typography, Avatar, Card, CardActionArea } from "@material-ui/core";
import { AddRounded, Delete, Add, Map, Search } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
//import { DATAgetAllGroups, getAvailableGroups} from "../components/DataNet/DataNet";
import * as DATANETAPI from "../components/DataNet/DataNet";
import GroupCard from "../components/GroupCard";
import GroupSkeletonCard from "../components/GorupSkeletonCard";
import GroupsModals from "../components/GroupsModals";
import GroupForm from "../components/GroupsModals/GroupForm";
import PageHeader from "../components/PageHeader";
import { withAuthorization } from "../components/Session";
import ErrorDialog from "../components/ErrorDialog";

const GroupsPage = (props) => {
  const { t } = useTranslation();
  const { programId } = useParams();
  const { authUser } = props;

  const [filterModalOpen, setfilterModalOpen] = useState(false);
  const [mapModalOpen, setmapModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState(0);
  const [groups, setGroups] = useState([]);
  //const [coordinators, setCoordinators] = useState([]);
  const [isFiltered, setisFiltered] = useState(false);
  const [filterObject, setfilterObject] = useState(null);
  const [currentposition, setcurrentposition] = useState();
  const [editModalOpen, seteditModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [inputText, setinputText] = useState("");
  const [areGroupsLoaded, setAreGroupsLoaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const searchHandler = (event) => {
    setinputText(event.target.value);
    let filteredGroups = [];
    backendGroups.forEach((group) => {
      if (group.name.toUpperCase().includes(event.target.value.toUpperCase())) {
        filteredGroups.push(group);
      }
    });
    event.target.value !== "" && setisFiltered(true);
    setGroups(filteredGroups);
  };

  const filter = (filterObject) => {
    let filteredGroups = [];
    backendGroups.forEach((group) => {
      let isValid = true;
      let isCoordinatorinGroup = true;
      if (!!filterObject.coordinator) {
        isCoordinatorinGroup = false;
        group.coordinators.forEach((coordinator) => {
          if (coordinator.userid === filterObject.coordinator) {
            isCoordinatorinGroup = true;
          }
        });
      }
      let statusCheck = filterObject.status === -1 ? true : group.status === filterObject.status;
      isValid =
        isCoordinatorinGroup &&
        group.devicesnumber >= filterObject.devices.min &&
        group.devicesnumber <= filterObject.devices.max &&
        group.require_rescuers >= filterObject.rescuers.min &&
        group.require_rescuers <= filterObject.rescuers.max &&
        statusCheck;

      isValid && filteredGroups.push(group);
      setfilterObject(filterObject);
    });
    setGroups(filteredGroups);
    setisFiltered(true);
  };

  const resetfilters = () => {
    setinputText("");
    setGroups(backendGroups);
    setfilterObject(null);
    setisFiltered(false);
  };

  const editGroup = (groupid) => {
    seteditModalOpen(true);
    groups.forEach((group) => {
      group.groupid === groupid && setSelectedGroup(group);
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setcurrentposition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });

    DATANETAPI.getAllGroups(programId).then((snap) => {
      if (snap.status < 0) {
        setGroups([]);
        setAreGroupsLoaded(true);
        // console.log("error en peticion getAllGroups");
        setErrorMsg(t(`error.connection_${snap.status}`));
      } else {
        backendGroups = snap.data;
        // console.log("datos de los grupos", backendGroups);
        if (backendGroups !== undefined) {
          setGroups(
            Object.values(backendGroups).map((v) => ({
              ...v,
              //   comsTypes: Math.random() < 0.3 ? ["Standalone"] : ["Sigfox", "Wifi"].sort(() => Math.random() - 0.5).slice(0, Math.floor(Math.random() * 2) + 1),
            }))
          );
          setAreGroupsLoaded(true);
        } else {
          setGroups([]);
          setAreGroupsLoaded(true);
        }
      }
    });
  }, [programId, updatedData]);

  const updateData = (isUpdated) => {
    setAddModalOpen(isUpdated);
    seteditModalOpen(isUpdated);
    setUpdatedData(1 + updatedData);
  };

  return (
    <Container>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <PageHeader title={t("groups.title")} />
        </Grid>
        <Grid item>
          <Paper style={{ padding: 12 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item container alignItems="center" xs={6} justify="flex-start">
                <Grid item>
                  <TextField
                    label={t("common.search")}
                    value={inputText}
                    onChange={searchHandler}
                    margin="dense"
                    placeholder={t("common.search")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  {/*<IconButton onClick={() => setfilterModalOpen(true)}>
                    <FilterList />
                  </IconButton>*/}
                  <GroupsModals
                    open={filterModalOpen}
                    handleClose={setfilterModalOpen}
                    type="filter"
                    coordinatorsList={coordinatorsList}
                    status={-1}
                    maxDevices={99}
                    maxRescuers={99}
                    setFilter={filter}
                    filterObject={filterObject}
                    currentUser={authUser}
                  />
                </Grid>
                <Grid item>
                  {isFiltered && (
                    <Fade in={isFiltered}>
                      <IconButton aria-label="delete" onClick={resetfilters}>
                        <Delete color="error" />
                      </IconButton>
                    </Fade>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Grid item>
                  <Tooltip
                    placement="top"
                    enterDelay={750}
                    title={
                      <Typography variant="body1" style={{ fontSize: 12 }}>
                        {t("groups.toolbar.tooltip.map")}
                      </Typography>
                    }
                  >
                    <span>
                      <IconButton onClick={() => setmapModalOpen(true)}>
                        <Map color="primary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <GroupsModals open={mapModalOpen} handleClose={setmapModalOpen} groups={groups} type="map" currentUser={authUser} />
                </Grid>
                <Grid item>
                  <Tooltip
                    placement="top"
                    enterDelay={750}
                    title={
                      <Typography variant="body1" style={{ fontSize: 12 }}>
                        {t("groups.toolbar.tooltip.add")}
                      </Typography>
                    }
                  >
                    <span>
                      <IconButton aria-label="add" onClick={() => setAddModalOpen(true)}>
                        <Add color="primary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <GroupForm open={addModalOpen} handleClose={updateData} isNew coordinatorsList={coordinatorsList} currentposition={currentposition} currentUser={authUser} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {isFiltered && !!filterObject && (
          <Grid item container style={{ marginTop: -40 }}>
            {!!filterObject.coordinator && <Chip avatar={<Avatar>#</Avatar>} label={`Coordinador del grupo ${filterObject.coordinator}`} style={{ margin: 8 }} />}
            <Chip avatar={<Avatar>#</Avatar>} label={`Dispositivos ${filterObject.devices.min} - ${filterObject.devices.max}`} style={{ margin: 8 }} />
            <Chip avatar={<Avatar>#</Avatar>} label={`Rescatadores ${filterObject.rescuers.min} - ${filterObject.rescuers.max}`} style={{ margin: 8 }} />
            <Chip avatar={<Avatar>#</Avatar>} label={`Estado del grupo ${filterObject.status}`} style={{ margin: 8 }} />
          </Grid>
        )}

        <Grid item container direction="row" spacing={3}>
          {!areGroupsLoaded && [0, 1, 2, 3, 4, 5].map((i) => <GroupSkeletonCard key={i} />)}

          {groups.map((group, i) => (
            <Grid item lg={4} md={4} xl={3} xs={12} sm={6}>
              <GroupCard data={group} edit={editGroup} index={i} />
            </Grid>
          ))}

          {!isFiltered && (
            <Grid item lg={4} md={4} xl={3} xs={12} sm={6}>
              <Card style={{ height: "100%", minHeight: 275, borderRadius: 12, background: "#f7f8fa" }} variant="outlined">
                <CardActionArea style={{ height: "100%" }} onClick={() => setAddModalOpen(true)}>
                  <Grid
                    style={{
                      height: "100%",
                      // border: "2px",
                      // borderStyle: "ridge",
                      borderRadius: 12,
                    }}
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                  >
                    <AddRounded style={{ fontSize: 40 }} color="primary" />
                    <Typography variant="body2" color="textSecondary">
                      {t("groups.add.new")}
                    </Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          )}
          {groups.length === 0 && areGroupsLoaded && (
            <Grid container justify="center" item xs={12} alignContent="center">
              <Typography variant="h4" color="textSecondary">
                {t("groups.notFound")}
              </Typography>
            </Grid>
          )}
          <GroupForm open={editModalOpen} handleClose={updateData} coordinatorsList={coordinatorsList} groupData={selectedGroup} currentUser={authUser} />
        </Grid>
      </Grid>
      <ErrorDialog errorMsg={errorMsg} clearError={() => setErrorMsg("")} />
    </Container>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(GroupsPage);

var backendGroups = [];

var coordinatorsList = [];
