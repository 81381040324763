import { Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const Programs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="textPrimary">{t("breadcrumbs.allPrograms")}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" color="secondary" style={{ marginTop: 18 }}>
        {t("navigation.programs")}
      </Typography>
    </>
  );
};

export default Programs;
/**  <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 38 }}>
      <Typography variant="h4" color="textPrimary">
        {t("breadcrumbs.allPrograms")}
      </Typography>
    </Breadcrumbs> */
