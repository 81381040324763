import React from "react";
import { Modal, Paper, makeStyles, Container, Grid, Dialog, AppBar, Toolbar, Slide, Button, Typography, IconButton } from "@material-ui/core";
import AddGroupModal from "./AddGroupModal";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GroupForm = (props) => {
  const classes = useStyles();
  const { open, handleClose, type, isNew } = props;
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => handleClose(false)} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {isNew ? t("groups.new.title.add") : t("groups.new.title.edit")}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <AddGroupModal {...props} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default GroupForm;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
