import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Divider, Grid, IconButton, Paper, SvgIcon, Tooltip, Typography } from "@material-ui/core";
import { green, orange, red, lightGreen, lime, yellow, amber, deepOrange, blueGrey, purple, pink, grey } from "@material-ui/core/colors";
import {
  Error,
  Check,
  Warning,
  BatteryFull,
  Battery90,
  Battery80,
  Battery60,
  Battery50,
  Battery30,
  Battery20,
  BatteryAlert,
  FlashOn,
  BatteryUnknown,
  CropPortrait,
  Sync,
  Face,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import getDeviceStatus from "../../functions/getDeviceStatus";
import Pads_Icon from "../../assets/images/Parches_2.png";
import batteryRemainingCalc from "../../functions/BatteryChargeCalc";

const deviceStatus = ({ status, t, hasComs, software }) => {
  //console.log("DEV STATUS ->", status);
  var dev_status = status.device_status; //dev_status_ini;
  var type_error = status.main;

  const syncOutOfDate = moment().diff(dev_status?.updateDate, "months") >= (hasComs ? 3 : 12) || dev_status?.updateSource === 0;
  const batteryResult = batteryRemainingCalc({
    hasComs: hasComs,
    syncDate: moment(dev_status?.updateDate),
    battery: dev_status?.batteryStatus,
    software: software,
    updateSource: dev_status?.updateSource,
  });
  const statusErrors = getDeviceStatus(status, syncOutOfDate, batteryResult, software);
  const wrongBattery = dev_status?.batteryStatus.name !== "BEXENCARDIO" && dev_status?.batteryStatus.name !== "DEMO";
  const doNotWhowBattery = statusErrors?.errors?.some((s) => s?.id == 31) || wrongBattery;
  const demoPadsInNormalBattery = statusErrors?.errors?.some((s) => s?.id == 23);

  return (
    <Grid container style={{ marginBottom: 12 }}>
      <Grid item spacing={2} xs={12}>
        <Grid item xs={12} container style={{ flexDirection: "row" }}>
          <Typography variant="h6" color="textPrimary">
            {t("device.statusmodal.title")}
            &nbsp;
            {"·"}
            &nbsp;
          </Typography>
          {status?.is_demo && (
            <Typography variant="h6" style={{ color: orange[500] }}>
              {"Demo ·"}
              &nbsp;
            </Typography>
          )}

          <DeviceStatusText status={status} value={type_error} batteryResult={batteryResult} software={software} />
          {syncOutOfDate && !wrongBattery && (
            <Typography variant="body1" style={{ fontWeight: 600, marginBottom: 9, marginTop: 6 }} color="textSecondary">
              {t(`NotSync.deviceDetailCard.${dev_status?.updateSource === 0 ? (hasComs ? "DefaultComs" : "Default") : hasComs ? "coms" : "Nocoms"}`)}&nbsp;
            </Typography>
          )}
          {wrongBattery && (
            <Paper
              variant="outlined"
              style={{
                borderRadius: 16,
                padding: 18,
                marginTop: 18,
                marginBottom: 18,
                borderColor: orange[400],
                backgroundColor: orange[50],
              }}
            >
              <Grid container direction="row" justifyContent="flex-start" alignContent="center">
                <Typography variant="body2" color="textSecondary">
                  {t("status.simple.battery_unknown")}
                </Typography>
              </Grid>
            </Paper>
          )}
        </Grid>

        {!!status?.update &&
          Object.keys(status?.update).map(
            (k, i) => status?.update[k] == 1 && <Chip key={i} icon={<Warning style={{ color: orange[500], paddingLeft: 6 }} />} style={{ margin: 3, backgroundColor: orange[50] }} label={t(k)} />
          )}
        {!syncOutOfDate &&
          getDeviceStatus(status, syncOutOfDate, batteryResult, software).errors.map((err, i) => (
            <DeviceStatusChips key={i} property={"error"} value={`eREG_${err?.id}`} is_id={true} type={err?.type} date={err?.date} />
          ))}
        <Grid item container xs={12}>
          <Paper style={{ padding: 16, background: status?.is_demo ? orange[50] : grey[50], borderRadius: 16, marginTop: 12 }} elevation={0}>
            <Grid item container xs={12}>
              <Grid item xs={12} container direction="row">
                <Typography color="secondary" style={{ fontWeight: 500 }}>
                  {t("status.simple.update_date")}
                </Typography>
                &nbsp;
                {"·"}
                &nbsp;
                {dev_status?.updateSource === 1 && wrongBattery ? (
                  <UpdateTimeItem property="status.simple.update_date" value={dev_status?.lastFullUpdateUsb} updateSource={dev_status?.updateSource} />
                ) : (
                  <UpdateTimeItem property="status.simple.update_date" value={dev_status?.updateDate} updateSource={dev_status?.updateSource} />
                )}
              </Grid>
              <Grid container item xs={12} style={{ marginTop: 9 }}>
                <Grid item xs={9} md={10}>
                  <Typography variant="h6" style={{ fontWeight: 500, marginBottom: -3 }}>
                    {t(`status.simple.update_source_${dev_status?.updateSource}`)}
                  </Typography>

                  <Typography variant="body2">
                    {t("status.simple.test_type")}
                    &nbsp;
                    {"·"}
                    &nbsp;
                    {
                      // para que no aparezcan datos del test cuando son datos de fábrica, se oculta este campo
                      dev_status?.updateSource !== 0 ? t(`status.simple.test_type_${dev_status?.testType}`) : t(`status.simple.update_source_${dev_status?.updateSource}`)
                    }
                  </Typography>
                </Grid>
                <Grid item xs={3} md={2} container justifyContent="center">
                  <Sync color="action" style={{ width: 52, height: 52 }} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* <DeviceStatusItem status={status} value={type_error} />*/}
        <Grid item xs={12} container spacing={2} /*style={{ marginLeft: 34 }}*/></Grid>
      </Grid>

      <Grid item xs={12} container style={{ marginTop: 18 }}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ padding: "2px 0", marginTop: 16, marginBottom: 12 }}>
            {t("navigation.accessories")}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item container xs={12} md={6}>
            <Paper style={{ padding: 16, background: status?.is_demo ? orange[50] : grey[50], borderRadius: 16, width: "100%" }} elevation={0}>
              <Grid item container xs={12}>
                <Grid item xs={12} container direction="row">
                  <Typography color="secondary" style={{ fontWeight: 500 }}>
                    {t("common.battery")}
                    &nbsp;
                  </Typography>
                  {status?.is_demo && <Typography style={{ color: orange[500], fontWeight: 500 }}>{` · Demo`}</Typography>}
                  {batteryResult < 10 && !syncOutOfDate && !doNotWhowBattery && (
                    <Typography style={{ color: red[500], fontWeight: 500 }}>
                      &nbsp;
                      {`· ${t("error_eREG_33")}`}
                    </Typography>
                  )}
                  {batteryResult > 10 && batteryResult <= 15 && !syncOutOfDate && doNotWhowBattery && (
                    <Typography style={{ color: orange[500], fontWeight: 500 }}>
                      &nbsp;
                      {`· ${t("error_eREG_32")}`}
                    </Typography>
                  )}
                  {doNotWhowBattery && (
                    <Typography style={{ color: red[500], fontWeight: 500 }}>
                      &nbsp;
                      {`· ${t("error_eREG_31.min")}`}
                    </Typography>
                  )}
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 9 }}>
                  <Grid item xs={8} md={8}>
                    <Typography variant="h6" style={{ fontWeight: 700, marginBottom: -6, color: (syncOutOfDate || doNotWhowBattery) && blueGrey[300] }}>
                      {syncOutOfDate || doNotWhowBattery ? t("common.NotAvailable") : ` ${batteryResult}% `}
                    </Typography>
                    <Typography variant="body2">{t("status.simple.battery_charge")}</Typography>
                  </Grid>
                  <Grid item xs={4} md={4} container justifyContent="center">
                    <BatteryItem value={batteryResult} nowValue={batteryResult} syncOutOfDate={syncOutOfDate || doNotWhowBattery} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Paper style={{ padding: 16, background: status?.is_demo || demoPadsInNormalBattery ? orange[50] : grey[50], borderRadius: 16 }} elevation={0}>
              <Grid item container xs={12}>
                <Grid item xs={12} container direction="row">
                  <Typography color="secondary" style={{ fontWeight: 500 }}>
                    {t("common.pads")}
                  </Typography>
                  <Typography style={{ fontWeight: 500, color: orange[500] }}>
                    &nbsp;{" "}
                    {moment().isAfter(moment(dev_status?.padsStatus?.expiration_date)) && // parches expirados
                      dev_status?.padsStatus?.is_connected && // parches conectados
                      !syncOutOfDate && // sincronización realizada
                      !demoPadsInNormalBattery && // no hay parches demo conectados
                      moment(dev_status?.padsStatus?.expiration_date).format("YYYY-MM") !== "0001-01" && // fecha de los parches no válida
                      `· ${t("error_eREG_20")}`}
                  </Typography>
                  {syncOutOfDate && moment(dev_status?.padsStatus?.expiration_date).format("YYYY-MM") !== "0001-01" && moment().isAfter(moment(dev_status?.padsStatus?.expiration_date)) && (
                    <Tooltip title={<Typography variant="body2">{t("pads.mightExpired.tooltip")}</Typography>} placement="top">
                      <Typography style={{ fontWeight: 500, color: orange[500] }}> {`· ${t("pads.mightExpired")}`}</Typography>
                    </Tooltip>
                  )}
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 9 }}>
                  <Grid item xs={8}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 700,
                        marginBottom: -6,
                        color: (syncOutOfDate || !dev_status?.padsStatus?.is_connected) && blueGrey[300],
                      }}
                    >
                      {status?.is_demo
                        ? t("common.demoMode")
                        : demoPadsInNormalBattery
                        ? t("error_eREG_23")
                        : syncOutOfDate
                        ? t("common.NotAvailable")
                        : dev_status?.padsStatus?.is_connected && moment(dev_status?.padsStatus?.expiration_date).format("YYYY-MM-DD") !== "0001-01-01"
                        ? moment(dev_status?.padsStatus?.expiration_date).format("YYYY-MM")
                        : t("pads.Noconnected")}
                    </Typography>
                    <Typography variant="body2">
                      {!dev_status?.padsStatus?.is_connected || status?.is_demo || demoPadsInNormalBattery || moment(dev_status?.padsStatus?.expiration_date).format("YYYY-MM-DD") == "0001-01-01"
                        ? ""
                        : t("device.details.accesories.expirationDate")}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="center">
                    <img style={{ height: 48 }} src={Pads_Icon} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

//
const UpdateTimeItem = ({ value, updateSource }) => {
  const { t } = useTranslation();
  const time = () => {
    if (updateSource >= 2 && updateSource <= 4) {
      return moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss");
    } else {
      return moment.utc(value).format("YYYY-MM-DD HH:mm:ss");
    }
  };

  return time();
};

const DeviceStatusChips = ({ property, value, unit, is_id, type, date }) => {
  const { t } = useTranslation();
  return (
    <Chip
      icon={type == "warning" ? <Warning style={{ color: orange[500], paddingLeft: 6 }} /> : <Error style={{ color: red[500] }} />}
      style={{ margin: 3, background: type == "warning" ? orange[50] : red[50] }}
      label={is_id ? t(property + "_" + value, { date: date }) : `${value} ${unit}`}
    />
  );
};

const DeviceStatusText = ({ status, syncOutOfDate, batteryResult, software }) => {
  const { t } = useTranslation();

  const [state, setState] = useState(0);

  useEffect(() => {
    if (!!status) {
      const errors = getDeviceStatus(status, syncOutOfDate, batteryResult, software);
      /* Para evitar mostar el estado de los test si está desincronizado, 
      se comprueba primero la fecha de la sincronización */
      if (syncOutOfDate) {
        setState(errors.statusResult >= 2 ? errors.statusResult : 1);
      } else {
        setState(errors.statusResult);
      }
    }
  }, [status]);

  return (
    <Typography variant="h6" style={{ fontWeight: 500, marginBottom: -6, color: state === 1 ? orange[500] : state == 2 ? red[500] : grey[700] }}>{` ${t("status.simple.state_" + state)} `}</Typography>
  );
};

const BatteryItem = ({ value, syncOutOfDate }) => {
  const ICONSIZE = 48;
  if (syncOutOfDate) {
    return <BatteryUnknown style={{ fontSize: ICONSIZE, color: blueGrey["300"] }} fontSize="medium" />;
  } else {
    return (
      <>
        {value === 100 && <BatteryFull style={{ fontSize: ICONSIZE, color: green["700"] }} fontSize="medium" />}
        {value !== 100 && value >= 90 && <Battery90 style={{ fontSize: ICONSIZE, color: lightGreen["700"] }} fontSize="medium" />}
        {value < 90 && value >= 80 && <Battery80 style={{ fontSize: ICONSIZE, color: lime["700"] }} fontSize="medium" />}
        {value < 80 && value >= 60 && <Battery60 style={{ fontSize: ICONSIZE, color: yellow["700"] }} fontSize="medium" />}
        {value < 60 && value >= 50 && <Battery50 style={{ fontSize: ICONSIZE, color: amber["700"] }} fontSize="medium" />}
        {value < 50 && value > 20 && <Battery30 style={{ fontSize: ICONSIZE, color: orange["700"] }} fontSize="medium" />}
        {value <= 20 && value > 10 && <Battery20 style={{ fontSize: ICONSIZE, color: deepOrange["700"] }} fontSize="medium" />}
        {value <= 10 && <BatteryAlert style={{ fontSize: ICONSIZE, color: red["700"] }} fontSize="medium" />}
      </>
    );
  }
};

export default deviceStatus;

var dev_status_ini = {
  updateDate: "29/07/2021",
  updateSource: 4, // 0: Unknown, 1: USB, 2: Sigfox, 3: Wifi, SigfoxNew:4
  testType: 40, // type of test
  Temperature: 35,
  errorNum: 0,
  testResult: 0, // 0: Ok
  batteryStatus: {
    expiration_date: "20/03/2026",
    nominal_capacity: "4200",
    remaining_percentage: "50",
    is_connected: true,
  },
  padsStatus: {
    expiration_date: "20/09/2023",
    is_connected: false,
    event_time: "11:21:45",
    envent_id: 0,
  },

  commsStatus: {
    accelerometer: 0,
    wifi: 0,
    sigfox: 0,
    gps: 0,
  },
  errorStatus: {
    error_id: 0,
    test_result: 0,
  },

  sigfoxNewErrorStatus: {
    error_code_defib: 5,
    error_code_ADS: 34,
    error_code_dc_18v: 0,
    error_code_dc_main: 0,
    error_code_audio: 0,
    error_electrodes: 0,
    battery_soc: 0,
    battery_volt: 0,
    battery_Tmax: 0,
    battery_Tmin: 0,
  },
};
