import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Button, Paper, Grid } from "@material-ui/core";
import { purple } from "@material-ui/core/colors";
import { Android, Apple, DescriptionOutlined, ShopOutlined, Web } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";

const SourceCard = ({ source }) => {
  const { t } = useTranslation();
  return (
    <Paper variant="outlined" style={{ borderRadius: 9, background: "#f7f8fa", padding: 16 }}>
      <Grid container direction="row" justifyContent="space-between" style={{ flex: 1, minHeight: "72%" }}>
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary" style={{ fontWeight: 500, marginBottom: 9 }}>
            {source.title}
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            {t(source.subtitle)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justifyContent="flex-end" xs={12} style={{ alignItems: "flex-end" }}>
        {!!source.buttons ? (
          source.buttons.map((b) => (
            <Button
              size="small"
              color="primary"
              variant="contained"
              disableElevation
              style={{ textTransform: "none", marginTop: 26, borderRadius: 20, marginLeft: 6 }}
              onClick={(e) => {
                e.preventDefault();
                if (source.has_link) {
                  window.open(b.url, "_blank");
                } else {
                  window.location.href = b.url;
                }
              }}
            >
              {b.type == "android" ? <Android style={{ marginRight: 6 }} /> : <Apple style={{ marginRight: 6 }} />}
              {source.type === "app" && t("sources.download.App")}
              {source.type === "web" && t("sources.download.web")}
              {source.type === "document" && t("sources.download.Document")}
            </Button>
          ))
        ) : (
          <Button
            size="small"
            color="primary"
            variant="contained"
            disableElevation
            style={{ textTransform: "none", marginTop: 26, borderRadius: 20, marginBottom: 6 }}
            onClick={(e) => {
              e.preventDefault();
              if (source.has_link) {
                window.open(source.link, "_blank");
              } else {
                window.location.href = source.link;
              }
            }}
          >
            {source.type === "app" && t("sources.download.App")}
            {source.type === "web" && t("sources.download.web")}
            {source.type === "document" && t("sources.download.Document")}
          </Button>
        )}
      </Grid>
    </Paper>
  );
};

export default SourceCard;
