import {
  Container,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import cardioLogo from "../constants/images/logo_color_Cardio_Protecccion.png";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import * as DATANET_AUth from "../components/DataNet/DataNetAuth";
import RequestPasswordButton from "../components/RequestPasswordButton";

const RequestPasswordChange = ({ firebase }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const onchange = (e) => {
    setEmail(e.target.value.toLowerCase().trim());
    !!error && setError(null);
  };

  return (
    <Container style={{ marginTop: -40 }}>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ minHeight: "90vh" }}
      >
        <Grid container item xs={12} md={5}>
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              style={{ minHeight: "80vh", padding: 16, position: "relative" }}
            >
              <Grid container justify="center" spacing={4}>
                <Grid style={{ marginTop: 32 }}>
                  <Grid item xs={12} container justify="center">
                    <img src={cardioLogo} style={{ height: 80 }} />
                  </Grid>
                  <Grid item xs={12} container justify="center">
                    <Typography
                      variant="h6"
                      style={{
                        paddingTop: 16,
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: 400,
                        //lineHeight: 1.3333,
                        marginBottom: 0,
                      }}
                    >
                      {t("resetPasswordTitleEmail")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} container>
                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 16,
                      color: "#202124",
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 400,
                      letterSpacing: 0.1,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("resetPasswordTextEmail")}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label={t("email")}
                    value={email}
                    onChange={onchange}
                    variant="outlined"
                    placeholder="demo@bexencardio.com"
                    fullWidth
                  />
                  {!!error && <FormHelperText error>{t(error)}</FormHelperText>}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-end"
                  style={{ paddingBottom: "10vh" }}
                >
                  <RequestPasswordButton reusable email={email} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="center"
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 8,
                  left: 0,
                }}
              >
                <Link to={ROUTES.HOME} style={{ textDecoration: "none" }}>
                  <Typography color="textSecondary">{t("home")}</Typography>
                </Link>
                <Typography
                  color="textSecondary"
                  style={{ marginRight: 4, marginLeft: 4 }}
                >
                  ·
                </Typography>
                <a
                  href={
                    "mailto:info@bexencardio.com?Subject=Error%20en%20el%20solicitud%20para%20cambio%20de%20contraseña"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Typography color="textSecondary">{t("help")}</Typography>
                </a>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RequestPasswordChange;
