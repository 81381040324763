import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  IconButton,
  Hidden,
  CircularProgress,
} from "@material-ui/core";
import { MailOutline, PersonOutline, PhoneOutlined, SchoolOutlined, FavoriteBorderOutlined, VpnKeyOutlined, LockOutlined, VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";

import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { grey, red } from "@material-ui/core/colors";
import * as DATANETAPI from "../DataNet/DataNet";
import * as ROUTES from "../../constants/routes";
import LangSelect from "../Navigation/LangSelect";
import ErrorDialog from "../ErrorDialog";
import { passwordPattern } from "../../constants/validations";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import useWindowDimensions from "../../functions/useWindowDimensions";

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const INITIAL_STATE = {
  email: "",
  name: "",
  surnames: "",
  serial: "",
  key: "",
  phone: "",
  program_name: "",
  confirmation_email: "",
  password: "",
  repeat_password: "",
  conditions: false,
  lang: "es",
};

const INITIAL_ERROR_STATE = {
  email: null,
  confirmation_email: null,
  name: null,
  surnames: null,
  serial: null,
  key: null,
  phone: null,
  program_name: null,
  password: null,
  repeat_password: null,
  conditions: false, //para la validación
};

const CreateAccount = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();

  const [accountForm, setAccountForm] = useState(INITIAL_STATE);
  const [errorId, setErrorId] = useState(0);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorForm, setErrorForm] = useState(INITIAL_ERROR_STATE);
  const [isValid, setIsvalid] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pdfWidth, setPdfWidth] = useState(0);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handleCloseError = () => {
    setOpenError(false);
    if (modalSuccess) {
      setAccountForm(INITIAL_STATE);
      setErrorForm(INITIAL_ERROR_STATE);
      history.push(ROUTES.SIGN_IN);
    }
  };

  const handleLangChange = (lang) => {
    setAccountForm({
      ...accountForm,
      lang: lang,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    setShowRepeatPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    let breakP;
    if (width <= 600) {
      breakP = BP.xs;
    } else if (width < 960) {
      breakP = BP.sm;
    } else if (width <= 1280) {
      breakP = BP.md;
    } else if (width < 1920) {
      breakP = BP.lg;
    } else {
      breakP = BP.xl;
    }
    setPdfWidth(width * breakP);
  }, [width]);

  const onSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    //createAccount
    handleLangChange(i18n.language.split("-")[0]);
    DATANETAPI.createAccount({ ...accountForm, lang: i18n.language }).then((snap) => {
      setIsLoading(false);
      if (snap?.status < 0) {
        setModalSuccess(false);
        setErrorId(4);
        setOpenError(true);
        setConnectionErrorMsg(t(`error.connection_${snap.status}`));
      } else {
        if (snap.status !== undefined) {
          // request finished
          if (snap.status === 1) {
            // SUCCESSn
            setModalSuccess(true);
            setErrorId(1);
            setOpenError(true);
          } else {
            // ERROR
            setModalSuccess(false);
            setErrorId(snap.status);
            setOpenError(true);
            if (snap.status === 3 || snap.status === 4) {
              setErrorForm((errorForm) => ({
                ...errorForm,
                key: t("account.validation.error.3-4"),
                serial: t("account.validation.error.3-4"),
              }));
            }
            if (snap.status === 6) {
              setErrorForm((errorForm) => ({
                ...errorForm,
                email: t("account.validation.error.6"),
                confirmation_email: t("account.validation.error.6"),
              }));
            }
            if (snap.status === 5) {
              setErrorForm((errorForm) => ({
                ...errorForm,
                serial: t("account.validation.error.3-4"),
              }));
            }
          }
        } else {
          //error during request
          setModalSuccess(false);
          setErrorId(4);
          setOpenError(true);
        }
      }
    });
  };

  useEffect(() => {
    let isValid = true;
    Object.keys(errorForm).forEach((x) => {
      if (x == "conditions") {
        isValid = accountForm.conditions && isValid;
      } else if (x == "phone") {
        isValid = !errorForm[x] && isValid;
      } else {
        isValid = !errorForm[x] && accountForm[x] !== "" && isValid;
      }
    });
    setIsvalid(isValid);
  }, [errorForm]);

  useEffect(() => {
    handleLangChange(i18n.language.split("-")[0]);
  }, []);

  const validEmail = (field) => {
    var email = accountForm[field];
    if (email !== "") {
      var errorMsg = new RegExp(emailPattern).test(email) ? null : t("account.error.format_email");
      var eForm = { ...errorForm, [field]: errorMsg };
      setErrorForm(eForm);
      if (accountForm.email != "" && accountForm.confirmation_email != "" && !!!errorMsg) {
        validEmails();
      }
    } else {
      setErrorForm({ ...errorForm, [field]: t("account.error.empty") });
    }
  };

  const validEmails = () => {
    var errorMsg = accountForm.email === accountForm.confirmation_email ? null : t("account.error.repeat_email");

    var eForm = { ...errorForm, confirmation_email: errorMsg, email: errorMsg };
    setErrorForm(eForm);
  };

  const validPassword = (field) => {
    const psw = accountForm[field];
    var msg;
    if (psw != "") {
      msg = new RegExp(passwordPattern).test(psw) && psw.length > 8 ? null : t("account.error.password");

      if (msg == null && accountForm.password != "" && accountForm.repeat_password != "" && accountForm.password.length < 72) {
        validRepeatPassword();
      } else if (accountForm.password.length >= 72) {
        msg = t("account.error.too_long");
        setErrorForm({ ...errorForm, [field]: msg });
      } else {
        setErrorForm({ ...errorForm, [field]: msg });
      }
    } else {
      msg = t("account.error.empty");

      setErrorForm({ ...errorForm, [field]: msg });
    }
  };

  const validRepeatPassword = () => {
    var errorMsg = accountForm.password === accountForm.repeat_password ? null : t("account.error.repeat_password");

    setErrorForm({
      ...errorForm,
      repeat_password: errorMsg,
      password: errorMsg,
    });
  };

  const validPhone = () => {
    const rege1 = new RegExp("/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im");
    const rege2 = new RegExp("^([+]*[(][0-9]{1,3}[)])?[\\s]?([0-9]{2,4}[\\s.-]?){2,4}$");
    const isEmpty = accountForm.phone === "";

    var validformat = rege1.test(accountForm.phone) || rege2.test(accountForm.phone);

    setErrorForm((errorForm) => ({
      ...errorForm,
      phone: !isEmpty ? (validformat ? null : t("account.error.format_phone")) : t("account.error.empty"),
    }));
  };

  const validSerial = () => {
    var msg = accountForm.serial === "" ? t("account.error.empty") : new RegExp("^2007[0-9]{5,5}$").test(accountForm.serial) ? null : t("account.error.format_sn");
    setErrorForm((errorForm) => ({ ...errorForm, serial: msg }));
  };

  return (
    <>
      <Grid item xs={12} container justify="center">
        <Typography
          variant="h6"
          style={{
            fontSize: 24,
            textAlign: "center",
            fontWeight: 400,
            //lineHeight: 1.3333,
            marginBottom: 0,
          }}
        >
          {t("account.wellcome")}
        </Typography>
        <Grid item xs={12} container justify="center">
          <Typography variant="h4" gutterBottom style={{ color: "#9933CC" }}>
            {t("account.title")}
          </Typography>

          <Hidden xsDown>
            <div style={{ height: 32 }}></div>
          </Hidden>
        </Grid>
      </Grid>

      {/** contenedor de los fileds */}
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="email"
            variant="outlined"
            name="accountForm[email]"
            value={accountForm.email}
            onChange={(e) =>
              setAccountForm({
                ...accountForm,
                email: e.target.value.trim(),
              })
            }
            onBlur={() => validEmail("email")}
            error={!!errorForm.email}
            helperText={errorForm.email}
            placeholder="email@mail.com"
            type="text"
            required
            label={t("common.email")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline color={!!errorForm.email ? "error" : "primary"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} justify="center">
          <TextField
            fullWidth
            id="confirmation_email"
            variant="outlined"
            name="accountForm[confirmation_email]"
            value={accountForm.confirmation_email.trim()}
            onChange={(e) =>
              setAccountForm({
                ...accountForm,
                confirmation_email: e.target.value,
              })
            }
            onBlur={() => validEmail("confirmation_email")}
            error={!!errorForm.confirmation_email}
            helperText={errorForm.confirmation_email}
            type="text"
            required
            label={t("common.repemail")}
            placeholder="email@mail.com"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline color={!!errorForm.confirmation_email ? "error" : "primary"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="password"
            variant="outlined"
            name="accountForm[password]"
            value={accountForm.password}
            onChange={(e) =>
              setAccountForm({
                ...accountForm,
                password: e.target.value,
              })
            }
            onBlur={() => validPassword("password")}
            error={!!errorForm.password}
            helperText={errorForm.password}
            type={showPassword ? "text" : "password"}
            label={t("common.password")}
            placeholder="XXXXXXXXXXXXXXX"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined color={!!errorForm.password ? "error" : "primary"} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                    {showPassword ? <VisibilityOutlined color={showRepeatPassword ? "primary" : ""} /> : <VisibilityOffOutlined color={showRepeatPassword ? "primary" : ""} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="repeat_password"
            variant="outlined"
            name="accountForm[repeat_password]"
            value={accountForm.repeat_password}
            onChange={(e) =>
              setAccountForm({
                ...accountForm,
                repeat_password: e.target.value,
              })
            }
            onBlur={() => validPassword("repeat_password")}
            error={!!errorForm.repeat_password}
            helperText={errorForm.repeat_password}
            type={showRepeatPassword ? "text" : "password"}
            label={t("common.repeat_password")}
            placeholder="XXXXXXXXXXXXXXX"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined color={!!errorForm.repeat_password ? "error" : "primary"} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                    {showRepeatPassword ? <VisibilityOutlined color={showRepeatPassword ? "primary" : ""} /> : <VisibilityOffOutlined color={showRepeatPassword ? "primary" : ""} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            variant="outlined"
            name="accountForm[name]"
            value={accountForm.name}
            onChange={(e) => {
              setAccountForm({
                ...accountForm,
                name: e.target.value.toUpperCase(),
              });
            }}
            onBlur={() => {
              var is_valid = accountForm.name != "" && accountForm.name.length < 50;
              setErrorForm({
                ...errorForm,
                name: is_valid ? null : t("account.error.name"),
              });
            }}
            error={!!errorForm.name}
            helperText={errorForm.name}
            required
            type="text"
            label={t("common.name")}
            placeholder={t("common.name")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline color={!!errorForm.name ? "error" : "primary"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="surnames"
            variant="outlined"
            name="accountForm[surnames]"
            value={accountForm.surnames}
            onChange={(e) => {
              setAccountForm({
                ...accountForm,
                surnames: e.target.value.toUpperCase(),
              });
            }}
            onBlur={() => {
              var is_valid = accountForm.surnames != "" && accountForm.surnames.length < 100;
              setErrorForm({
                ...errorForm,
                surnames: is_valid ? null : t("account.error.surnames"),
              });
            }}
            error={!!errorForm.surnames}
            helperText={errorForm.surnames}
            required
            type="text"
            label={t("common.surnames")}
            placeholder={t("common.surnames")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline color={!!errorForm.surnames ? "error" : "primary"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="phone"
            variant="outlined"
            name="accountForm[phone]"
            value={accountForm.phone}
            placeholder="+(XX) XXX XXX XXX"
            onChange={(e) => setAccountForm({ ...accountForm, phone: e.target.value })}
            required
            onBlur={validPhone}
            error={!!errorForm.phone}
            helperText={errorForm.phone}
            type="tel"
            label={t("common.phone")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneOutlined color={!!errorForm.phone ? "error" : "primary"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="program_name"
            variant="outlined"
            name="accountForm[program_name]"
            value={accountForm.program_name}
            onChange={(e) => {
              setAccountForm({
                ...accountForm,
                program_name: e.target.value,
              });
            }}
            required
            onBlur={() => {
              var is_correct = accountForm.program_name != "" && accountForm.program_name.length <= 39;
              setErrorForm({
                ...errorForm,
                program_name: is_correct ? null : t("account.error.program"),
              });
            }}
            error={!!errorForm.program_name}
            helperText={errorForm.program_name}
            type="text"
            label={t("account.program")}
            placeholder={t("common.program_placeholder")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SchoolOutlined color={!!errorForm.program_name ? "error" : "primary"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="serial"
            variant="outlined"
            name="accountForm[serial]"
            placeholder="2007XXXXX"
            required
            value={accountForm.serial}
            onChange={(e) => setAccountForm({ ...accountForm, serial: e.target.value })}
            onBlur={validSerial}
            error={!!errorForm.serial}
            helperText={errorForm.serial}
            type="text"
            label={t("common.serialNumber")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FavoriteBorderOutlined color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="key"
            variant="outlined"
            name="accountForm[key]"
            value={accountForm.key}
            required
            onChange={(e) => setAccountForm({ ...accountForm, key: e.target.value })}
            error={!!errorForm.key}
            onBlur={() =>
              setErrorForm({
                ...errorForm,
                key: accountForm.key != "" ? null : t("account.error.empty"),
              })
            }
            helperText={errorForm.key}
            type="text"
            label={t("common.code")}
            placeholder="XXXXXXXXXXXXXXX"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlined color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid container item justify="center">
        <FormControlLabel
          style={{ fontSize: 16, color: grey[600], marginBottom: 0 }}
          control={
            <Checkbox
              id="conditions"
              value={accountForm.conditions}
              name="conditions"
              onChange={(e) => {
                setAccountForm({
                  ...accountForm,
                  conditions: e.target.checked,
                });
                setErrorForm({
                  ...errorForm,
                  conditions: e.target.checked,
                });
              }}
              color="primary"
            />
          }
          label={
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.open(t("Privacy_Policy.pdf"), "_blank");
                // handleClickOpen();
              }}
              style={{
                fontSize: 16,
                color: grey[600],
                marginBottom: 0,
              }}
            >
              {t("rescuers.register.aceptConditions")}
            </Link>
          }
        />
      </Grid>
      <Grid item xs={12} container justify="center" style={{ marginBottom: 32 }}>
        <Button variant="contained" onClick={onSubmit} color="secondary" disabled={!isValid || isLoading}>
          {isLoading && (
            <CircularProgress
              size={24}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12,
                marginLeft: -12,
              }}
            />
          )}
          {t("common.createAccount")}
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justify="center"
        alignItems="center"
        style={{
          position: "absolute",
          width: "100%",
          bottom: 8,
          left: 0,
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        <Link
          to={ROUTES.HOME}
          style={{ textDecoration: "none" }}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <Typography color="textSecondary">{t("common.home")}</Typography>
        </Link>
        <Typography color="textSecondary" style={{ marginRight: 4, marginLeft: 4 }}>
          ·
        </Typography>
        <a href={"mailto:info@bexencardio.com?Subject=Error%20en%20el%20solicitud%20para%20cambio%20de%20contraseña"} style={{ textDecoration: "none" }}>
          <Typography color="textSecondary">{t("common.reportCreteAccountError")}</Typography>
        </a>
        <Typography color="textSecondary" style={{ marginRight: 4, marginLeft: 4 }}>
          ·
        </Typography>
        <a href={t("common.bexencardioWebContact")} target="_blank" style={{ textDecoration: "none", marginRight: 4, marginLeft: 4 }}>
          <Typography color="textSecondary">bexencardio.com</Typography>
        </a>
        ·
        <LangSelect />
        <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{t("rescuers.register.privacity")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" aling="justify">
              <p>{t("rescuers.register.privacydescriptionI")}</p>
              <p>{t("rescuers.register.privacydescriptionI1")}</p>
              <p>{t("rescuers.register.privacydescriptionII")}</p>
              <p>{t("rescuers.register.privacydescriptionIII")}</p>
              <p>{t("rescuers.register.privacydescriptionIV")}</p>
              <p>{t("rescuers.register.privacydescriptionV")}</p>
              <p>{t("rescuers.register.privacydescriptionVI")}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              {t("common.ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openError} onClose={handleCloseError}>
          <DialogTitle style={{ color: !modalSuccess && red[500] }}>{modalSuccess ? t("account.success_title") : t("account.err_title")}</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Typography variant="body1">{t("account.err_" + errorId)}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError} color="primary" autoFocus>
              {t("common.ok")}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
    </>
  );
};

export default CreateAccount;

const BP = {
  xs: 0.8,
  sm: 0.8,
  md: 0.65,
  lg: 0.55,
  xl: 0.5,
};
