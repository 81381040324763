import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Grid, Typography } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import * as API_KEYS from "../../constants/apiKeys";
import React, { useState } from "react";
import { LocationOn } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { orange, red } from "@material-ui/core/colors";
import DeviceStatus from "../../components/DeviceStatusModal/DeviceStatus";
import DeviceStatusOthers from "../../components/DeviceStatusModal/DeviceStatusOthers";
import LoadingPage from "../LoadingPage";

const DeviceStatusModal = ({ isStatusModalOpen, device, setisStatusModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isStatusModalOpen} onClose={() => setisStatusModalOpen(false)}>
      <DialogTitle>{device.name}</DialogTitle>
      <DialogContent>
        {device?.status && device?.status?.device_status && device?.model == "Reanibex 100" ? (
          <DeviceStatus status={device?.status} t={t} hasComs={device?.connectivity} software={device?.soft_version} />
        ) : (
          <DeviceStatusOthers device={device} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setisStatusModalOpen(false)} color="primary" autoFocus variant="contained">
          {t("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceStatusModal;
