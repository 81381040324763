import DataNet from "./../DataNet/DataNet";
import SignInPage from "../SignIn";
import HomePage from "../Home";
import { BrowserRouter, BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import withAuthentication from "../Session/withAuthentication";
import theme from "../../constants/theme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import * as ROUTES from "../../constants/routes";
import Navigation from "../Navigation";
import { AuthUserContext } from "../Session";
import "../../i18n";

import DevicesPage from "../../pages/DevicesPage";
import GroupsPage from "../../pages/GroupsPage";
import ProgramsPage from "../../pages/ProgramsPage";
import DevicePage from "../../pages/DevicePage";
import AccessoriesPage from "../../pages/AccessoriesPage";
import UsersPage from "../../pages/UsersPage";
import UserPage from "../../pages/UserPage";
import SourcesPage from "../../pages/SourcesPage";
import AccountValidationPage from "../../pages/AccountValidationPage";
import NewAccount from "../../pages/NewAccount";
import RequestPasswordChange from "../../pages/RequestNewPasswordPage";
import ResetPassword from "../../pages/ResetPassword";
import RegisterUserFrom from "../../pages/RegisterUserFrom";
import ReleasesNotes from "../../pages/ReleasesNotes";

const App = () => {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <AuthUserContext.Consumer>{(authUser) => !!authUser && <Navigation />}</AuthUserContext.Consumer>

          <main className={classes.content}>
            <Switch style={{ background: "#fbfdff" }}>
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.ACCOUNT_NEW} component={NewAccount} />
              <Route path={ROUTES.REQUEST_NEW_PASSWROD} component={RequestPasswordChange} />
              <Route path={ROUTES.PASSWOR_DCHANGE} component={ResetPassword} />
              <Route path={ROUTES.ACCOUNT_VALIDATION} component={AccountValidationPage} />
              <Route path={ROUTES.DEVICES_IN_GROUP} component={DevicesPage} />
              <Route path={ROUTES.DEVICES_IN_PROGRAM} component={DevicesPage} />
              <Route path={ROUTES.DEVICES} exact component={DevicesPage} />
              <Route path={ROUTES.DEVICE + "/:id"} component={DevicePage} />
              {/*<Route path={ROUTES.RESCUERS} component={RescuersSignInPage} />*/}
              <Route path={ROUTES.GROUPS_IN_PROGRAM} exact component={GroupsPage} />
              <Route path={ROUTES.GROUPS} component={GroupsPage} />
              <Route path={ROUTES.PROGRAMS} exact component={ProgramsPage} />
              <Route path={ROUTES.ACCES_IN_GROUP} component={AccessoriesPage} />
              <Route path={ROUTES.ACCES_IN_PROG} component={AccessoriesPage} />
              <Route path={ROUTES.ACCESSORIES} component={AccessoriesPage} />
              <Route path={ROUTES.USERS_IN_GROUP} component={UsersPage} />
              <Route path={ROUTES.USERS_IN_PROGRAM} component={UsersPage} />
              <Route path={ROUTES.USERS} exact component={UsersPage} />
              <Route path={ROUTES.USER + "/:uid"} component={UserPage} />
              <Route path={ROUTES.SOURCES} component={SourcesPage} />
              <Route path={ROUTES.SOURCES_IN_GROUP} component={SourcesPage} />
              <Route path={ROUTES.SOURCES_IN_PROGRAM} component={SourcesPage} />
              <Route path={ROUTES.REGISTER_USER_FROM_PROGRAM} component={RegisterUserFrom} />
              <Route path={ROUTES.RELEASE_NOTES} component={ReleasesNotes} />
              {/** redirigir cuando no se encuentre una ruta */}
              <Redirect to={ROUTES.SIGN_IN} />
            </Switch>
          </main>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default withAuthentication(App);

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflowX: "hidden",
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("xl")]: {
      padding: theme.spacing(10),
      paddingTop: theme.spacing(2),
    },
  },
  root: {
    display: "flex",
    marginTop: 32,
  },
}));
