import { Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography, makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const LoadingProgramCard = () => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined" elevation={0} style={{ display: "flex", flexDirection: "column" }}>
      <CardContent style={{ flex: 1, minHeight: 128 }}>
        <Grid container alignItems="center">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h4" component="h2" color="secondary" style={{ marginBottom: 0, whiteSpace: "normal", fontWeight: 500 }}>
                <Skeleton animation="wave" variant="text" style={{ width: 120 }} />
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                <Skeleton animation="wave" variant="text" style={{ width: 320 }} />
              </Typography>
            </Grid>
            <Grid item xs={2} container alignContent="flex-start" justifyContent="flex-end">
              <Skeleton variant="circle" width={32} height={32} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <Grid container justifyContent="flex-end" style={{ width: "100%", padding: 12 }}>
        <Skeleton variant="rect" width={84} height={32} style={{ borderRadius: 20 }} />
      </Grid>
    </Card>
  );
};

export default LoadingProgramCard;

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    borderRadius: 12,
    background: "#f7f8fa",
  },
  media: {
    height: 140,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
}));
