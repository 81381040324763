import { AppBar, Button, Dialog, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import FormBase from "./FormBase";

function ProgramDialog({ isOpen, setIsOpen, edit = true, editingData }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen TransitionComponent={Transition} maxWidth={"xs"}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6">{edit ? t("Editar programa") : t("Crear programa")}</Typography>
        </Toolbar>
      </AppBar>

      <Grid item xs={12} container alignItems="center" justifyContent="center" style={{ marginTop: 32 }}>
        <Grid item xs={12} md={7} xl={5} container>
          <FormBase edit={edit} cancel={handleClose} data={{ ...editingData }} />
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ProgramDialog;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
