import { Button, DialogActions, Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DeviceUsageStatus from "../../components/DeviceUseEventModal/DeviceUsageStatus";
import DeviceUsageHistory from "../../components/DeviceUseEventModal/DeviceUsageHistory";
import React, { useEffect, useState } from "react";
import * as DATANETAPI from "../../components/DataNet/DataNet";
import LoadingPage from "../LoadingPage";

const DeviceUseEventModal = ({ isUseEventModalOpen, currentNotifications, setisUseEventModalOpen, notifications, isNotificationsLoaded, serial }) => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(true);

  const setUseEventAsReviewed = () => {
    DATANETAPI.setReviewNofitifications(notifications).then((snap) => {
      //console.log("NOTIFICATION REVIEW", snap);
      if (snap.status == 1) {
        setPending(false);
      }
    });
  };
  return (
    <Dialog open={isUseEventModalOpen} onClose={() => setisUseEventModalOpen(false)}>
      <DialogContent>
        <Grid container>
          {currentNotifications.length > 0 && pending && <DeviceUsageStatus active_notifications={currentNotifications}></DeviceUsageStatus>}
          {!isNotificationsLoaded ? (
            <LoadingPage />
          ) : (
            <>
              {currentNotifications.length === 0 || !pending || (currentNotifications.length > 0 && notifications.length > 0) ? (
                <DeviceUsageHistory notifications={notifications}></DeviceUsageHistory>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "20px 20px" }}>
        {currentNotifications.length > 0 && pending && (
          <Button onClick={() => setUseEventAsReviewed()} variant="contained" color="primary" autoFocus>
            {t("device_usage.review")}
          </Button>
        )}
        <Button onClick={() => setisUseEventModalOpen(false)} color="primary" variant="outlined" autoFocus>
          {t("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceUseEventModal;
