import { Breadcrumbs, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link, useLocation, useParams, useRouteMatch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AllGroups from "./AllGroups";
import Programs from "./Programs";
import Devices from "./AllDevices";
import GroupDevice from "./GroupDevice";
import ProgramGroups from "./ProgramGroups";
import ProgramDevices from "./ProgramDevices";
import AllDevices from "./AllDevices";
import AllAccessories from "./AllAccessories";
import GroupAccessories from "./GroupAccessories";
import ProgramAccessories from "./ProgramAccessories";
import AllUsers from "./AllUsers";
import ProgramUsers from "./ProgramUsers";
import GroupUsers from "./GroupUsers";
import { AuthUserContext, withAuthentication } from "../Session";
import Sources from "./Sources";
import ReleaseNotesHeader from "./ReleaseNotes";

const PageHeader = (props) => {
  const location = useLocation();
  const params = useParams();
  const match = useRouteMatch();

  const parse = (programsData) => {
    var obj = {};
    programsData.forEach((p) => {
      obj[p.id] = p.name;
      p.groups.forEach((g) => {
        obj[g.id] = g.name;
      });
    });
    return obj;
  };

  return (
    <div style={{ marginTop: 32 }}>
      {match.path == ROUTES.DEVICES_IN_GROUP && (
        <AuthUserContext.Consumer>{(user) => <GroupDevice programId={params.programId} groupId={params.groupId} programsList={parse(user.programas)} />}</AuthUserContext.Consumer>
      )}
      {match.path == ROUTES.DEVICES_IN_PROGRAM && (
        <AuthUserContext.Consumer>{(user) => <ProgramDevices programId={params.programId} groupId={params.groupId} programsList={parse(user.programas)} />}</AuthUserContext.Consumer>
      )}
      {match.path == ROUTES.DEVICES && <AuthUserContext.Consumer>{(user) => <AllDevices params={params} programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path == ROUTES.PROGRAMS && <AuthUserContext.Consumer>{(user) => <Programs programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path == ROUTES.GROUPS && <AuthUserContext.Consumer>{(user) => <AllGroups programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.GROUPS_IN_PROGRAM && <AuthUserContext.Consumer>{(user) => <ProgramGroups programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.ACCESSORIES && <AuthUserContext.Consumer>{(user) => <AllAccessories programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.ACCES_IN_GROUP && <AuthUserContext.Consumer>{(user) => <GroupAccessories programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.ACCES_IN_PROG && <AuthUserContext.Consumer>{(user) => <ProgramAccessories programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.USERS && <AuthUserContext.Consumer>{(user) => <AllUsers programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.USERS_IN_GROUP && <AuthUserContext.Consumer>{(user) => <GroupUsers programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.USERS_IN_PROGRAM && <AuthUserContext.Consumer>{(user) => <ProgramUsers programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.SOURCES && <AuthUserContext.Consumer>{(user) => <Sources programsList={parse(user.programas)} />}</AuthUserContext.Consumer>}
      {match.path === ROUTES.RELEASE_NOTES && <AuthUserContext.Consumer>{(user) => <ReleaseNotesHeader />}</AuthUserContext.Consumer>}
    </div>
  );
};

export default PageHeader;
