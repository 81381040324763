import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Slide,
  Grid,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectConfig from "./SelectConfig";
import * as DATANETAPI from "../DataNet/DataNet";
import * as COMS from "../../constants/comsTypes";
import { useParams } from "react-router-dom";
import LoadingPage from "../LoadingPage";
import { red, orange } from "@material-ui/core/colors";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ErrorDialog from "../ErrorDialog";
import ButtonLoader from "../ButtonLoader";

const DeviceSetupForm = ({ open, handleClose, groupId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  //const { groupId } = useParams();
  const [groupData, setGroupData] = useState({});
  const [config, setConfig] = useState([]);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [confOptions, setConfOptions] = useState([]);
  const [isConfOptions, setIsConfOptions] = useState(false);
  const [listLangs, setListLangs] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [newVersion, setNewVersion] = useState([]);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const [toUpdate, setToUpdate] = useState(false);
  const [openNotCompatible, setOpenNotCompatible] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const resetForm = () => {
    handleClose(false);
    setOpenAlert(false);
    setIsError(false);
  };

  const updateConfig = (e) => {
    if (!isFormChanged && !toUpdate) {
      /**  si el formualio no se ha cambiado que no actualice nada */
      handleClose(false);
    } else {
      setIsSaving(true);
      e.preventDefault();
      if (newVersion?.version_current == "0100") {
        setOpenNotCompatible(true);
      } else {
        if (groupId !== undefined) {
          var conf_update = {
            new_update: toUpdate && newVersion !== null,
            new_version: "",
            old_version: "",
            opt_version: "",
            config: config,
          };

          if (toUpdate && newVersion !== null) {
            conf_update.new_version = newVersion?.version_new;
            conf_update.old_version = newVersion?.version_current;
            conf_update.opt_version = newVersion?.opt_version;
          }
          /* comprobar que los parametros en standalone van a 0 en las comunicaciones */
          if (groupData?.comsTypes?.includes(COMS.STANDALONE) && !!conf_update?.config?.device_setting?.misc?.transmission_mode) {
            conf_update.config.device_setting.misc.transmission_mode = { priority: 0, gps_enable: 0, transmit_frequency: 0 };
          }
          // console.log("datos que se van a subir", conf_update.config.device_setting.misc.transmission_mode);
          DATANETAPI.editConfig(conf_update, groupId).then((snap) => {
            if (snap.status < 0) {
              setConnectionErrorMsg(t(`error.connection_${snap.status}`));
              setIsSaving(false);
            } else {
              if (snap != undefined) {
                setIsSaving(false);
                setOpenAlert(true);
                if (snap.status === 1) {
                  setIsError(false);
                  requestVersionUpdate();
                } else {
                  setIsError(true);
                }
              }
            }
          });
        } else {
          setIsError(true);
          setIsSaving(false);
        }
      }
    }
  };

  useEffect(() => {
    /** revisar cuando se actualiza el formulario para salir sin hacer la petición */
    if (!isFormChanged && isConfigLoaded && isConfOptions) {
      setIsFormChanged(true);
    }
  }, [config, listLangs, confOptions]);

  useEffect(() => {
    /** se comprueba que el identificador del grupo existe
     * si no existe quiere decir que se está mostrando la lista
     * de dispositivos de un programa en general o bien todos los dispositivos
     * del usuario
     */

    if (groupId !== undefined) {
      DATANETAPI.getGroup(groupId).then((snap) => {
        var backendGroups = snap.data;
        if (backendGroups !== undefined) {
          setGroupData(backendGroups);

          if (backendGroups?.config_current?.device_setting?.langs?.combination === "en-fr-de-es-it-pl" || backendGroups?.config_current?.device_setting?.langs?.combination === "en-fr-de-es-lt-pl") {
            backendGroups.config_current.device_setting.langs.combination = "en-fr-de-el-lt-pl";
            backendGroups.config_current.device_setting.langs.list = ["02_EN", "03_FR", "10_DE", "13_EL", "12_LT", "11_PL"];
            backendGroups.config_current.device_setting.langs.default_lang = 1;
          }

          setListLangs(backendGroups?.config_current?.device_setting?.langs?.list);
          setConfig(backendGroups?.config_current);

          setIsConfigLoaded(true);
        } else {
          setConfig([]);
          setIsConfigLoaded(true);
        }
      });

      DATANETAPI.getConfOptions(groupId).then((snap) => {
        if (snap?.status < 0) {
          setConnectionErrorMsg(t(`error.connection_${snap?.status}`));
          setListLangs([]);
          setConfOptions([]);
          setIsConfOptions(false);
        } else {
          if (snap.data !== undefined) {
            var lang_combinations = snap?.data?.combinations.map((x) => {
              if (x.name === "en-fr-de-es-it-pl") {
                x.name = "en-fr-de-el-lt-pl";
                x.list = ["02_EN", "03_FR", "10_DE", "13_EL", "12_LT", "11_PL"];
              }
              return x;
            });

            lang_combinations = lang_combinations?.filter((x, i, self) => {
              return lang_combinations?.findIndex((xi) => xi?.name === x?.name) === i;
            });

            snap.data.combinations = lang_combinations;
            setConfOptions(snap.data);

            setIsConfOptions(true);
          } else {
            setListLangs([]);
            setConfOptions([]);
            setIsConfOptions(false);
          }
        }
      });

      requestVersionUpdate();
    }
  }, [groupId]);

  const requestVersionUpdate = () => {
    DATANETAPI.newVersionGroup(groupId).then((snap) => {
      if (snap?.status < 0) {
        setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        setIsNewVersion(false);
        setNewVersion([]);
      } else {
        if (snap.status === 1) {
          setNewVersion(snap.data);
          setIsNewVersion(true);
        } else {
          setIsNewVersion(false);
          setNewVersion([]);
        }
      }
    });
  };

  const handleCombinationChange = (event) => {
    var filter = confOptions?.combinations?.filter((x) => x.name === event.target.value);

    if (filter.length > 0) {
      setListLangs(filter[0].list);
      config.device_setting.langs.list = filter[0].list;
      setConfig(config);

      setConfig({
        ...config,
        device_setting: {
          ...config?.device_setting,
          langs: {
            ...config.device_setting.langs,
            combination: event.target.value,
          },
        },
      });
    }
  };

  const handleUpdateToNew = (event) => {
    setToUpdate(event.target.checked);
    if (event.target.checked) {
      if (parseInt(newVersion?.version_current) >= 202) {
        if (config?.device_setting?.misc?.mains_filter == 0) {
          setConfig({
            ...config,
            device_setting: {
              ...config?.device_setting,
              misc: { ...config?.device_setting?.misc, mains_filter: 1 },
            },
          });
        }
      }

      if (parseInt(newVersion?.version_current) >= 203) {
        setConfig({
          ...config,
          device_setting: {
            ...config?.device_setting,
            misc: { ...config?.device_setting?.misc, alert_warning: 1 },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (isConfigLoaded) {
      let error = false;
      error = config?.device_setting?.shocks?.energy2_adult < config?.device_setting?.shocks?.energy1_adult ? true : error;
      error = config?.device_setting?.shocks?.energy3_adult < config?.device_setting?.shocks?.energy2_adult ? true : error;
      error = config?.device_setting?.shocks?.energy3_pediatric < config?.device_setting?.shocks?.energy2_pediatric ? true : error;
      error = config?.device_setting?.shocks?.energy3_pediatric < config?.device_setting?.shocks?.energy2_pediatric ? true : error;
      setIsFormValid(!error);
    }
  }, [config, toUpdate]);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => handleClose(false)} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t("devices.setUp.title", { groupName: groupData?.name })}
          </Typography>
        </Toolbar>
      </AppBar>
      {(!isConfigLoaded || !isConfOptions || !isNewVersion) && <LoadingPage />}
      {isConfigLoaded && isConfOptions && isNewVersion && (
        <Grid item xs={12} container justify="center" alignItems="flex-start" style={{ marginTop: 32 }}>
          <Grid container spacing={3} item xs={12} lg={10} xl={8}>
            <Grid item xs={12}>
              <Typography variant="h3" color="textSecondary">
                {groupData?.name}
              </Typography>
              <Typography variant="body1">{t("devices.setUp.info.onlyConnectedDevices")}</Typography>
            </Grid>
            {isNewVersion && newVersion?.is_available && (
              <Grid container item xs={12} alignItems="center">
                <Grid item style={{ paddingRight: 10 }}>
                  <NewReleasesIcon fontSize="large" style={{ color: orange[500] }}></NewReleasesIcon>
                </Grid>
                <Grid item>
                  <FormControlLabel control={<Checkbox checked={toUpdate} onClick={handleUpdateToNew} name="checkedB" color="primary" />} label={t("devices.setUp.info.update_opt")} />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 18,
                }}
              >
                {t("common.languaje")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SelectConfig
                label={t("common.lang_combination")}
                value={config?.device_setting?.langs?.combination}
                onChange={handleCombinationChange}
                //info={t("devices.setUp.combination")}
                kv
                values={confOptions?.combinations.map((v) => {
                  return { key: v.name, value: v.name.toUpperCase() };
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SelectConfig
                label={t("common.languaje")}
                //info={t("devices.setUp.selectDefaultLanguaje")}
                value={config?.device_setting?.langs?.default_lang}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      langs: {
                        ...config.device_setting.langs,
                        default_lang: e.target.value,
                      },
                    },
                  })
                }
                kv
                values={listLangs?.map((v, i) => {
                  return { key: i + 1, value: t("common.lang." + v) };
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 18,
                }}
              >
                {t("devices.setUp.crpTitle")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <SelectConfig
                label={t("devices.setUp.selectCPR1Label")}
                value={config?.device_setting?.cpr?.cpr1}
                // info={t("devices.setUp.selectCPR1Label")}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      cpr: {
                        ...config.device_setting.cpr,
                        cpr1: parseInt(e.target.value),
                      },
                    },
                  })
                }
                kv
                values={[
                  { key: 60, value: "60 sec" },
                  { key: 120, value: "120 sec" },
                  { key: 150, value: "150 sec" },
                  { key: 180, value: "180 sec" },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={4}>
              <SelectConfig
                label={t("devices.setUp.selectCPR2Label")}
                //info={t("devices.setUp.selectCPR2Label")}
                value={config?.device_setting?.cpr?.cpr2 + " sec"}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      cpr: {
                        ...config.device_setting.cpr,
                        cpr2: parseInt(e.target.value.split(" ")[0]),
                      },
                    },
                  })
                }
                values={["60 sec", "120 sec", "150 sec", "180 sec"]}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <SelectConfig
                label={t("devices.setUp.msg_longLabel")}
                value={config?.device_setting?.cpr?.msg_long}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      cpr: {
                        ...config.device_setting.cpr,
                        msg_long: e.target.value,
                      },
                    },
                  })
                }
                kv
                values={[
                  { key: 0, value: t("common.short") },
                  { key: 1, value: t("common.long") },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 18,
                }}
              >
                {t("devices.setUp.metronome.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
              <SelectConfig
                label={t("device.details.config.metronome.ratio_adult")}
                value={config?.device_setting?.metronome?.ratio_adult}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      metronome: {
                        ...config.device_setting.metronome,
                        ratio_adult: e.target.value,
                      },
                    },
                  })
                }
                kv
                values={[
                  { key: 0, value: t("devices.setUp.ratioAdultValue0") },
                  { key: 1, value: t("devices.setUp.ratioAdultValue1") },
                  { key: 2, value: t("devices.setUp.ratioAdultValue2") },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <SelectConfig
                label={t("device.details.config.metronome.ratio_pediatric")}
                value={config?.device_setting?.metronome?.ratio_pediatric}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      metronome: {
                        ...config.device_setting.metronome,
                        ratio_pediatric: e.target.value,
                      },
                    },
                  })
                }
                kv
                values={[
                  { key: 0, value: t("devices.setUp.ratioAdultValue0") },
                  { key: 1, value: t("devices.setUp.ratioAdultValue1") },
                  { key: 2, value: t("devices.setUp.ratioAdultValue2") },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={3}>
              <SelectConfig
                label={t("devices.setUp.rateLabel")}
                value={config?.device_setting?.metronome?.rate}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      metronome: {
                        ...config.device_setting.metronome,
                        rate: e.target.value,
                      },
                    },
                  })
                }
                kv
                values={[
                  { key: 0, value: t("cpm", { value: 100 }) },
                  { key: 1, value: t("cpm", { value: 110 }) },
                  { key: 2, value: t("cpm", { value: 120 }) },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 18,
                }}
              >
                {t("devices.setUp.shock.title")}
              </Typography>
            </Grid>
            <EnergyModule
              label={t("devices.setUp.energyAdult1Label")}
              setConfig={setConfig}
              config={config}
              type={"energy1_adult"}
              checkType={"energy1_adult"}
              errorLabel={t("devices.setUp.energy.overEnergy.error")}
              isAdult={true}
            />
            <EnergyModule
              label={t("devices.setUp.energyAdult2Label")}
              setConfig={setConfig}
              config={config}
              type={"energy2_adult"}
              checkType={"energy1_adult"}
              errorLabel={t("devices.setUp.energy.overEnergy.error")}
              isAdult={true}
            />
            <EnergyModule
              label={t("devices.setUp.energyAdult3Label")}
              setConfig={setConfig}
              config={config}
              type={"energy3_adult"}
              checkType={"energy2_adult"}
              errorLabel={t("devices.setUp.energy.overEnergy.error")}
              isAdult={true}
            />
            <EnergyModule
              label={t("devices.setUp.energyPediatric1Label")}
              setConfig={setConfig}
              config={config}
              type={"energy1_pediatric"}
              checkType={"energy1_pediatric"}
              errorLabel={t("devices.setUp.energy.overEnergy.error")}
              isAdult={false}
            />
            <EnergyModule
              label={t("devices.setUp.energyPediatric2Label")}
              setConfig={setConfig}
              config={config}
              type={"energy2_pediatric"}
              checkType={"energy1_pediatric"}
              errorLabel={t("devices.setUp.energy.overEnergy.error")}
              isAdult={false}
            />
            <EnergyModule
              label={t("devices.setUp.energyPediatric3Label")}
              setConfig={setConfig}
              config={config}
              type={"energy3_pediatric"}
              checkType={"energy2_pediatric"}
              errorLabel={t("devices.setUp.energy.overEnergy.error")}
              isAdult={false}
            />
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <SelectConfig
                label={t("devices.setUp.consecutive_socks")}
                value={config?.device_setting?.shocks?.consecutive_shocks}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      shocks: {
                        ...config.device_setting.shocks,
                        consecutive_shocks: e.target.value,
                      },
                    },
                  })
                }
                values={[1, 2, 3, 4]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 18,
                }}
              >
                {t("devices.setUp.patient.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <SelectConfig
                label={t("devices.setUp.patientLabel")}
                value={config?.device_setting?.patient?.patient_type}
                onChange={(e) => setConfig({ ...config, device_setting: { ...config.device_setting, patient: { ...config.device_setting.patient, patient_type: e.target.value } } })}
                auto
                values={[t("common.pediatric"), t("common.adult")]}
              />
            </Grid>

            {parseInt(newVersion?.version_current) >= 210 && !groupData?.comsTypes?.includes(COMS.STANDALONE) && (
              <Grid item xs={12}>
                <Typography
                  color="secondary"
                  style={{
                    fontWeight: 500,
                    fontSize: 22,
                    lineHeight: "30px",
                    marginTop: 18,
                  }}
                >
                  {t(`devices.setUp.comms.title`)}
                </Typography>
              </Grid>
            )}
            {false && parseInt(newVersion?.version_current) >= 210 && (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("devices.setUp.alert_mode")}
                  value={config?.device_setting?.misc?.alert_mode}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          alert_mode: e.target.value,
                        },
                      },
                    })
                  }
                  kv
                  values={(() => {
                    let list = [{ key: 0, value: t("devices.setUp.alert_mode.none") }];
                    groupData?.comsTypes?.includes(COMS.SIGFOX) && list.push({ key: 1, value: t("devices.setUp.alert_mode.Sigfox") });
                    groupData?.comsTypes?.includes(COMS.WIFI) && list.push({ key: 2, value: t("devices.setUp.alert_mode.WiFi") });
                    groupData?.comsTypes?.includes(COMS.SIGFOX) && groupData?.comsTypes?.includes(COMS.WIFI) && list.push({ key: 3, value: t("devices.setUp.alert_mode.both") });
                    return list;
                  })()}
                />
              </Grid>
            )}

            {parseInt(newVersion?.version_current) >= 210 && (groupData?.comsTypes?.includes(COMS.SIGFOX) || groupData?.comsTypes?.includes(COMS.WIFI)) && (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("device.details.config.misc.trans_mode.priority")}
                  value={config?.device_setting?.misc?.transmission_mode?.priority}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          transmission_mode: {
                            ...config?.device_setting?.misc?.transmission_mode,
                            priority: e.target.value,
                          },
                        },
                      },
                    })
                  }
                  kv
                  values={(() => {
                    let list = [{ key: 0, value: t("common.off") }];
                    groupData?.comsTypes?.includes(COMS.SIGFOX) && list.push({ key: 1, value: t("devices.setUp.trans_mode.priority.sigfox_only") });
                    groupData?.comsTypes?.includes(COMS.SIGFOX) && groupData?.comsTypes?.includes(COMS.WIFI) && list.push({ key: 2, value: t("devices.setUp.trans_mode.priority.sigfox_first") });
                    groupData?.comsTypes?.includes(COMS.WIFI) && list.push({ key: 3, value: t("devices.setUp.trans_mode.priority.wifi_only") });
                    groupData?.comsTypes?.includes(COMS.SIGFOX) && groupData?.comsTypes?.includes(COMS.WIFI) && list.push({ key: 4, value: t("devices.setUp.trans_mode.priority.wifi_first") });
                    return list;
                  })()}
                />
              </Grid>
            )}
            {parseInt(newVersion?.version_current) >= 210 &&
              config?.device_setting?.misc?.transmission_mode?.priority >= 3 /* solo se permite para los que tengan prioridad wiifi, ya que paras las demás opciones no se puede elegir*/ && (
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                  <SelectConfig
                    label={t("device.details.config.misc.trans_mode.transmit_frequency")}
                    value={config?.device_setting?.misc?.transmission_mode?.transmit_frequency}
                    onChange={(e) =>
                      setConfig({
                        ...config,
                        device_setting: {
                          ...config?.device_setting,
                          misc: {
                            ...config?.device_setting?.misc,
                            transmission_mode: {
                              ...config?.device_setting?.misc?.transmission_mode,
                              transmit_frequency: e.target.value,
                            },
                          },
                        },
                      })
                    }
                    kv
                    values={[
                      { key: 0, value: t("devices.setUp.trans_mode.frequency.send_everyday") },
                      { key: 1, value: t("devices.setUp.trans_mode.frequency.send_4_30_days") },
                      { key: 2, value: t("devices.setUp.trans_mode.frequency.send_1_30_days") },
                    ]}
                  />
                </Grid>
              )}

            {parseInt(newVersion?.version_current) >= 210 && config?.device_setting?.misc?.transmission_mode?.priority >= 1 && (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("device.details.config.misc.trans_mode.gps_enable")}
                  value={config?.device_setting?.misc?.transmission_mode.gps_enable}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          transmission_mode: {
                            ...config?.device_setting?.misc?.transmission_mode,
                            gps_enable: e.target.value,
                          },
                        },
                      },
                    })
                  }
                  kv
                  values={[
                    { key: 0, value: t("devices.setUp.alerts.off") },
                    { key: 1, value: t("devices.setUp.alerts.on") },
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 18,
                }}
              >
                {t("devices.setUp.misc.title")}
              </Typography>
            </Grid>
            {parseInt(newVersion?.version_current) <= 201 ? (
              <Grid item xs={6} lg={3} xl={3}>
                <SelectConfig
                  label={t("devices.setUp.mains_filterLabel")}
                  value={config?.device_setting?.misc?.mains_filter}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          mains_filter: e.target.value,
                        },
                      },
                    })
                  }
                  kv
                  values={[{ key: 0, value: t("devices.setUp.mains_filter0") }]}
                />
              </Grid>
            ) : (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("devices.setUp.mains_filterLabel")}
                  value={config?.device_setting?.misc?.mains_filter}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          mains_filter: e?.target?.value,
                        },
                      },
                    })
                  }
                  kv
                  values={[
                    { key: 0, value: t("devices.setUp.mains_filter0") },
                    { key: 1, value: "50 Hz" },
                    { key: 2, value: "60 Hz" },
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
              <SelectConfig
                label={t("devices.setUp.utc_timeLabel")}
                value={config?.device_setting?.misc?.utc_time}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      misc: {
                        ...config?.device_setting?.misc,
                        utc_time: e?.target?.value,
                      },
                    },
                  })
                }
                kv
                values={UTC_TIMELIST}
              />
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
              <SelectConfig
                label={t("devices.setUp.volumenLabel")}
                value={config?.device_setting?.misc?.volume}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    device_setting: {
                      ...config?.device_setting,
                      misc: {
                        ...config?.device_setting?.misc,
                        volume: e.target.value,
                      },
                    },
                  })
                }
                values={[1, 2, 3, 4, 5, 6, 7, 8]}
              />
            </Grid>
            {false &&
              /** se oculta para la versión de lanzamiento */
              (parseInt(newVersion?.version_current) >= 203 || (newVersion.is_available && parseInt(newVersion?.version_current) >= 203 && toUpdate)) && (
                <>
                  <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                    <SelectConfig
                      label={t("device.details.config.misc.alert_warning")}
                      value={config?.device_setting?.misc?.alert_warning}
                      onChange={(e) =>
                        setConfig({
                          ...config,
                          device_setting: {
                            ...config?.device_setting,
                            misc: {
                              ...config?.device_setting?.misc,
                              alert_warning: e.target.value,
                            },
                          },
                        })
                      }
                      kv
                      values={[
                        { key: 0, value: t("devices.setUp.alerts.off") },
                        { key: 1, value: t("devices.setUp.alerts.on") },
                      ]}
                    />
                  </Grid>
                </>
              )}
            {false && ( // no está disponible para la versión del wifi
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("devices.setUp.alert_move")}
                  value={config?.device_setting?.misc?.alert_move}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          alert_move: e.target.value,
                        },
                      },
                    })
                  }
                  kv
                  values={[
                    { key: 0, value: t("devices.setUp.alerts.off") },
                    { key: 1, value: t("devices.setUp.alerts.on") },
                  ]}
                />
              </Grid>
            )}

            {parseInt(newVersion?.version_current) >= 210 && (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("devices.setUp.alert_patient")}
                  value={config?.device_setting?.misc?.alert_patient}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          alert_patient: e.target.value,
                        },
                      },
                    })
                  }
                  kv
                  values={[
                    { key: 0, value: t("devices.setUp.alerts.off") },
                    { key: 1, value: t("devices.setUp.alerts.on") },
                  ]}
                />
              </Grid>
            )}
            {parseInt(newVersion?.version_current) >= 209 && (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                <SelectConfig
                  label={t("devices.setUp.alert_warning")}
                  value={config?.device_setting?.misc?.alert_warning}
                  onChange={(e) =>
                    setConfig({
                      ...config,
                      device_setting: {
                        ...config?.device_setting,
                        misc: {
                          ...config?.device_setting?.misc,
                          alert_warning: e?.target?.value,
                        },
                      },
                    })
                  }
                  kv
                  values={[
                    { key: 0, value: t("devices.setUp.alerts.off") },
                    { key: 1, value: t("devices.setUp.alerts.on") },
                  ]}
                />
              </Grid>
            )}
            <Grid container item xs={12} justify="flex-end" style={{ marginBottom: 34 }}>
              <Button variant="text" style={{ color: red[500] }} className={classes.closeButton} onClick={resetForm}>
                {t("common.close")}
              </Button>
              <Button variant="contained" color="primary" disabled={!isFormValid || isSaving} onClick={updateConfig}>
                {isSaving && <ButtonLoader />}
                {toUpdate ? t("common.update") : t("common.ok")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />

      <Dialog open={openAlert}>
        <DialogTitle style={{ color: isError && red[500] }}>{isError ? t("devices.setUp.dialog.title.error") : t("devices.setUp.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{isError ? t("devices.setUp.dialog.description.error") : t("devices.setUp.dialog.descriptionWithSync")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAlert(false);
              !isError && resetForm();
            }}
            variant="outlined"
            color="primary"
          >
            {!isError ? t("devices.setUp.dialog.agree") : t("devices.setUp.dialog.agree")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNotCompatible}>
        <DialogTitle style={{ color: red[500] }}>{t("devices.setUp.dialog.notcompatibe.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("devices.setUp.dialog.notcompatibe.description")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenNotCompatible(false);
            }}
            variant="text"
            color="primary"
          >
            {t("devices.setUp.dialog.agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const EnergyModule = ({ label, setConfig, config, type, checkType, errorLabel, isAdult }) => {
  let values = isAdult
    ? [
        { key: 150, value: "150 J" },
        { key: 175, value: "175 J" },
        { key: 200, value: "200 J" },
      ]
    : [
        { key: 50, value: "50 J" },
        { key: 65, value: "65 J" },
        { key: 75, value: "75 J" },
        { key: 90, value: "90 J" },
        { key: 100, value: "100 J" },
      ];

  return (
    <Grid item xs={12} sm={4} xl={2}>
      <SelectConfig
        label={label}
        value={config?.device_setting?.shocks?.[type]}
        onChange={(e) =>
          setConfig({
            ...config,
            device_setting: {
              ...config?.device_setting,
              shocks: {
                ...config.device_setting.shocks,
                [type]: e.target.value,
              },
            },
          })
        }
        error={config.device_setting.shocks[type] < config.device_setting.shocks[checkType]}
        helperText={config.device_setting.shocks[type] < config.device_setting.shocks[checkType] && errorLabel}
        kv
        values={values}
      />
    </Grid>
  );
};

export default DeviceSetupForm;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
  },
  closeButton: {
    marginRight: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formcontainer: {
    padding: 24,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UTC_TIMELIST = [
  { key: -12, value: "UTC-12" },
  { key: -11, value: "UTC-11" },
  { key: -10, value: "UTC-10" },
  { key: -9, value: "UTC-9" },
  { key: -8, value: "UTC-8" },
  { key: -7, value: "UTC-7" },
  { key: -6, value: "UTC-6" },
  { key: -5, value: "UTC-5" },
  { key: -4, value: "UTC-4" },
  { key: -3, value: "UTC-3" },
  { key: -2, value: "UTC-2" },
  { key: -1, value: "UTC-1" },
  { key: 0, value: "UTC 0" },
  { key: 1, value: "UTC+1" },
  { key: 2, value: "UTC+2" },
  { key: 3, value: "UTC+3" },
  { key: 4, value: "UTC+4" },
  { key: 5, value: "UTC+5" },
  { key: 6, value: "UTC+6" },
  { key: 7, value: "UTC+7" },
  { key: 8, value: "UTC+8" },
  { key: 9, value: "UTC+9" },
  { key: 10, value: "UTC+10" },
  { key: 11, value: "UTC+11" },
  { key: 12, value: "UTC+12" },
  { key: 13, value: "UTC+13" },
  { key: 14, value: "UTC+14" },
];
