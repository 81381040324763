import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ButtonLoader from "../ButtonLoader";
import * as DATANET from "../DataNet/DataNet";
import ErrorDialog from "../ErrorDialog";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import ClientEmailForm from "./ClientEmailForm";
import NotEmptyProgramDialog from "./NotEmptyProgramDialog";
import { WarningOutlined } from "@material-ui/icons";

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FormBase = ({ edit = true, cancel, data }) => {
  const limiteTextInput = 50;
  const { t } = useTranslation();
  const history = useHistory();

  const hasClientOnEdit = edit && !!data?.client_email && data?.client_email != "";

  const [name, setName] = useState(edit ? data?.name : "");
  const [description, setDescription] = useState(edit ? data?.def : "");
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setnameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [hasLocation, sethasLocation] = useState(false);
  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  const [successRequest, setSuccessRequest] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [generalError, setGeneralError] = useState(t("Revise los campos y vuelva a enviar el formulario"));
  const [isChanged, setIsChanged] = useState(false);
  const [generatedProgramId, setGeneratedProgramId] = useState("");
  const [clientEmail, setClientEmail] = useState(hasClientOnEdit ? data?.client_email : "");
  const [addClient, setAddClient] = useState(hasClientOnEdit);
  const [clientName, setClientName] = useState(hasClientOnEdit ? data?.client_name : "");
  const [contactEmail, setContactEmail] = useState(hasClientOnEdit ? data?.contact_email : "");
  const [contactDescription, setContactDescription] = useState(hasClientOnEdit ? data?.contact_description?.split(" - ")[0] : "");
  const [clientNameError, setClientNameError] = useState("");
  const [contactEmailError, setContactEmailError] = useState("");
  const [contactDescriptionError, setContactDescriptionError] = useState("");
  const [clientLang, setClientLang] = useState(hasClientOnEdit ? data?.lang : "");
  const [companyName, setCompanyName] = useState(hasClientOnEdit && data?.contact_description?.includes(" - ") ? data?.contact_description?.split(" - ")[1] : "");
  const [isContactEmailLoaded, setIsContactEmailLoaded] = useState(hasClientOnEdit);
  const [emptyProgramModalOpen, setEmptyProgramModalOpen] = useState(false);

  /*const [first, setfirst] = useState("");
  const [fistAlertRange, setfistAlertRange] = useState(0);
  const [maxAlertRange, setMaxAlertRange] = useState(0);
  const [AEDSInstalled, setAEDSInstalled] = useState(false);
  const [isPublic, setpublic] = useState(false);
  const [hastCP, setHastCP] = useState(false);*/

  const handleName = (e) => {
    const v = e.target.value;
    v?.length <= limiteTextInput && setName(v);
    setnameError("");
    setIsChanged(true);
    setGeneralError("");
  };
  const handleDescription = (e) => {
    const v = e.target.value;
    v?.length <= limiteTextInput && setDescription(v);
    setDescriptionError("");
    setIsChanged(true);
  };

  const send = () => {
    setIsLoading(true);

    if (edit) {
      //  let newClient = addClient ? data?.client_email === clientEmail : false;
      // no añadir los datos de usuario para ver que pasa con el null
      let newClient = addClient ? data?.client_email === clientEmail || clientEmail !== "" : false;
      let editedData = {
        name: name.trim(),
        def: description.trim(),
        client_email: newClient ? clientEmail : "",
        client_new: newClient,
      };
      if (newClient) {
        editedData = {
          ...editedData,
          lang: clientLang,
          client_new: newClient,
          contact_email: contactEmail,
          contact_description: `${contactDescription}${companyName != "" || !!companyName ? ` - ${companyName}` : ""}`,
          client_name: clientName,
        };
      }

      DATANET.editProgram(data?.programId, editedData).then((snap) => proccessRequest(snap));
    } else {
      let data = {
        name: name.trim(),
        def: description.trim(),
        client_email: addClient ? clientEmail : "",
        client_new: addClient,
        location: { name: "---", latitude: location.latitude, longitude: location.longitude, has_location: hasLocation },
      };
      if (addClient) {
        data = {
          ...data,
          lang: clientLang,
          contact_email: contactEmail,
          contact_description: `${contactDescription}${companyName != "" || !!companyName ? ` - ${companyName}` : ""}`,
          client_name: clientName,
        };
      }
      DATANET.createProgram(data).then((snap) => proccessRequest(snap));
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation(position.coords);
      sethasLocation(true);
    });
  }, []);

  useEffect(() => {
    if (isChanged) {
      checkName();
    }
  }, [name]);

  useEffect(() => {
    if (isChanged) {
      checkDescription();
    }
  }, [description]);

  useEffect(() => {
    setGeneralError("");
    /* si no tiene pero se añade, tambien hay que modificar el haschanged */
    if (hasClientOnEdit || (!hasClientOnEdit && addClient)) {
      const new_contact_description = companyName === "" ? contactDescription : `${contactDescription} - ${companyName}`;

      const hasChanged =
        isChanged ||
        clientName !== data.client_name ||
        clientEmail !== data.client_email ||
        contactEmail !== data.contact_email ||
        clientLang !== data.lang ||
        new_contact_description !== data.contact_description;
      setIsChanged(hasChanged);
    }
  }, [name, description, clientName, clientEmail, contactEmail, contactDescription, companyName, clientLang]);

  useEffect(() => {
    hasClientOnEdit && !addClient && setIsChanged(true);
  }, [addClient]);

  const proccessRequest = (snap) => {
    if (snap.status <= 0) {
      setErrorMsg(t(`error.connection_${snap.status}`));
    } else {
      if (snap.status === 1) {
        setSuccessRequest(true);
        // console.log("programa creado correctamente", snap);
        !edit && setGeneratedProgramId(snap?.data.programid);
      } else if (snap.status === 2) {
        checkName();
        checkDescription();
        setGeneralError(t("Los datos introducidos no son correctos"));
      } else if (snap.status === 3 || snap.status === 4) {
        setGeneralError(t("No tienes privilegios para realizar esta operación"));
        setErrorMsg(t("No tienes privilegios para realizar esta operación"));
      } else if (snap.status === 9) {
        setEmptyProgramModalOpen(true);
      } else {
        setErrorMsg(t(`Error en la solicitud`));
      }
    }
    setIsLoading(false);
    setIsChanged(false);
  };

  const checkName = () => setnameError(name?.length > 0 && name?.length <= limiteTextInput ? "" : t("El campo tiene que tener ente x e y", { min: 0, max: 50 }));
  const checkDescription = () => setDescriptionError(description?.length > 0 && description?.length <= limiteTextInput ? "" : t("El campo tiene que tener ente x e y", { min: 0, max: 50 }));
  const checkClientName = () => setClientNameError(clientName?.length > 0 && clientName?.length <= 100 ? "" : t("El campo tiene que tener ente x e y", { min: 0, max: 100 }));
  const checContactDescription = () =>
    setContactDescriptionError(contactDescription?.length > 0 && contactDescription?.length <= 100 ? "" : t("El campo tiene que tener ente x e y", { min: 0, max: 100 }));
  const checkContactemail = () => {
    if (contactEmail == "") {
      setContactEmailError(t("El campo no puede estar vacío"));
    } else if (!contactEmail?.match(emailPattern)) {
      setContactEmailError(t("Introduce un email válido"));
    } else {
      setContactEmailError("");
    }
  };

  const isClientValid = addClient
    ? clientName?.length > 0 &&
      clientName?.length <= 100 &&
      clientEmail?.match(emailPattern) &&
      contactEmail?.match(emailPattern) &&
      contactDescription?.length > 0 &&
      contactDescription?.length <= 100
    : true;

  const isValid = name !== "" && name?.length <= limiteTextInput && description !== "" && description?.length <= limiteTextInput && isClientValid;
  return (
    <Grid container justifyContent="center" style={{ padding: 16, paddingBottom: 32 }}>
      <Grid item xs={12} container spacing={3} style={{ marginTop: 13 }}>
        <Grid item xs={12}>
          <Typography variant="h6">{edit ? t("Editar programa") : t("Crear programa")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label={t("common.name")}
            error={nameError || generalError !== ""}
            helperText={nameError}
            onBlur={checkName}
            value={name}
            placeholder={t("common.program_placeholder")}
            variant="outlined"
            fullWidth
            onChange={handleName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("common.description")}
            required
            error={descriptionError || generalError !== ""}
            helperText={descriptionError}
            value={description}
            onBlur={checkDescription}
            variant="outlined"
            fullWidth
            onChange={handleDescription}
          />
          <FormHelperText error>{generalError}</FormHelperText>
        </Grid>

        {/* -------------------------------------------------------------------------------------------------------------------------------------- */}

        {/* Añadir una opcion para no mostrar la sección de añadir un nuevo usuario */}
        <Accordion elevation={0} style={{ backgroundColor: "white" }} expanded={addClient}>
          <AccordionSummary style={{ margin: "-12px 15px", padding: 0, marginTop: -4, marginBottom: -4 }}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setAddClient(!addClient)}
              color={"primary"}
              style={{ color: addClient && red[500], margin: 0, height: "100%", borderColor: addClient && red[700] }}
            >
              {addClient ? t("Eliminar cliente") : t("Añadir cliente")}
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{t("Datos del cliente")}</Typography>
              </Grid>

              <Grid item xs={12} md={8}>
                <TextField
                  label={t("Nombre del cliente")}
                  required
                  error={clientNameError || generalError !== ""}
                  helperText={clientNameError}
                  value={clientName}
                  onBlur={checkClientName}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setClientName(e.target.value);
                    setClientNameError("");
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">{t("Idioma del cliente")}</InputLabel>
                  <Select labelId="demo-simple-select-label" id="demo-simple-select" value={clientLang} label={t("Idioma del cliente")} onChange={(e) => setClientLang(e.target.value)}>
                    {["es", "en", "it"].map((e) => (
                      <MenuItem value={e}>{t(`navigation.languaje.${e}`)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <ClientEmailForm updateEmail={setClientEmail} generalError={generalError} initialEmail={hasClientOnEdit ? data?.client_email : ""} />

              <Grid item xs={12}>
                {t("Datos del distribuidor")}
              </Grid>

              <Grid item xs={12}>
                <AuthUserContext.Consumer>
                  {(authUser) => {
                    if (!isContactEmailLoaded) {
                      setIsContactEmailLoaded(true);
                      !hasClientOnEdit && setContactEmail(authUser.email);
                      !hasClientOnEdit && setClientLang(authUser.lang);
                    }
                  }}
                </AuthUserContext.Consumer>
                <TextField
                  label={t("Email de contacto del distribuidor")}
                  required
                  error={contactEmailError || generalError !== ""}
                  helperText={contactEmailError}
                  value={contactEmail}
                  onBlur={checkContactemail}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setContactEmail(e.target.value);
                    setContactEmailError("");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t("Nombre de contacto")}
                  required
                  error={contactDescriptionError || generalError !== ""}
                  helperText={contactDescriptionError}
                  value={contactDescription}
                  onBlur={checContactDescription}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setContactDescription(e.target.value);
                    setContactDescriptionError("");
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField label={t("Nombre de la empresa")} value={companyName} variant="outlined" fullWidth onChange={(e) => setCompanyName(e.target.value)} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: -16 }}>
                <FormHelperText error>{generalError}</FormHelperText>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {hasClientOnEdit && (!addClient || data.client_email !== clientEmail) && (
          <Grid item xs={12}>
            <Paper elevation={0} variant="outlined" style={{ borderColor: red[700], backgroundColor: red[50], padding: 16, borderRadius: 9 }}>
              <Grid container item xs={12}>
                <Grid item xs={12} container>
                  <Grid item xs={12} container justifyContent="center" alignContent="flex-end">
                    <Typography variant="h6" color="error">
                      <WarningOutlined color="error" style={{ marginBottom: -3, marginRight: 6 }} />
                      {t("Cuidado")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} container justifyContent="center">
                    {!addClient ? (
                      <Typography variant="body1">{t("Se va a eliminar el acceso al usuario", { email: data?.client_email })}</Typography>
                    ) : (
                      <Typography variant="body1">{t("Se va a quitar el acceso al programa", { old: data?.client_email, new: clientEmail })}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => cancel()}
            disabled={isLoading}
            style={{ marginRight: 9, color: !isLoading && red[500], borderColor: !isLoading && red[700], textTransform: "none" }}
          >
            {t("common.close")}
          </Button>

          <Button variant="outlined" color="primary" disabled={!isValid || isLoading || !isChanged} onClick={send} style={{ textTransform: "none" }}>
            {edit ? t("Editar programa") : t("Crear programa")}
            {isLoading && <ButtonLoader />}
          </Button>
        </Grid>
      </Grid>
      <Dialog open={successRequest} onClose={() => setSuccessRequest(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"> {edit ? t("Editar programa") : t("Crear programa")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {edit ? t("El programa se ha editado correctamente, se han creado un grupo por defecto") : t("El programa se ha creado correctamente, se han creado un grupo por defecto")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessRequest(false);
              cancel();
            }}
            style={{ textTransform: "none" }}
          >
            {t("common.close")}
          </Button>
          {!edit && (
            <Button
              onClick={() => {
                setSuccessRequest(false);
                cancel();
                history.push(ROUTES.PROGRAMS + "/" + generatedProgramId + ROUTES.GROUPS);
              }}
              color="primary"
              autoFocus
              style={{ textTransform: "none" }}
            >
              {t("Ver programa")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ErrorDialog errorMsg={errorMsg} clearError={() => setErrorMsg("")} />
      <NotEmptyProgramDialog open={emptyProgramModalOpen} close={() => setEmptyProgramModalOpen(false)} programID={data?.programId} />
    </Grid>
  );
};

export default FormBase;
