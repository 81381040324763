import { Grid, makeStyles, Paper, Button, Hidden } from "@material-ui/core";
import React, { useEffect } from "react";
import { AuthUserContext, withAuthentication } from "../Session";
import SignInForm from "./SignInForm";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";
import login_img from "../../assets/images/login.jpg";

const SignInPage = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const goToUserHome = (user) => {
    user.program.length > 1 ? history.push(ROUTES.PROGRAMS) : history.push(`${ROUTES.PROGRAMS}/${user.program[0].id}${ROUTES.GROUPS}`);
  };

  return (
    <>
      {<AuthUserContext.Consumer>{(authUser) => !!authUser && goToUserHome(authUser)}</AuthUserContext.Consumer>}

      <Hidden xsDown>
        <Grid container justify="center" alignItems="center" style={{ minHeight: "80vh", marginTop: 0 }}>
          <Grid container item lg={7} xs={12} xl={6} md={6}>
            <Paper
              variant="outlined"
              style={{
                width: "100%",
                borderRadius: 16,
                "background-image": "linear-gradient(0deg,rgba(255,255,255,.5),rgba(255,255,255,.5)),url()",
              }}
            >
              <Grid
                container
                style={{
                  borderTopLeftRadius: 16,
                  borderBottomLeftRadius: 16,
                }}
              >
                <Hidden smDown>
                  <Grid
                    container
                    item
                    alignItems="center"
                    justify="center"
                    sm={6}
                    style={{
                      borderTopLeftRadius: 16,
                      borderBottomLeftRadius: 16,
                      backgroundPosition: "-95px",
                      backgroundSize: "cover",
                      "background-image": "linear-gradient(0deg,rgba(255,255,255,.15),rgba(255,255,255,.15)), " + `url(${login_img})`,
                    }}
                  ></Grid>
                </Hidden>

                <Grid item justify="center" sm={12} lg={6} container style={{ padding: 32 }}>
                  <SignInForm />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid container alignItems="center" style={{ minHeight: "90vh" }}>
          <SignInForm />
        </Grid>
      </Hidden>
    </>
  );
};

export default withAuthentication(SignInPage);
