import { Avatar, IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SingOutMenuItem from "../SignOut/SingOutMenuItem";
import * as ROUTES from "../../constants/routes";
import LangSelect from "./LangSelect";

const User = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const history = useHistory();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={handleMenu}>
        <Avatar className={classes.avatar}>{user.name[0].toUpperCase()}</Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            window.scrollTo(0, 0);
            history.push(`${ROUTES.USER}/${user.id}`);
          }}
        >
          <ListItemIcon>
            <Avatar className={classes.avatarIcon}>{user.name[0].toUpperCase()}</Avatar>
          </ListItemIcon>
          <ListItemText>
            {user.name} {user.last_name}
          </ListItemText>
        </MenuItem>

        <SingOutMenuItem />
      </Menu>
    </>
  );
};

export default User;

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: "#023f88",

    fontWeight: 500,
  },
  avatarIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: "#023f88",
  },
}));
