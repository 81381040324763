import { FormHelperText, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ClientEmailForm = ({ updateEmail, generalError, initialEmail = "" }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState(initialEmail);
  const [repeatEmail, setRepeatEmail] = useState(initialEmail);
  const [emailError, setEmailError] = useState("");
  const [repeatEmailError, setRepeatEmailError] = useState("");

  const checkEmail = () => {
    if (email == "") {
      setEmailError(t("El campo no puede estar vacío"));
    } else if (!email.match(emailPattern)) {
      setEmailError(t("Introduce un email válido"));
    } else if (email !== repeatEmail && repeatEmail != "") {
      setEmailError(t("Los correos son diferentes"));
      setRepeatEmailError(t("Los correos son diferentes"));
    } else {
      setEmailError("");
      setRepeatEmailError("");
      updateEmail(email);
    }
  };
  const checkRepeatemail = () => {
    if (email == "") {
      if (repeatEmail === "") {
        setRepeatEmailError(t("El campo no puede estar vacío"));
      } else {
        if (!repeatEmail.match(emailPattern)) {
          setRepeatEmailError(t("Introduce un email válido"));
        } else {
          setEmailError(t("El campo no puede estar vacío"));
          setRepeatEmailError(t("Los correos son diferentes"));
        }
      }
    } else {
      if (email !== repeatEmail) {
        setEmailError(t("Los correos son diferentes"));
        setRepeatEmailError(t("Los correos son diferentes"));
      } else {
        if (repeatEmail.match(emailPattern)) {
          setEmailError("");
        } else {
          setRepeatEmailError(t("Introduce un email válido"));
        }
      }
    }
    if (email == repeatEmail && repeatEmail.match(emailPattern)) {
      updateEmail(email);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="email"
          value={email}
          required
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
            updateEmail("");
          }}
          onBlur={checkEmail}
          error={emailError !== "" || generalError !== ""}
          helperText={emailError}
          label={t("common.email")}
          variant="outlined"
          autoComplete="email"
          type="email"
          inputMode="email"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          required
          id="outlined-basic"
          value={repeatEmail}
          onBlur={checkRepeatemail}
          onChange={(e) => {
            setRepeatEmail(e.target.value);
            setRepeatEmailError("");
            updateEmail("");
          }}
          error={repeatEmailError !== "" || generalError !== ""}
          helperText={repeatEmailError}
          label={t("common.repemail")}
          autoComplete="email"
          type="email"
          variant="outlined"
          inputMode="email"
        />
      </Grid>
    </>
  );
};

export default ClientEmailForm;
