import React, { useState } from "react";
import { AppBar, Drawer, IconButton, Grid, Toolbar, Typography, Hidden, SwipeableDrawer, Avatar } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import AuthDrawer from "./AuthDrawer";
import { useHistory, useLocation } from "react-router-dom";
import User from "./User";
import SelectList from "./SelectList";
import LangSelect from "./LangSelect";
import { grey, purple } from "@material-ui/core/colors";
import BexenCardioLogoH from "../../assets/images/BEXEN_Cardio_Horiz_Blanco.png";
import BexenCardioLogoColorH from "../../assets/images/0_3_BEXEN_Cardio_Horiz.png";

const Navigation = ({}) => {
  const classes = useStyles();

  const [anchor, setanchor] = useState(false);
  const [openDrawer, setopenDrawer] = useState(false);

  const location = useLocation();
  const history = useHistory();

  var currentLoc = location.pathname.split("/").pop();
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={1}>
        <Toolbar>
          <div style={{ alignItems: "center", display: "flex" }}>
            <Hidden lgUp>
              <IconButton onClick={() => setopenDrawer(true)}>
                <Menu color="primary" />
              </IconButton>
            </Hidden>

            <Typography variant="h5" className={classes.title}>
              <img src={BexenCardioLogoColorH} className={classes.logo} />
            </Typography>
          </div>

          {!ROUTES.SOURCES.includes(currentLoc) &&
            (ROUTES.DEVICES.includes(currentLoc) || ROUTES.USERS.includes(currentLoc) || ROUTES.GROUPS.includes(currentLoc)) /*||
              ROUTES.PROGRAMS.includes(currentLoc)*/ && <AuthUserContext.Consumer>{(authUser) => <SelectList list={authUser.programas} category={currentLoc} />}</AuthUserContext.Consumer>}

          <div style={{ flex: 1 }}></div>
          <Hidden xsDown>
            <LangSelect style={{ marginRight: 6 }} icon />
          </Hidden>
          <Hidden smUp>
            <LangSelect style={{ marginRight: 6 }} />
          </Hidden>

          <AuthUserContext.Consumer>{(authUser) => authUser && <User user={authUser} />}</AuthUserContext.Consumer>
        </Toolbar>
      </AppBar>

      {/**drawer para versiones moviles y tablets */}

      <Hidden lgUp>
        <SwipeableDrawer
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          open={openDrawer}
          onOpen={() => setopenDrawer(true)}
          onClose={() => setopenDrawer(false)}
        >
          <Toolbar>
            <Grid container alignItems="bottom" style={{ marginTop: 18 }}>
              <img src={BexenCardioLogoColorH} style={{ width: 164 }} />
            </Grid>
          </Toolbar>

          <AuthUserContext.Consumer>{(authUser) => authUser && <AuthDrawer user={authUser} setanchor={setanchor} />}</AuthUserContext.Consumer>
        </SwipeableDrawer>
      </Hidden>

      {/**ocultar el drawer para versiones moviles y tablets */}
      <Hidden mdDown>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <AuthUserContext.Consumer>{(authUser) => authUser && <AuthDrawer user={authUser} setanchor={setanchor} />}</AuthUserContext.Consumer>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Navigation;

const drawerWidth = 251;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
    [theme.breakpoints.up("sm")]: {},
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflowX: "hidden",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 0.2,
    marginRight: 20,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  logo: {
    maxWidth: 164,
    verticalAlign: "bottom",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: 150,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
