import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { PowerSettingsNew } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { singOut } from "../DataNet/DataNetAuth";

const SingOutMenuItem = () => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  return (
    <MenuItem button onClick={() => (singOut(), setIsClicked(true))}>
      <ListItemIcon>
        {isClicked ? (
          <CircularProgress size={22} />
        ) : (
          <PowerSettingsNew color={"primary"} />
        )}
      </ListItemIcon>
      <ListItemText>{t("common.singOut")}</ListItemText>
    </MenuItem>
  );
};

export default SingOutMenuItem;
