import React, { useEffect } from "react";
import { AuthUserContext, withAuthentication } from "../Session";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { loggedUser } from "../DataNet/DataNetAuth";

const HomePage = () => {
  const history = useHistory();

  useEffect(() => {
    if (!!!loggedUser) {
      //loggedUser.roles.includes("admin") && history.push(ROUTES.PROGRAMS);
      //loggedUser.roles.includes("coordinator") && history.push(ROUTES.GROUPS);
    } else {
      //history.push(ROUTES.SIGN_IN);
    }
  }, []);

  return <></>;
};

// && !!authUser.roles.includes("Admin");

export default withAuthentication(HomePage);
