import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./en.json";
import esTranslation from "./es.json";
import euTranslation from "./eus.json";
import itTranslation from "./it.json";

import moment from "moment";
import { langsLocale } from "../components/Navigation/LangSelect";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  it: {
    translation: itTranslation,
  },
};

const options = {
  order: ["cookie", "navigator"],
  lookupQuerystring: "lng",
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: options,
    fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then((v) => {
    const localeCode = i18n.language.split("-")[0];
    moment.locale(localeCode, langsLocale[localeCode]);
  });

export default i18n;
