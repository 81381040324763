import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { FlashOn, Notifications } from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import moment, { utc } from "moment";

const DeviceUsageStatus = ({ active_notifications }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Typography variant="h6" color="secondary" style={{ padding: "20px 20px 10px 10px" }}>
          {t("use_notifications.pending.title")}
        </Typography>
      </Grid>
      {[...active_notifications].reverse().map((x) => (
        <UseNotification date={x.creation_date} t={t}></UseNotification>
      ))}
    </>
  );
};

const UseNotification = ({ date, type, t }) => (
  <Grid item xs={12} container alignItems="center">
    <Notifications style={{ marginRight: 16, color: red[300] }} fontSize="medium" />
    <Typography variant="body1" color="secondary" style={{ padding: "2px 20px 2px 0px" }}>
      {t("use_notifications.item")}
    </Typography>
    <Typography variant="body1" color="textPrimary">
      {moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")}
    </Typography>
  </Grid>
);

export default DeviceUsageStatus;
