import React from "react";

import AuthUserContext from "./context";
import { currentUser, client } from "../DataNet/DataNetAuth";
import { CircularProgress, Grid } from "@material-ui/core";
import moment from "moment";
import i18n from "../../i18n";

const signalR = require("@microsoft/signalr");
var _ = require("lodash");

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        loaded: false,
        reload: null,
      };
    }

    componentDidMount() {
      let self = this;

      currentUser().then((authUser) => {
        this.setState({ authUser: authUser, loaded: true });
        !!authUser && i18n.changeLanguage(authUser?.lang);
      });

      let r = setInterval(() => {
        currentUser().then((authUser) => {
          !_.isEqual(authUser, this.state.authUser) && this.setState({ authUser: authUser });
        });
      }, 10000);

      this.setState({
        reload: r,
      });

      /*client.onmessage = (message) => {
        console.log("datos recividos", message.data);
        //let authUser = message.data === "" ? null : JSON.parse(message.data);
        //console.log(authUser);
        this.setState({ authUser: JSON.parse(message.data).authUser });
      };*/

      /**
       * enviar
       */
      //this.setState({ authUser: null });
      /*this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          authUser
            ? this.setState({ authUser })
            : this.setState({ authUser: null });
        }
      );*/
    }

    componentWillUnmount() {
      clearInterval(this.state.reload);
    }

    render() {
      //console.log("valor", this.state.authUser);
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
