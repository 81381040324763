import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Button,
  Typography,
  IconButton,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import DeviceForm from "../AddDevice/DeviceForm";
import { useTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";
import * as DATANETAPI from "../DataNet/DataNet";
import ErrorDialog from "../ErrorDialog";
import ButtonLoader from "../ButtonLoader";

const EditDevice = ({ open, handleClose, data, userData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isFormValid, setisFormValid] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isError, setIsError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isWattingForBK, setIsWattingForBK] = useState(false);
  const [programId, setProgramId] = useState(!!data ? data.programid : "00000000-0000-0000-0000-000000000000");
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");

  const loadFormValues = (values) => {
    !isEdited && !!formValues && setIsEdited(true);
    setFormValues(values);
    setProgramId(data.programid);
  };

  const resetForm = () => {
    handleClose(false);
    setOpenAlert(false);
    setIsError(false);
    setFormValues({});
    setIsEdited(false);
  };

  const handleSubmit = () => {
    if (isEdited) {
      setIsWattingForBK(true);
      if (isFormValid) {
        var deviceForm = {
          device: formValues,
        };
        if (!(typeof deviceForm.device.expirationDatePads === "string") && "_f" in deviceForm.device.expirationDatePads && deviceForm.device.expirationDatePads._f === "MM/yyyy") {
          var date_pads = new Date(formValues.expirationDatePads);
          date_pads.setMonth(date_pads.getMonth() + 1);
          deviceForm.device.expirationDatePads = date_pads.toJSON();
        }

        if (!(typeof deviceForm.device.expirationDateBatt === "string") && "_f" in deviceForm.device.expirationDateBatt && deviceForm.device.expirationDateBatt._f === "MM/yyyy") {
          var date_pads = new Date(formValues.expirationDateBatt);
          date_pads.setMonth(date_pads.getMonth() + 1);
          deviceForm.device.expirationDateBatt = date_pads.toJSON();
        }

        deviceForm.device.programid = data.programid;
        deviceForm.device.groupid = data.groupid;
        DATANETAPI.editDevice({ ...deviceForm, programId: data.programid, groupid: data.groupid }, data.id).then((snap) => {
          if (snap.status < 0) {
            setConnectionErrorMsg(t(`error.connection_${snap.status}`));
          } else {
            setIsWattingForBK(false);
            if (snap != undefined) {
              setOpenAlert(true);
              if (snap.status === 1) {
                setIsError(false);
              } else {
                setIsError(true);
              }
            }
          }
        });
      } else {
        setIsError(true);
      }
    } else {
      resetForm();
    }
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              handleClose(false);
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t("devices.edit.title") + " " + data.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Grid container spacing={3} justify="center">
          <DeviceForm
            models={devicesModelsBack}
            model={devicesModelsBack.indexOf(data.model)}
            currentposition={{
              lat: !!!data.location ? 48 : data.location.latitude,
              lng: !!!data.location ? -2 : data.location.longitude,
            }}
            value={data}
            setFormValues={loadFormValues}
            setisFormValid={setisFormValid}
            isFormValid={true}
            editForm={true}
            user={userData}
            programId={programId}
          />

          <Grid container item xs={12} lg={9}>
            <Grid item xs={12} container spacing={2} justify="flex-end" style={{ paddingRight: 20 }}>
              <Grid item>
                <Button color="error" style={{ color: red[500] }} onClick={resetForm}>
                  {t("common.close")}
                </Button>
              </Grid>

              <Grid item style={{ position: "relative" }}>
                <Button variant="contained" color="primary" disabled={!isFormValid || !isEdited || isWattingForBK} onClick={() => handleSubmit()}>
                  {t("common.ok")}
                </Button>
                {isWattingForBK && <ButtonLoader />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />

      <Dialog open={openAlert}>
        <DialogTitle id="alert-dialog-title">{!isError ? t("devices.setUp.dialog.title") : t("devices.setUp.dialog.title.error")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{!isError ? t("devices.setUp.dialog.description") : t("devices.setUp.dialog.description.error")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAlert(false);
              !isError && resetForm();
            }}
            variant="contained"
            color="primary"
          >
            {t("devices.new.dialog.agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default EditDevice;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const deviceDataBack = {
  id: 6,
  name: "R200 Ayuntamiento",
  serial: "2020/20202414",
  manufacturer: "Bexen Cardio",
  disabled: false,
  conectivity: false,
  model: "Reanibex 200",
  status: { main: 0 },
  location: {
    name: "Osatu S.coop",
    latitude: 43.1854194,
    longitude: -2.5099712,
  },
  battery: {
    type: "t_battery",
    id: 114125,
    serial: "2016/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 200"],
  },
  pads: {
    type: "t_pads",
    id: 154127,
    serial: "50623-0773",
    reference: "KSA 0501 B",
    expiration_date: "2021-02-05T11:00:07.865465",
    total: 5,
    used: 3,
    compatibility: ["Reanibex 200", "Reanibex 300", "Reanibex 500"],
  },
};

const devicesModelsBack = ["Reanibex 100", "Reanibex 200", "Reanibex 300", "Reanibex 500"];
