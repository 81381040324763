import { Grid, Paper, Typography, IconButton, Tooltip, makeStyles, SvgIcon, Link, useTheme, useMediaQuery, Chip } from "@material-ui/core";
import { AssignmentOutlined, Edit, Error, LocationOn, Settings, SyncOutlined, SystemUpdateRounded, Warning } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import * as API_KEYS from "../../constants/apiKeys";
import { green, grey, orange, red } from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DeviceSetupForm from "../DeviceSetupForm";
import EditDevice from "../EditDevice";
import { AuthUserContext, withAuthentication, withAuthorization } from "../Session";
import DeviceHourEditor from "../AddDevice/DeviceHourEditor";
import batteryRemainingCalc from "../../functions/BatteryChargeCalc";
import getDeviceStatus from "../../functions/getDeviceStatus";
import Licenses from "./Licenses";
import getDeviceComunications from "../../functions/getDeviceComunications";

import * as DATANETAPI from "../DataNet/DataNet";
import * as COMNS from "../../constants/comsTypes";

const properties_mapping = {
  msg_long: ["device.details.values.msglength0", "device.details.values.msglength1"],
  alert: ["device.details.values.enable0", "device.details.values.enable1"],
  trainner_mode: ["device.details.values.enable0", "device.details.values.enable1"],
  ratio_adult: ["device.details.values.ratio0", "device.details.values.ratio1", "device.details.values.ratio2"],
  ratio_pediatric: ["device.details.values.ratio0", "device.details.values.ratio1", "device.details.values.ratio2"],
  rate: [100, 110, 120],
  patient_type: ["device.details.values.patient_ped", "device.details.values.patient_adult"],
  alert_warning: ["device.details.values.enable0", "device.details.values.enable1"],
  alert_patient: ["device.details.values.enable0", "device.details.values.enable1"],
  mains_filter: [0, 50, 60],
  priority: [
    "common.off",
    "devices.setUp.trans_mode.priority.sigfox_only",
    "devices.setUp.trans_mode.priority.sigfox_first",
    "devices.setUp.trans_mode.priority.wifi_only",
    "devices.setUp.trans_mode.priority.wifi_first",
  ],
  transmit_frequency: ["devices.setUp.trans_mode.frequency.send_everyday", "devices.setUp.trans_mode.frequency.send_4_30_days", "devices.setUp.trans_mode.frequency.send_1_30_days"],
  gps_enable: ["device.details.values.enable0", "device.details.values.enable1"],
};

const units = {
  cpr1: "device.details.units.msc",
  cpr2: "device.details.units.msc",
  ratio_adult: "",
  ratio_pediatric: "",
  rate: "device.details.units.copresions_minute",
  energy1_adult: "device.details.units.energy",
  energy2_adult: "device.details.units.energy",
  energy3_adult: "device.details.units.energy",
  energy1_pediatric: "device.details.units.energy",
  energy2_pediatric: "device.details.units.energy",
  energy3_pediatric: "device.details.units.energy",
  mains_filter: "device.details.units.filter",
};

const DeviceUpdateStatus = ({ property, value }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={4}>
      <Tooltip placement="top" arrow title={<Typography variant="body2">{t("newUpdateAvailableTitle")}</Typography>}>
        <Paper
          variant="outlined"
          style={{
            borderRadius: 16,
            padding: 18,
            borderColor: value == 2 ? red[400] : orange[400],
            backgroundColor: value == 2 ? red[100] : orange[50],
          }}
        >
          <Typography
            variant="h4"
            style={{
              color: value == 2 ? red[400] : orange[400],
              fontWeight: 500,
            }}
          >
            {value == 2 ? "Error" : t("new")}
          </Typography>

          <Grid container direction="row" justifyContent="flex-start" alignContent="center">
            <Typography variant="body1" color="textSecondary">
              {property}
            </Typography>
          </Grid>
        </Paper>
      </Tooltip>
    </Grid>
  );
};

const DeviceSettingLang = ({ default_lang, list_langs, haslangUpdate, groupLangs }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sm={8} lg={9} xl={9}>
        <Typography variant="body1" color="secondary" style={{ padding: "2px 0", marginTop: 18, fontWeight: 500 }}>
          {t("device.details.config.langs.list")}
        </Typography>
        <Typography variant="body1" color="textPrimary" style={list_langs.some((x, i) => groupLangs?.list[i] !== x) ? { color: grey[500], textDecorationLine: "line-through" } : {}}>
          {list_langs.map((x, i) => (i !== list_langs.length - 1 ? `${t("common.lang." + x)}, ` : t("common.lang." + x)))}
        </Typography>
        {list_langs.some((x, i) => groupLangs?.list[i] !== x) && (
          <Typography variant="body1" color="textPrimary" style={{ color: orange[500] }}>
            {groupLangs?.list.map((x, i) => (i !== groupLangs?.list?.length - 1 ? `${t("common.lang." + x)}, ` : t("common.lang." + x)))}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} lg={3} xl={3}>
        <Typography variant="body1" color="secondary" style={{ padding: "2px 0", marginTop: 18, fontWeight: 500 }}>
          {t("device.details.config.langs.default_lang")}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          style={default_lang != groupLangs?.default_lang || list_langs.some((x, i) => groupLangs?.list[i] !== x) ? { color: grey[500], textDecorationLine: "line-through" } : {}}
        >
          {t("common.lang." + list_langs[default_lang - 1])}
        </Typography>
        {console.log(list_langs, groupLangs?.list)}
        {(default_lang != groupLangs?.default_lang || list_langs.some((x, i) => groupLangs?.list[i] !== x)) && (
          <Typography variant="body1" color="textPrimary" style={{ color: orange[500] }}>
            {t("common.lang." + groupLangs?.list[groupLangs?.default_lang - 1 ?? 0])}
          </Typography>
        )}
      </Grid>
    </>
  );
};

const DeviceSettingShocks = ({ property, data, groupValue }) => {
  const { t } = useTranslation();
  let properties = Object.keys(data);
  properties.shift(); //primer elemento eliminado que es los shocks consecutivos

  return (
    <>
      {properties.map((value) => (
        <DeviceSettingItem property={value} property_label={t(`device.details.config.${property}.${value}`)} value={data[value]} groupValue={groupValue?.[value]} />
      ))}
      <DeviceSettingItem
        property={"consecutive_shocks"}
        property_label={t(`device.details.config.${property}.consecutive_shocks`)}
        value={data["consecutive_shocks"]}
        groupValue={groupValue?.["consecutive_shocks"]}
      />
    </>
  );
};

const DeviceSettingItem = ({ property, property_label, value, groupValue }) => {
  const { t } = useTranslation();

  /** Componente general donde se renderizan las caracteristicas */
  const parse_value = (property, value) => {
    var msg = "";
    try {
      if (property === "utc_time" && value > 0) return "+" + value;
      if (properties_mapping[property] !== undefined) {
        msg = t(properties_mapping[property][value]);
      } else {
        msg = value;
      }

      if (units[property] !== undefined) {
        msg = msg + " " + t(units[property]);
      }

      if (property === "mains_filter" && value === 0) {
        msg = t("devices.setUp.alert_mode.none");
      }
    } catch (error) {
      // console.log(error);
    }
    return msg;
  };

  //console.log(property_label, value, groupValue, !!groupValue);

  return (
    <Grid item xs={12} sm={4}>
      <Typography variant="body1" color="secondary" style={{ padding: "2px 0", marginTop: 18, fontWeight: 500 }}>
        {property_label}
      </Typography>
      <Typography variant="body1" color="textPrimary" style={groupValue != value ? { color: grey[400], textDecorationLine: "line-through" } : {}}>
        {parse_value(property, value)}
      </Typography>
      {groupValue != value && (
        <Typography variant="body1" style={{ color: orange[500] }}>
          {parse_value(property, groupValue)}
        </Typography>
      )}
    </Grid>
  );
};

const DeviceInfoItem = ({ property, value, loading, outOfDate = false, warning = 0 }) => {
  return (
    <Grid item xs={6} sm={4} md={3}>
      <Typography variant="body1" color="secondary" style={{ padding: "2px 0", marginTop: 18 }}>
        {loading ? <Skeleton variant="text" /> : property}
      </Typography>
      <Typography variant="body1" color="textPrimary" style={{ color: warning == 1 ? orange[500] : warning == 2 && red[500] }}>
        {loading ? <Skeleton variant="text" /> : outOfDate ? <DataNotAvailable /> : value}
      </Typography>
    </Grid>
  );
};

const DeviceAccesoryItem = ({ type, info, loading, model, syncOutOfDate = false, isConnected = true, battery, demo, demoPadsInNormalMode = false, unknownBattery = false }) => {
  const { t } = useTranslation();
  let exDate = moment(info.expiration_date).format("YYYY / MM");
  let now = moment(new Date()).format("YYYY/MM");
  const padsConnectedByTime = moment(info?.expiration_date).format("YYYY-MM") !== "0001-01"; // parches con fecha "default" son parches no conectados
  const estimatedWarning = type == "pads" ? moment(info?.expiration_date).format("YYYY-MM") !== "0001-01" && moment().isAfter(moment(info?.expiration_date)) : false;
  return (
    <>
      <Grid item xs={12} sm={4} container style={{ marginTop: 12 }}>
        <Grid item xs={12} container justifyContent="flex-start" alignItems="baseline">
          {!loading && !syncOutOfDate && type !== "battery" && model == "Reanibex 100" && <ExpirationDateicon timeGap={moment(exDate).diff(moment(now), "months")} />}
          <Typography color="textSecondary" style={{ fontWeight: 600, fontSize: 18, marginBottom: 9, paddingRight: 3 }}>
            {loading ? <Skeleton variant="rect" width={175} /> : t(`common.${type}`)}{" "}
          </Typography>
          {!loading && type == "pads" && estimatedWarning && !demo && (
            <Tooltip title={<Typography variant="body2">{t("pads.mightExpired.tooltip")}</Typography>} placement="top">
              <Typography variant="body1" style={{ color: orange[500], alignSelf: "baseline" }}>
                {` · ${t("pads.mightExpired")}`}
              </Typography>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          {(type === "pads" || (type === "battery" && model !== "Reanibex 100")) && (
            <>
              <Typography variant="body1" color="secondary">
                {loading ? <Skeleton variant="text" width={175} /> : t("device.details.accesories.expirationDate")}
              </Typography>
              <Typography variant="body1" style={{ color: demoPadsInNormalMode && orange[500] }}>
                {loading ? (
                  <Skeleton variant="text" width={175} />
                ) : (!syncOutOfDate || model !== "Reanibex 100") && isConnected && padsConnectedByTime ? (
                  demo ? (
                    t("common.demoMode")
                  ) : demoPadsInNormalMode ? (
                    t("error_eREG_23")
                  ) : (
                    exDate
                  )
                ) : !isConnected || !padsConnectedByTime ? (
                  <AccesoryNoConnected />
                ) : (
                  <DataNotAvailable />
                )}
              </Typography>
            </>
          )}
        </Grid>
        {type === "battery" && model === "Reanibex 100" && (
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography variant="body1" color="secondary">
              {loading ? <Skeleton variant="text" width={175} /> : t("status.simple.battery_charge")}
            </Typography>
            <Typography variant="body1" color={unknownBattery ? "error" : ""}>
              {loading ? <Skeleton variant="text" width={175} /> : !syncOutOfDate && !unknownBattery ? `${battery} %` : unknownBattery ? t("error_eREG_31.min") : <DataNotAvailable />}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const ExpirationDateicon = ({ timeGap }) => {
  if (timeGap < 0) {
    return (
      <Error
        style={{
          color: red[500],
          marginLeft: -34,
          marginRight: 6,
          fontSize: 26,
        }}
      />
    );
  } else {
    /** seleccionar cuando se quiere mostar el warning de caducidad proxima*/
    if (timeGap <= 3) {
      return (
        <Warning
          style={{
            color: orange[500],
            marginLeft: -34,
            marginRight: 6,
            fontSize: 26,
          }}
        />
      );
    } else {
      return <></>;
    }
  }
};

const DeviceGeneralSettings = ({ device, property, groupSettings }) => {
  const { t } = useTranslation();

  if (property == "misc") {
    /* hay que evitar enviar este dato   Se elimina a mano para que no aparezca en la lista de propiedaes
     */
    delete device?.config?.device_setting?.misc?.trainner_mode;
  }
  return (
    <>
      <Grid item xs={12} container alignItems="center" style={{ paddingBottom: 24 }}>
        {property === "langs" && device?.status?.update?.has_new_audio !== 0 && (
          <Tooltip title={<Typography variant="body2">{t("deviceLangnotsyncTitle")}</Typography>} placement="top">
            <Warning
              style={{
                color: orange[400],
                paddingRight: 8,
                paddingBottom: 6,
              }}
            />
          </Tooltip>
        )}
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "30px",
            color: property === "langs" && device?.status?.update?.has_new_audio !== 0 && orange[400],
          }}
          color="secondary"
        >
          {t(`device.details.config.title.${property}`)}
        </Typography>

        <Grid item container spacing={2} style={{ marginBottom: 18 }}>
          {property === "langs" && (
            <DeviceSettingLang
              default_lang={device?.config?.device_setting[property]["default_lang"]}
              list_langs={device?.config?.device_setting[property]["list"]}
              haslangUpdate={device?.status?.update?.has_new_audio}
              groupLangs={groupSettings?.[property]}
            />
          )}
          {property === "shocks" && <DeviceSettingShocks property={property} data={device.config.device_setting[property]} groupValue={groupSettings?.[property]} />}
          {property !== "shocks" &&
            property !== "langs" &&
            Object.keys(device?.config?.device_setting[property]).map(
              (value) =>
                value !== "transmission_mode" && (
                  <DeviceSettingItem
                    property={value}
                    property_label={t(`device.details.config.${property}.${value}`)}
                    value={device?.config?.device_setting[property][value]}
                    groupValue={groupSettings?.[property][value]}
                  />
                )
            )}
        </Grid>
      </Grid>
    </>
  );
};

const DeviceGeneralSettingsComms = ({ device, property, groupSettings }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} container alignItems="center">
      <Typography
        style={{
          fontWeight: 500,
          fontSize: 20,
          lineHeight: "30px",
        }}
        color="secondary"
      >
        {t(`devices.setUp.comms.title`)}
      </Typography>

      <Grid item container spacing={2} style={{ marginBottom: 18 }}>
        {property !== "shocks" &&
          property !== "langs" &&
          Object.keys(device?.config?.device_setting[property]).map(
            (value) =>
              value === "transmission_mode" &&
              Object.keys(device?.config?.device_setting[property][value]).map((x) => {
                /* se comprueba la prioridad cuando se quiere mostrar la frecuencia de transmision para evitar mostrarla cuando no se puede modificar */
                const priority = device.config.device_setting.misc.transmission_mode.priority;

                if (x == "transmit_frequency") {
                  if (priority == 3 || priority == 4) {
                    return (
                      <DeviceSettingItem
                        property={x}
                        property_label={t(`device.details.config.misc.trans_mode.${x}`)}
                        value={device.config.device_setting[property][value][x]}
                        groupValue={groupSettings?.[property]?.[value]?.[x]}
                      />
                    );
                  } else {
                    return <></>;
                  }
                } else {
                  return (
                    <DeviceSettingItem
                      property={x}
                      property_label={t(`device.details.config.misc.trans_mode.${x}`)}
                      value={device.config.device_setting[property][value][x]}
                      groupValue={groupSettings?.[property]?.[value]?.[x]}
                    />
                  );
                }
              })
          )}
      </Grid>
    </Grid>
  );
};

const DeviceDetailTab = (props) => {
  const { device, isNewDevice, IsPermanentLoading, reload, authUser } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [deviceSetupOpen, setDeviceSetupOpen] = useState(false);
  const [deviceEditOpen, setDeviceEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasUpdate, setHasUpdate] = useState(false);
  const [groupSettings, setGroupSettings] = useState(null);

  const syncOutOfDate =
    device.model === "Reanibex 100"
      ? moment().diff(device?.status?.device_status?.updateDate, "months") >= (device?.connectivity ? 3 : 12) || device?.status?.device_status?.updateSource === 0
      : false;

  useEffect(() => {
    if (!IsPermanentLoading && !deviceSetupOpen) {
      setIsLoading(false);
      if (!isNewDevice) {
        let haspendingUpdate = false;
        Object.keys(device?.status?.update).forEach((u) => {
          haspendingUpdate = haspendingUpdate || device?.status?.update[u] !== 0;
        });
        setHasUpdate(haspendingUpdate);
      }
      //se cargan los datos del grupo para ver la diferencia de configuración del equipo

      if (device?.groupid) {
        DATANETAPI.getGroup(device?.groupid).then((snap) => {
          if (snap?.status == 1) {
            setGroupSettings(snap?.data?.config_current?.device_setting);
          }
        });
      }
    }
  }, [device, deviceSetupOpen]);

  useEffect(() => {
    /* cuando se cierre la edición se recaarga la info del equipo */
    if (!deviceEditOpen && !IsPermanentLoading && !isNewDevice) {
      reload();
      setIsLoading(true);
    }
  }, [deviceEditOpen]);

  /* --------------- eliminar en el futuro------------- */
  /* Se eliminan las 3 propiedades que se tienen que mostrar en la siguiente versión */
  if (!IsPermanentLoading) {
    if (!!device.config) {
      delete device.config.device_setting.misc.alert_mode;
      delete device.config.device_setting.misc.alert_move;
      if (device.soft_version < "0210") {
        delete device.config.device_setting.misc.alert_patient;
      }
      if (device.soft_version < "0209") {
        delete device.config.device_setting.misc.alert_warning;
      }
      /** eliminado el Alertas activas? */
      delete device.config.device_setting.misc.alert;
    }
  }

  const batteryCalc =
    device.model === "Reanibex 100" &&
    batteryRemainingCalc({
      hasComs: device?.connectivity,
      syncDate: moment(device?.status?.device_status?.updateDate),
      software: device?.soft_version,
      battery: device?.status?.device_status?.batteryStatus,
      updateSource: device?.status?.device_status?.updateSource,
    });

  const generalStatusValue = !isLoading && device.model === "Reanibex 100" ? getDeviceStatus(device?.status, syncOutOfDate, batteryCalc, device?.soft_version) : 0;

  return (
    <Grid container spacing={4} style={{ marginTop: 12 }}>
      {!isNewDevice && (
        <Grid item container spacing={2}>
          <Grid item xs={12} container alignItems="center">
            {isLoading ? (
              <Skeleton variant="circle" width={24} height={24} style={{ marginRight: 8, height: 30, width: 30 }} />
            ) : (
              device.model === "Reanibex 100" && (
                <SvgIcon color="secondary" style={{ marginRight: 8, height: 30, width: 30 }}>
                  <path d="M22,13v3c0,1.1-0.9,2-2,2h-3l1,1v2H6v-2l1-1H4c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2l8,0v2L4,5v11h16v-3H22z M15,15l-5-5h4V3 h2v7h4L15,15z" />
                </SvgIcon>
              )
            )}

            <Typography variant="h5" color="secondary" style={{ fontWeight: 500, fontSize: 22 }}>
              {isLoading ? <Skeleton variant="text" width={275} /> : device.model === "Reanibex 100" && t("updates")}
            </Typography>
          </Grid>
          {syncOutOfDate && device?.model == "Reanibex 100" && !IsPermanentLoading && !isLoading && (
            <Grid item xs={12}>
              <Paper variant="outlined" style={{ borderRadius: 16, padding: 18, borderColor: orange[500], backgroundColor: orange[50] }}>
                <Typography variant="h4" style={{ color: orange[400], fontWeight: 500, marginBottom: 16 }}>
                  {t("sincronización necesaria")}
                </Typography>

                <Grid container direction="row" justifyContent="flex-start" alignContent="center">
                  <Typography variant="body1" color="textSecondary" style={{ paddingLeft: 8, width: "100%" }}>
                    {t(`NotSync.deviceDetailCard.${device?.status?.device_status?.updateSource === 0 ? "Default" : device?.connectivity ? "coms" : "Nocoms"}`)}&nbsp;
                    <Link style={{ cursor: "pointer" }} onClick={() => (window.location.href = "https://usbsync.blob.core.windows.net/app/REANIBEX_USB_SYNC.exe")}>
                      {t("common.DawnloadUSB_Sync")}
                    </Link>
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          )}
          {!IsPermanentLoading &&
            !isLoading &&
            device.model == "Reanibex 100" &&
            Object.keys(device?.status?.update).map((u) => device?.status?.update[u] != 0 && <DeviceUpdateStatus property={t(u)} value={device?.status?.update[u]} />)}

          {(isLoading || IsPermanentLoading) && (
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                style={{
                  borderRadius: 16,
                  padding: 18,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 500,
                  }}
                >
                  <Skeleton variant="text" />
                </Typography>

                <Typography variant="body1" color="textPrimary" style={{ marginTop: 9 }}>
                  <Skeleton variant="text" />
                </Typography>
              </Paper>
            </Grid>
          )}
          {!hasUpdate && !isLoading && !IsPermanentLoading && device.model == "Reanibex 100" && !syncOutOfDate && (
            <Grid item xs={12}>
              <Tooltip placement="top" arrow title={<Typography variant="body2"> {t("deviceIsUpdated")}</Typography>}>
                <Paper
                  variant="outlined"
                  style={{
                    borderRadius: 16,
                    padding: 18,
                    borderColor: green[600],
                  }}
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 500,
                      color: green[600],
                    }}
                  >
                    {t("deviceIsUpdated")}
                  </Typography>

                  <Typography variant="body1" color="textSecondary" style={{ marginTop: 9 }}>
                    {t("deviceHasAllUpdates")}
                  </Typography>
                </Paper>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}

      {!IsPermanentLoading && device.model == "Reanibex 100" /*&& !!device.licences */ && false && <Licenses isLoading={isLoading} licences={device.licences} serialNumber={device.serial} />}

      <Grid container spacing={2} item>
        <Grid container item xs={12}>
          <Grid item xs={10} container alignItems="center">
            {isLoading ? (
              <Skeleton
                variant="circle"
                style={{
                  marginRight: 8,
                }}
                width={30}
                height={30}
              />
            ) : (
              <AssignmentOutlined
                color="secondary"
                style={{
                  marginRight: 8,
                  height: 30,
                  width: 30,
                }}
              />
            )}

            <Typography color="secondary" style={{ fontWeight: 500, fontSize: 22, lineHeight: "30px" }}>
              {isLoading ? <Skeleton width={250} /> : t("device.details.title.description")}
            </Typography>

            {device?.status?.is_demo && (
              <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "30px", color: orange[500] }}>
                &nbsp;
                {!isLoading && "· Demo"}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            <Tooltip title={<Typography variant="body2">{isNewDevice ? t("device.details.edit.disabled") : t("device.details.edit.enabled")}</Typography>}>
              <snap>
                <IconButton color="primary" onClick={() => setDeviceEditOpen(true)} disabled={isNewDevice}>
                  {isLoading ? <Skeleton variant="circle" width={24} height={24} /> : <Edit />}
                </IconButton>
              </snap>
            </Tooltip>
            {<AuthUserContext.Consumer>{(authUser) => <EditDevice open={deviceEditOpen} handleClose={setDeviceEditOpen} data={device} userData={authUser} />}</AuthUserContext.Consumer>}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.paper} style={{ background: device?.status?.is_demo && orange[50] }}>
            <Grid container spacing={isMobile ? 1 : 6} direction="column">
              <Grid item container spacing={3} style={{ marginBottom: 32 }}>
                <Grid item xs={12}>
                  <Typography
                    color="secondary"
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "30px",
                    }}
                  >
                    {isLoading ? <Skeleton variant="text" width={250} /> : t("device.details.title.deviceDetails")}
                  </Typography>
                </Grid>
                <DeviceInfoItem property={t("common.name")} value={device.name} loading={isLoading} />
                <DeviceInfoItem property={t("common.program")} value={authUser?.programas?.find((p) => p?.id == device?.programid)?.name} loading={isLoading} />
                <DeviceInfoItem property={t("common.group")} value={device.groupName} loading={isLoading} />
                <DeviceInfoItem property={t("common.serialNumber")} value={device.serial} loading={isLoading} />
                <DeviceInfoItem property={t("common.model")} value={device.model} loading={isLoading} />
                <DeviceInfoItem property={t("common.manufacturer")} value={device.manufacturer} loading={isLoading} />
                <DeviceInfoItem
                  property={t("common.conectivity")}
                  value={
                    device.connectivity
                      ? getDeviceComunications(device?.options).map((v, i) => (
                          <Chip label={v} key={i} variant="default" size="small" color="secondary" style={{ borderRadius: 9, marginRight: 6 }} />
                        )) /*t("device.details.conectivity") */
                      : t("device.details.noconectivity")
                  }
                  loading={isLoading}
                />
                {(device.model === "Reanibex 100" || device.model === "R100") && (
                  <DeviceInfoItem
                    property={t("common.state")}
                    value={t("device.details.status" + generalStatusValue?.statusResult)}
                    warning={generalStatusValue?.statusResult}
                    loading={isLoading}
                    outOfDate={syncOutOfDate}
                  />
                )}
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    color="secondary"
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "30px",
                    }}
                  >
                    {isLoading ? <Skeleton width={175} /> : t("common.accessories")}
                  </Typography>
                </Grid>
                <Grid container style={{ marginBottom: 32, marginLeft: 9 }}>
                  <DeviceAccesoryItem
                    type="battery"
                    info={device.battery}
                    status={device.status}
                    loading={isLoading}
                    model={device.model}
                    battery={batteryCalc}
                    syncOutOfDate={syncOutOfDate}
                    lastSync={moment(device?.status?.device_status?.updateDate).format("YYYY/MM/DD")}
                    unknownBattery={generalStatusValue?.errors?.some((s) => s?.id == 31)}
                    demo={device?.status?.is_demo}
                  />
                  <DeviceAccesoryItem
                    type="pads"
                    info={device.pads}
                    loading={isLoading}
                    model={device.model}
                    isConnected={device?.status?.device_status?.padsStatus?.is_connected}
                    syncOutOfDate={syncOutOfDate}
                    demo={device?.status?.is_demo}
                    demoPadsInNormalMode={!isLoading ? generalStatusValue.errors?.some((s) => s?.id == 23) : false}
                  />
                </Grid>
              </Grid>

              <Grid item container spacing={2} style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <Typography
                    color="secondary"
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "30px",
                    }}
                  >
                    {isLoading ? <Skeleton variant="text" width={175} /> : t("common.location")}
                  </Typography>
                </Grid>
                <DeviceInfoItem property={t("device.location.name")} value={device.location.name} loading={isLoading} />
                <DeviceInfoItem property={t("device.location.description")} value={device.location.description} loading={isLoading} />
                <Grid
                  item
                  xs={12}
                  style={{
                    minHeight: 300,
                    marginTop: 32,
                    borderRadius: 16,
                    height: "50vh",
                  }}
                >
                  {isLoading ? (
                    <Skeleton variant="rect" height={300} />
                  ) : (
                    <GoogleMap
                      bootstrapURLKeys={{
                        key: API_KEYS.MAPS_API_KEY,
                      }}
                      defaultCenter={{
                        lat: device.location.latitude,
                        lng: device.location.longitude,
                      }}
                      center={{
                        lat: device.location.latitude,
                        lng: device.location.longitude,
                      }}
                      defaultZoom={16}
                      onGoogleApiLoaded={({ map, maps }) =>
                        map.setOptions({
                          mapTypeControl: true,
                          mapTypeId: "satellite",
                          streetViewControl: true,
                        })
                      }
                    >
                      <LocationOn
                        style={{
                          color: red[500],
                          position: "absolute",
                          transform: "translate(-50%, -95%)",
                        }}
                        lat={device.location.latitude}
                        lng={device.location.longitude}
                      />
                    </GoogleMap>
                  )}
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    color="secondary"
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "30px",
                    }}
                  >
                    {isLoading ? <Skeleton variant="text" width={175} /> : t("device.location.availability")}
                  </Typography>
                </Grid>
              </Grid>
              {!isLoading && <DeviceHourEditor openingTime={device.openingTime} />}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {(device.model === "Reanibex 100" || device.model === "R100") && (!!device.config || !!device.config.device_setting) && (
        <Grid container spacing={2} item>
          <Grid container item xs={12}>
            <Grid item xs={10} container alignItems="center">
              {device.status.update.has_new_cfg !== 0 ? (
                <Tooltip title={<Typography variant="body2">{t("deviceCfgnotsyncTitle")}</Typography>} placement="top">
                  <Warning
                    style={{
                      marginRight: 8,
                      marginBottom: 6,
                      height: 30,
                      width: 30,
                      color: orange[400],
                    }}
                  />
                </Tooltip>
              ) : (
                <Settings
                  color="secondary"
                  style={{
                    marginRight: 8,
                    height: 30,
                    width: 30,
                  }}
                />
              )}

              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  color: device.status.update.has_new_cfg !== 0 && orange[400],
                }}
              >
                {t("device.details.title.configuration")} {device.status.update.has_new_cfg !== 0 && <>*</>}
              </Typography>
            </Grid>
            {device.status.update.has_new_cfg !== 0 && (
              <Grid item xs={10} container alignItems="center">
                <Typography variant="body2" style={{ color: grey[600], padding: "0px 5px" }}>
                  {t("device.details.title.configuration.update_pending")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={2} container justify="flex-end">
              {!isNewDevice && (
                <Tooltip title={<Typography variant="body2">{isNewDevice ? t("device.setUp.edit.disabled") : t("device.setUp.edit.enabled")}</Typography>}>
                  <snap>
                    <IconButton disabled={isNewDevice} color="primary" onClick={() => setDeviceSetupOpen(true)}>
                      <Edit />
                    </IconButton>
                  </snap>
                </Tooltip>
              )}

              <DeviceSetupForm
                open={deviceSetupOpen}
                handleClose={setDeviceSetupOpen}
                groupId={device.groupid} // siempre deberia de haber un grupo
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper} variant="outlined">
              <Grid container spacing={2}>
                <Grid item xs={12} container spacing={2}>
                  {Object.keys(device.config.device_setting).map((property) => (
                    <>
                      {property !== "wifi" && property !== "pcb_hw_opt" && (
                        <>
                          {property === "misc" && device.soft_version >= "0210" && !getDeviceComunications(device?.options).includes(COMNS.STANDALONE) && (
                            <DeviceGeneralSettingsComms device={device} property={property} groupSettings={groupSettings} />
                          )}
                          <DeviceGeneralSettings device={device} property={property} groupSettings={groupSettings} />
                        </>
                      )}
                    </>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const DataNotAvailable = (type, value) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={<Typography variant="body1">{t("common.updateWithUSBSync")}</Typography>}>
      <Typography variant="body1" color="textSecondary">
        {t("common.NotAvailable")}
      </Typography>
    </Tooltip>
  );
};

const AccesoryNoConnected = ({ loading }) => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ alignContent: "baseline" }}>
      <Typography variant="body1" style={{ color: orange[500] }}>
        {loading ? <Skeleton variant="text" width={175} /> : t("pads.Noconnected")}
      </Typography>
    </Grid>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(DeviceDetailTab);

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 16,
    padding: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),
      border: "0px",
      boxShadow: "0 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  syncCard: {
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: -40,
    },
  },
}));
