import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, TextField, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../ButtonLoader";
import * as DATANET from "../DataNet/DataNet";
import ErrorDialog from "../ErrorDialog";

const DeleteDialog = ({ isOpen, close, data }) => {
  const { t } = useTranslation();

  const [inputvalue, setInputvalue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [successRequest, setSuccessRequest] = useState(false);

  const handleClose = () => {
    close();
    setInputvalue("");
  };

  const handleChange = (e) => setInputvalue(e.target.value);

  const remove = () => {
    setIsLoading(true);
    DATANET.removeProgram(data.programId).then((snap) => proccessRequest(snap));
  };

  const proccessRequest = (snap) => {
    if (snap.status <= 0) {
      setErrorMsg(t(`error.connection_${snap.status}`));
    } else {
      if (snap.status === 1) {
        setSuccessRequest(true);
      } else if (snap.status === 3 || snap.status === 4) {
        setGeneralError(t("No tienes privilegios para realizar esta operación"));
      } else {
        setErrorMsg(t(`error.connection_${snap.status}`));
      }
    }
    setInputvalue("");
    setIsLoading(false);
  };

  const isValid = data.name === inputvalue && inputvalue !== "";
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <Grid container justifyContent="center" style={{ padding: 16, paddingBottom: 32 }}>
        <Grid item xs={12} container spacing={3} style={{ marginTop: 13 }}>
          <Grid item xs={12}>
            <Typography variant="body1">{t("Va a eliminar el programa", { name: data.name })}</Typography>
            <FormHelperText error>{generalError}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <TextField label={t("common.name")} error={errorDescription !== ""} value={inputvalue} placeholder={t("Escriba el nombre")} variant="outlined" fullWidth onChange={handleChange} />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Button variant="outlined" color="primary" disabled={isLoading} onClick={handleClose} style={{ marginRight: 9, textTransform: "none" }}>
              {t("common.cancel")}
            </Button>
            <Button
              variant="outlined"
              disabled={!isValid || isLoading}
              onClick={remove}
              style={{ textTransform: "none", color: isValid && !isLoading && red[500], borderColor: isValid && !isLoading && red[700] }}
            >
              {t("delete")}
              {isLoading && <ButtonLoader />}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ErrorDialog errorMsg={errorMsg} clearError={() => setErrorMsg("")} />
      <Dialog open={successRequest} onClose={() => setSuccessRequest(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"> {t("Programa eliminado")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("El programa se ha eliminado correctamente")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSuccessRequest(false);
              close();
            }}
            style={{ textTransform: "none" }}
          >
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default DeleteDialog;
