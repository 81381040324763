import { makeStyles, Paper, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const DeviceTabInfoHeader = ({ device }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper} variant="outlined">
      <Grid container justify="center">
        <Grid item xs={12} container alignItems="center">
          <Typography
            color="secondary"
            className={classes.title}
            style={{
              fontWeight: 500,
              fontSize: 22,
              lineHeight: "30px",
            }}
          >
            {t("devices.new.title.form")}
          </Typography>
        </Grid>
        <Property property={t("device.name")} value={device.name} cl={classes.propertyText} />
        <Property property={t("common.serialNumber")} value={device.serial} cl={classes.propertyText} />
        <Property property={t("common.model")} value={device.model} cl={classes.propertyText} />
        <Property property={t("common.manufacturer")} value={device.manufacturer} cl={classes.propertyText} />
      </Grid>
    </Paper>
  );
};

export default DeviceTabInfoHeader;

const Property = ({ property, value, cl }) => (
  <Grid item xs={12} sm={6} container alignItems="center">
    <Typography color="primary" variant="body1">
      {property}
    </Typography>
    <Typography className={cl} color="textSecondary" variant="body1">
      {value}
    </Typography>
  </Grid>
);

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "30.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.secondary,
  },
  lastHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.secondary,
  },
  paper: {
    borderRadius: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 2,
    },
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  propertyText: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
  },
  propertyText: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
  },
}));
