import React from "react";
import { useHistory, withRouter } from "react-router-dom";

import AuthUserContext from "./context";
import { currentUser, client } from "../DataNet/DataNetAuth";
import * as ROUTES from "../../constants/routes";
import LoadingPage from "../LoadingPage";

// Roles

/**
 * Viewer => sin rol asignado, con estar logeado es suficietne
 * trainingManager => puede gestionar el acceso al training de los usuarios a su cargo (ya se vera que usuarios son)
 * groupManager => ademas de ver, podra gestionar todas las cosas referentes a los grupos, ABM y gestionar usuarios
 * programManager => Ademas de ver y gestionar grupos podra gestionar los programas y todos los elementos
 * datanetAdmin => puede gestionar todos los contenidos del sistema
 */

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        reaload: null,
      };
    }
    componentDidMount() {
      //** Web socket para la sesión */

      const self = this;
      currentUser().then((authUser) => {
        if (!condition(authUser)) {
          self.props.history.push(ROUTES.SIGN_IN);
        }
      });
      let r = setInterval(() => {
        currentUser().then((authUser) => {
          var path = self.props.history.location.pathname;
          if (!condition(authUser) && path !== ROUTES.ACCOUNT_NEW && path !== ROUTES.REQUEST_NEW_PASSWROD) {
            self.props.history.push(ROUTES.SIGN_IN);
          }
        });
      }, 10000);

      this.setState({ reload: r });

      //console.log("autorizacion", onAuthStateChange );
      /*client.onmessage = (message) => {
        //console.log("mensaje autorizacion", message.data);
        let authUser = message.data === "" ? null : JSON.parse(message.data);
        var path = self.props.history.location.pathname;
        if (!condition(authUser) && path !== ROUTES.ACCOUNT_NEW) {
          self.props.history.push(ROUTES.SIGN_IN);
        }
      };*/

      /*console.log(JSON.parse(message.data));
        let user = JSON.parse(message.data);
        var path = self.props.history.location.pathname;
        if (!condition(user) && path !== ROUTES.ACCOUNT_NEW) {
          self.props.history.push(ROUTES.SIGN_IN);
        }*/
    }
    componentWillUnmount() {
      clearInterval(this.state.reaload);
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? (
              <>
                <Component {...this.props} authUser={authUser} />
              </>
            ) : (
              <>
                <LoadingPage />
              </>
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
