import { Container, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import PageHeader from "../components/PageHeader";

const ReleasesNotes = () => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} lg={9}>
          <PageHeader />
        </Grid>
      </Grid>
      <Grid item container style={{ marginTop: 24 }}>
        <Grid item xs={12}>
          <Typography variant="h5">Historial de cambios</Typography>
        </Grid>
        <Grid item xs={12} lg={8} container style={{ marginTop: 28 }}>
          {[0, 1, 2, 3, 4, 5].map((x, i) => (
            <Grid item xs={12} container style={{ minHeight: 48 }}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container style={{ padding: "0px 16px" }}>
                <Grid item xs={8} container direction="row">
                  {i == 0 && (
                    <Typography color="primary" style={{ marginRight: 6, fontWeight: 600 }}>
                      Nuevo
                    </Typography>
                  )}
                  <Typography style={{ fontWeight: 500 }} color="textPrimary">
                    Versión de software Reanibex 100 · 209
                  </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <Typography color="textSecondary">9 de Enero 2024</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReleasesNotes;
