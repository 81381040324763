import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../ButtonLoader";
import { green, red } from "@material-ui/core/colors";
import { requestNewAccountToken } from "../DataNet/DataNet";
import ErrorDialog from "../ErrorDialog";

const ExpiredTokenModal = ({ close, open, setValidToken, token, request_uuid }) => {
  const { t } = useTranslation();

  const [isRequestingNewToken, setIsRequestingNewToken] = useState(false);
  const [requestNewTokenStatus, setRequestNewTokenStatus] = useState(0);
  const [attemps, setAttemps] = useState(0);

  const requestNewToken = () => {
    setIsRequestingNewToken(true);
    requestNewAccountToken(token, request_uuid).then((res) => {
      manageResponse(res);
    });
  };

  const manageResponse = (res) => {
    setIsRequestingNewToken(false);
    setAttemps(attemps + 1);
    //console.log("estado de la solicitud del nuevo token", res);
    if (res.status < 0) {
    //  console.log("error de conexión estado -2");
      setRequestNewTokenStatus(-2);
    } else if (res.status === 0 || res.status === 2 || res.status === 4 || res.status === 6) {
  //    console.log("error interno");
      setRequestNewTokenStatus(-1);
    } else if (res.status === 3) {
   //   console.log("token no válido");
    } else if (res.status === 5) {
   //   console.log("el token sigue siendo válido");
      setRequestNewTokenStatus(5);
    } else {
    //  console.log("petición correcta");
      setRequestNewTokenStatus(1);
    }
  };

  if (requestNewTokenStatus === 0) {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
        <DialogTitle style={{ color: red[700] }}>{t("Tiempo expirado")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t("Para poder crear una cuenta de usuario, es necesario volver a enviar la solicitud. Pulsa en el boton 'Nueva solicitud' y te enviaremos un nuevo enlace a tu cuenta")}{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {t("common.close")}
          </Button>
          <Button variant="outlined" color="primary" onClick={requestNewToken} disabled={isRequestingNewToken}>
            {t("nueva solicitud")}
            {isRequestingNewToken && <ButtonLoader />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (requestNewTokenStatus === -1) {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
        <DialogTitle style={{ color: red[700] }}>{t("Error en la solicitud")}</DialogTitle>
        <DialogContent>
          {attemps >= 3 ? (
            <Typography variant="h6">{t("Se han realizado demasiados intentos, pongase en contacto con el distribuidor")}</Typography>
          ) : (
            <Typography variant="body1">{t("Se ha producido un error, intentelo de nuevo, pulsa en el boton 'Nueva solicitud' y le enviaremos un nuevo enlace a su cuenta")}</Typography>
          )}
          {isRequestingNewToken && (
            <Typography variant="body1" style={{ textAlign: "center", marginTop: 9 }}>
              {t("Solicitando nuevo formulario...")}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {t("common.close")}
          </Button>
          <Button variant="outlined" color="primary" onClick={requestNewToken} disabled={isRequestingNewToken || attemps >= 3}>
            {t("nueva solicitud")}
            {isRequestingNewToken && <ButtonLoader />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (requestNewTokenStatus === -2) {
    return <ErrorDialog errorMsg={t("error.connection_-1")} clearError={() => setRequestNewTokenStatus(0)} />;
  } else if (requestNewTokenStatus === 5) {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
        <DialogTitle>{t("Nuevo enlace no necesario")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ marginTop: 6 }}>
            {t("El formulario aún no ha expirado, intente crear el usuario nuevamente")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              close();
              setValidToken();
            }}
          >
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
        <DialogTitle style={{ color: green[700] }}>{t("Solicitud enviada")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ marginTop: 6 }}>
            {t("Se ha enviado un nuevo enlace a la cuenta, complete el formulario antes de que expire.")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>
            {t("common.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
};

export default ExpiredTokenModal;
