import React, { Component, useState } from "react";
import { withRouter, Link } from "react-router-dom";
//import { compose } from "recompose";

//import { withFirebase } from "../Firebase";
import { doLogIn } from "../DataNet/DataNetAuth";
import * as ROUTES from "../../constants/routes";
import { Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, TextField, Typography, FormHelperText, makeStyles } from "@material-ui/core";
import { AccountCircle, Instagram, LinkedIn, Lock, Twitter, Visibility, VisibilityOff } from "@material-ui/icons";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BexenCardioLogo from "../../assets/images/0_3_BEXEN_Cardio_Horiz.png";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

const SignInForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const [email, setemail] = useState(INITIAL_STATE.email);
  const [emailError, setEmailError] = useState(null);
  const [password, setpassword] = useState(INITIAL_STATE.password);
  const [error, setError] = useState(INITIAL_STATE.error);
  const [isSessionLoading, setIsSessionLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (event) => {
    setIsSessionLoading(true);

    doLogIn(email, password)
      .then((snap) => {})
      .catch((error) => {
        setIsSessionLoading(false);
        switch (error) {
          case 1:
            setError(t("signIn.error.wrongLogin"));
            break;
          case 3:
            setError(t("signIn.error.wrongLogin"));
            break;
          case 2:
            setError(t("signIn.error.userBlock"));
            break;
          case -1:
            setError(t("signIn.error.serverError"));
            break;
        }
      });

    event.preventDefault();
  };

  const checkEmail = () => {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    !new RegExp(emailPattern).test(email) && email !== "" && setEmailError(t("account.error.format_email"));
  };

  const onEmailChange = (event) => {
    setemail(event.target.value.trim());
    setError(null);
    setEmailError(null);
  };

  const onPasswordChange = (event) => {
    setpassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isInvalid = password === "" || email === "" || !!emailError;

  return (
    <form onSubmit={onsubmit}>
      <Grid container justify="center" alignItems="center" spacing={4}>
        <Grid item xs={12} justify="center" className={classes.title}>
          <Typography variant="h4" component="h1" gutterBottom color="secondary" style={{ marginBottom: 0, fontWeight: 500, fontSize: "2.3rem" }}>
            REANIBEX DATA NET
          </Typography>
          <Grid container direction="row" alignContent="flex-end">
            <Grid item>
              <Typography
                variant="h6"
                gutterBottom
                color="secondary"
                style={{
                  fontSize: 18,
                  //  color: "grey",
                  fontWeight: 600,
                  marginTop: 5,
                  marginRight: 4,
                }}
              >
                by
              </Typography>
            </Grid>

            <Grid item>
              <img src={BexenCardioLogo} style={{ width: 148 }} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} md={9} xl={9} spacing={2} justify="center">
          <Grid item xs={12} sm={7} md={12} xl={12}>
            <TextField
              id="email"
              variant="outlined"
              name="email"
              error={!!emailError || error}
              value={email}
              onBlur={checkEmail}
              onChange={onEmailChange}
              type="text"
              label={t("common.email")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <Grid item xs={12} sm={7} md={12} xl={12}>
              <FormHelperText error>{emailError}</FormHelperText>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} md={12} xl={12}>
            <TextField
              id="password"
              variant="outlined"
              name="password"
              value={password}
              error={error}
              onChange={onPasswordChange}
              type={showPassword ? "text" : "password"}
              label={t("common.password")}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="primary" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility color="primary" /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12} sm={5} md={12} xl={12}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12} md={9} spacing={2} justify="center">
          <Grid item xs={12} sm={6} md={12} container justify="center">
            {isSessionLoading ? (
              <CircularProgress style={{ j: "center" }} />
            ) : (
              <Button size="medium" fullWidth variant="contained" disabled={isInvalid} onClick={onSubmit} style={{ textTransform: "none" }} color={"secondary"} type="submit">
                {t("common.dologin")}
              </Button>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={12}>
            <Link to={ROUTES.ACCOUNT_NEW} style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                fullWidth
                style={{ textTransform: "none" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {t("common.createAccount")}
              </Button>
            </Link>
          </Grid>
          {
            <Grid item xs={12} sm={6} md={12}>
              <Button style={{ marginTop: 8, textTransform: "none" }} onClick={() => history.push(ROUTES.REQUEST_NEW_PASSWROD)} fullWidth>
                {t("passwordForgot")}
              </Button>
            </Grid>
          }
        </Grid>

        <Grid item xs={8} style={{ width: "100%" }}>
          <Divider />
        </Grid>
        <Grid container item xs={12} direction="row" justify="center" alignItems="center" style={{ width: "100%" }}>
          <Grid item container xs={3} justify="center" alignItems="center">
            <IconButton color="secondary" onClick={() => window.open("https://twitter.com/BexenCardio", "_blank")}>
              <Twitter fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item container xs={3} justify="center" alignItems="center">
            <IconButton color="secondary" onClick={() => window.open("https://www.instagram.com/bexencardio/", "_blank")}>
              <Instagram fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item container xs={3} justify="center" alignItems="center">
            <IconButton color="secondary" onClick={() => window.open("https://www.linkedin.com/company/bexen-cardio/", "_blank")}>
              <LinkedIn fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SignInForm;

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
}));
