import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";

import { useHistory, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { purple } from "@material-ui/core/colors";

const SelectList = ({ list, category }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [userSelectOptions, setUserSelectOption] = useState([]);
  const [value, setvalue] = useState("-1");

  const onSelectChange = (event) => {
    let path = event.target.value == category ? "" : event.target.value;
    history.push(path + "/" + category);
    setvalue(event.target.value);
  };

  const getLocationKey = () => {
    if (location.pathname.split("/").length == 2) {
      return category;
    } else {
      let l = location.pathname.split("/");
      l.pop();
      return l.join("/");
    }
  };

  useEffect(() => {
    setvalue(getLocationKey);
  }, [category]);

  useEffect(() => {
    let items =
      category === "groups"
        ? [<MenuItem value="groups">{t("breadcrumbs.allGroups")}</MenuItem>]
        : location.pathname.includes("devices")
        ? [<MenuItem value="devices">{t("breadcrumbs.allDevices")}</MenuItem>]
        : [<MenuItem value="users">{t("breadcrumbs.allUsers")}</MenuItem>];
    list.forEach((p) => {
      location.pathname.split("/").length <= 4 /*Impide mostar los programas en paginas donde se está a nivel de grupo */ &&
        items.push(<MenuItem value={`${ROUTES.PROGRAMS}/${p.id}`}>{p.name}</MenuItem>);
      category !== "groups" &&
        p.groups.forEach((g) => {
          items.push(<MenuItem value={`${ROUTES.PROGRAMS}/${p.id}${ROUTES.GROUPS}/${g.id}`}>{`  ${p.name} | ${g.name}`}</MenuItem>);
        });
    });
    setUserSelectOption(items);
    setvalue(getLocationKey);
  }, [category]);

  return (
    <div className={classes.search}>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="header-select" className={classes.formControl}>
          {location.pathname.split("/").length <= 4 ? t("common.program") : t("common.group")}
        </InputLabel>
        <Select
          labelId="header-select"
          label={location.pathname.split("/").length <= 4 ? t("common.program") : t("common.group")}
          value={value}
          defaultValue={""}
          placeholder={t("navigation.groups")}
          onChange={onSelectChange}
          fullWidth
          className={classes.formControl}
        >
          {
            //* Si solo tiene una opcción se elimina la opción todos */
            /*
            userSelectOptions.length > 1 && (
              <MenuItem value={"-1"}>{t("common.all")}</MenuItem>
            )*/
          }

          {userSelectOptions.map((p) => p)}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectList;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    color: theme.palette.primary.main,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 0.2,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    minWidth: 120,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 20,
    //width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      minWidth: 140,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(1),
      maxWidth: 140,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
