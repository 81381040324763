import { Container, Grid, InputAdornment, Paper, Table, TableRow, TableBody, TableCell, TableContainer, TableHead, TextField, Typography, Avatar, LinearProgress, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageHeader from "../components/PageHeader";
import { useTranslation } from "react-i18next";
import { AccountCircle, Search, SupervisedUserCircle, VerifiedUser } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import AddUser from "../components/AddUser";
import { withAuthorization } from "../components/Session";
import * as DATANETAPI from "../components/DataNet/DataNet";
import LoadingPage from "../components/LoadingPage";
import * as ROLES from "../constants/roles";

const UsersPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { programId, groupId } = useParams();

  const [inputText, setinputText] = useState("");
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [areUsersLoaded, setAreUsersLoaded] = useState(false);

  const searchHandler = (event) => {
    setinputText(event.target.value);
    let filteredUsers = [];
    usersBack.forEach((user) => {
      if (
        /**Filtar por, email, nombre, apellido y telefono */
        user.email.toUpperCase().concat(user.name.toUpperCase()).concat(user.last_name.toUpperCase()).concat(user.telephone.toUpperCase()).includes(event.target.value.toUpperCase())
      ) {
        filteredUsers.push(user);
      }
    });
    //event.target.value !== "" && setisFiltered(true);
    setUsers(filteredUsers);
  };

  useEffect(() => {
    setAreUsersLoaded(false);
    DATANETAPI.getUsers(programId, groupId).then((snap) => {
      //console.log("USERSSSSS!!!!!", snap.data);
      usersBack = snap.data;
      if (snap.data !== undefined) {
        usersBack = snap.data;
        setUsers(usersBack);
        setAreUsersLoaded(true);
      } else {
        setUsers([]);
        setAreUsersLoaded(true);
        // console.log("Not users available");
      }
    });
  }, [programId, groupId]);

  return (
    <Container>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <PageHeader title={t("users.title")} />
        </Grid>

        <Grid item>
          <Paper style={{ padding: 12 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item container alignItems="center" xs={6} justify="flex-start">
                <Grid item>
                  <TextField
                    label={t("common.search")}
                    value={inputText}
                    onChange={searchHandler}
                    margin="dense"
                    placeholder={t("common.search")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Grid item>
                  {/* <Tooltip
                    placement="top"
                    enterDelay={750}
                    title={
                      <Typography variant="body1" style={{ fontSize: 12 }}>
                        {t("users.toolbar.tooltip.add")}
                      </Typography>
                    }
                  >
                    <span>
                      <IconButton
                        disabled={!!!groupId}
                        onClick={() => {
                          setAddModalOpen(true);
                        }}
                      >
                        <PersonAdd />
                      </IconButton>
                    </span>
                      </Tooltip>*/}
                  <AddUser open={addModalOpen} handleClose={setAddModalOpen} />
                </Grid>
                {/*
                <Grid item>
                  <Tooltip
                    placement="top"
                    enterDelay={750}
                    title={
                      <Typography variant="body1" style={{ fontSize: 12 }}>
                        {t("users.toolbar.tooltip.shop")}
                      </Typography>
                    }
                  >
                    <span>
                      <IconButton
                        onClick={() => {
                          window.open(
                            "https://bexencardio-desfibrilador.com/",
                            "_blank"
                          );
                        }}
                      >
                        <ShoppingBasketOutlined color="primary" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>*/}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <TableContainer component={Paper}>
            {!areUsersLoaded && <LinearProgress />}

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width={64}>{t("users.table.email")}</TableCell>

                  <TableCell align="center">{t("users.table.name")}</TableCell>
                  <Hidden smDown>
                    <TableCell align="center">{t("users.table.last_name")}</TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="center">{t("users.table.telephone")}</TableCell>
                  </Hidden>
                  <Hidden smDown>
                    <TableCell align="right">{t("users.table.groups")}</TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    onClick={() => {
                      history.push(ROUTES.USER + "/" + row.id);
                      window.scrollTo(0, 0);
                    }}
                    //style={{ background: row.status.main == 2 && red[100] }} //para los expirados
                  >
                    {/*<TableCell component="th" scope="row">
                      <Avatar>
                        {row.roles.some((x) => x.name_id === ROLES.PROGRAMS_SELECT) && <SupervisedUserCircle />}
                        {row.roles.some((x) => x.name_id === "PROGRAM ADMINISTRATOR" || x.name_id === "MASTER ADMINISTRATOR") && <AccountCircle />}
                        {row.roles[0].name_id == "GROUP COORDINATOR" && <AccountCircle />}
                        {row.roles[0].name_id == "TRAINED RESCUERS" && <VerifiedUser />}
                      </Avatar>
                </TableCell>*/}
                    <TableCell component="th" scope="row" align="left">
                      <Hidden smDown>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                          {row.email}
                        </Typography>
                      </Hidden>
                      <Hidden mdUp>
                        <Typography variant="body1">{`${row.email}`}</Typography>
                      </Hidden>
                    </TableCell>

                    <TableCell align="center">{row.name}</TableCell>

                    <Hidden smDown>
                      <TableCell align="center">{row.last_name}</TableCell>{" "}
                    </Hidden>
                    <Hidden smDown>
                      <TableCell align="center">{row.telephone}</TableCell>
                    </Hidden>
                    <Hidden smDown>
                      <TableCell align="right">{row.groups.map((g, i) => (i !== row.groups.length - 1 ? g.name + " · " : g.name))}</TableCell>
                    </Hidden>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {users.length === 0 && areUsersLoaded && (
              <Grid container justify="center" alignContent="center" style={{ height: 120 }}>
                <Typography variant="h4" color="textSecondary">
                  {t("users.notFound")}
                </Typography>
              </Grid>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(UsersPage);

var usersBack = [
  {
    id: 10,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["c"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 11,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 12,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["a"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 13,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 14,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 15,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },

  {
    id: 16,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 17,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 18,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 19,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 20,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["c"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
  {
    id: 21,
    email: "argiosa@bexencardio.com",
    name: "Argi",
    last_name: "Osa",
    roles: ["u"],
    telephone: "+34 698542154",
    groups: ["Ermua Centro"],
  },
];
