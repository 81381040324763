import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Divider, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { green, orange, red, lightGreen, lime, yellow, amber, deepOrange, blueGrey } from "@material-ui/core/colors";
import { Error, Check, Warning, BatteryFull, BatteryAlert, FlashOn, CropPortrait } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Pads_Icon from "../../assets/images/Parches_2.png";

const DeviceStatusOthers = ({ device }) => {
  const getExpirationState = (expiration_date) => {
    var epiration_timestamp = Date.parse(expiration_date);
    var current_timestamp = new Date().getTime();

    if (epiration_timestamp <= current_timestamp) {
      return 2; // ERROR
    } else if (epiration_timestamp <= current_timestamp + 7776000000) {
      return 1; // WARNING
    }
    return 0; // SUCCESS
  };

  const mainState = () => {
    var bat_state = getExpirationState(device.expirationDateBatt);
    var pads_state = getExpirationState(device.expirationDatePads);

    if (bat_state === 2 || pads_state === 2) return 2;
    if (bat_state === 1 || pads_state === 1) return 1;
    return 0;
  };

  return (
    <Grid container>
      <Grid item xs={12} container>
        <DeviceStatusItem device_status={mainState()} />
        <Grid item xs={12} container spacing={2}>
          <BatteryDate expiration_state={getExpirationState(device.expirationDateBatt)} expiration_date={moment(device.expirationDateBatt).format("YYYY-MM")} />
          <PadsDate expiration_state={getExpirationState(device.expirationDatePads)} expiration_date={moment(device.expirationDatePads).format("YYYY-MM")} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const BatteryDate = ({ expiration_state, expiration_date }) => {
  const { t } = useTranslation();
  //console.log(expiration_state);
  return (
    <Grid item xs={12} md={6}>
      <Paper style={{ padding: 16, backgroundColor: blueGrey[50], borderRadius: 16 }} elevation={0}>
        <Grid item container xs={12}>
          <Grid item xs={12} container direction="row">
            <Typography color="secondary" style={{ fontWeight: 500 }}>
              {t("common.battery")}
            </Typography>
            <Typography style={{ fontWeight: 500, color: red[500] }}> &nbsp; {expiration_state === 2 && `· ${t("error_eREG_30")}`}</Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 9 }}>
            <Grid item xs={6} md={8}>
              <Typography variant="h6" style={{ fontWeight: 700, marginBottom: -6 }}>
                {expiration_date}
              </Typography>
              <Typography variant="body2">{t("device.details.accesories.expirationDate")}</Typography>
            </Grid>
            <Grid item xs={6} md={4} container justifyContent="center">
              {expiration_state === 2 && <BatteryAlert style={{ fontSize: 48, marginRight: 16, color: red["500"] }} fontSize="medium" />}
              {expiration_state === 1 && <BatteryAlert style={{ fontSize: 48, marginRight: 16, color: orange["500"] }} fontSize="medium" />}
              {expiration_state === 0 && <BatteryFull style={{ fontSize: 48, marginRight: 16, color: green[500] }} fontSize="medium" />}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const PadsDate = ({ expiration_state, expiration_date }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={6}>
      <Paper style={{ padding: 16, backgroundColor: blueGrey[50], borderRadius: 16 }} elevation={0}>
        <Grid item container xs={12}>
          <Grid item xs={12} container direction="row">
            <Typography color="secondary" style={{ fontWeight: 500 }}>
              {t("common.pads")}
            </Typography>
            <Typography style={{ fontWeight: 500, color: red[500] }}> &nbsp; {expiration_state === 2 && `· ${t("error_eREG_20")}`}</Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 9 }}>
            <Grid item xs={6} md={8}>
              <Typography variant="h6" style={{ fontWeight: 700, marginBottom: -6 }}>
                {expiration_date}
              </Typography>
              <Typography variant="body2">{t("device.details.accesories.expirationDate")}</Typography>
            </Grid>
            <Grid item xs={6} md={4} container justifyContent="center">
              <img style={{ height: 48 }} src={Pads_Icon} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const DeviceStatusItem = ({ device_status }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} container alignItems="center" style={{ marginBottom: 9 }}>
      {device_status === 0 && <Check style={{ marginRight: 6, color: green[500] }} fontSize="medium" />}
      {device_status === 1 && <Warning style={{ marginRight: 6, color: orange[500] }} fontSize="medium" />}
      {device_status === 2 && <Error style={{ marginRight: 6, color: red[500] }} fontSize="medium" />}

      <Typography variant="h6" style={{ color: device_status === 1 ? orange[500] : device_status == 2 && red[500] }}>
        {t("status.simple.state_" + device_status)}
      </Typography>
    </Grid>
  );
};

export default DeviceStatusOthers;
