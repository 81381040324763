import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    //fontFamily: '"Google Sans"',
    fontFamily: "Manrope",
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#8f53a1",
    },
    secondary: {
      main: "#023f88",
    },
  },
});

export default theme;
