import React from "react";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../constants/routes";
import { Link, useParams } from "react-router-dom";
import ProgramLink from "./ProgramLink";

const ProgramGroups = ({ programsList }) => {
  const { t } = useTranslation();
  const { programId } = useParams();
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <ProgramLink />
        <Typography color="textPrimary">{programsList[programId]}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" color="secondary" style={{ marginTop: 18 }}>
        {t("navigation.groups")}
      </Typography>
    </>
  );
};

export default ProgramGroups;
