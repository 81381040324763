import { makeAndManageRequest } from "./DataNet";

/*import app from "firebase/app";
import "firebase/auth";
import "firebase/database";*/
var W3CWebSocket = require("websocket").w3cwebsocket;

//export const client = new W3CWebSocket("ws://localhost:8000");

const config = {
  urlBase: window.location.origin + "/app", // "http://192.168.1.145:5000/app",
};

export var loggedUser = null;
/* test API */

export const testConexion = () =>
  new Promise((resolve, reject) => {
    fetch(`${config.urlBase}/groups`)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((data) => reject(data));
  });

export const currentUser = () => {
  return new Promise((resolve, reject) => {
    fetch(`${config.urlBase}/session/user`)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
        loggedUser = data;
      })
      .catch((err) => {
        loggedUser = null;
        resolve(null);
        //reject(err);
      });
  });
};

export const doLogIn = (user, password) => {
  return new Promise((resolve, reject) => {
    fetch(`${config.urlBase}/session/login`, {
      method: "POST",
      body: JSON.stringify({ email: user, password: password }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("respuesta login", data);
        data.status.error !== 0 && reject(data.status.error);
        loggedUser = data.status.error === 0 ? data : null;
        data.status.error === 0 && resolve(data);
      })
      .catch((err) => {
        //  console.log("error", err);
        reject(-1);
      });
  });
};

export const singOut = () => {
  fetch(`${config.urlBase}/session/logout`).then(() => {});
};

export const sendPasswordResetEmail = (email, lang) => {
  return new Promise((resolve, reject) => {
    if (email != undefined) {
      var path = `${config.urlBase}/account/password/recovery`;
      var post_body = { email: email, lang: lang };
      fetch(path, {
        method: "POST",
        body: JSON.stringify(post_body),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return { data: { status: 5 } };
          }
        })
        .then((data) => resolve(data))
        .catch((data) => reject(data));
    }
    /*setTimeout(() => {
      Math.random() < 0.5 ? resolve() : reject({ code: "algomal" });
    }, 5000);*/
  });
};

export const confirmPasswordReset = (email, token, password_1, password_2) => {
  return new Promise((resolve, reject) => {
    if (email != undefined) {
      var path = `${config.urlBase}/account/password/reset`;
      var post_body = {
        email: email,
        token: token,
        password_1: password_1,
        password_2: password_2,
      };
      fetch(path, {
        method: "POST",
        body: JSON.stringify(post_body),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return { data: { status: 5 } };
          }
        })
        .then((data) => resolve(data))
        .catch((data) => reject(data));
    }
  });
  /*return new Promise((resolve, reject) => {
    setTimeout(() => {
      Math.random() < 0.5 ? resolve() : reject({ code: "algomal" });
    }, 5000);
  });*/
};
