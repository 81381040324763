import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import * as DATANET_AUTH from "../components/DataNet/DataNetAuth";
import cardioLogo from "../constants/images/logo_color_Cardio_Protecccion.png";
import { Link } from "react-router-dom";
import { passwordPattern } from "../constants/validations";

const ResetPassword = ({ firebase }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState(null);
  const [viewPassword, setviewPassword] = useState(false);
  const [psw1, setPsw1] = useState("");
  const [psw2, setPsw2] = useState("");
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snakError, setrSnakError] = useState(null);

  const onchange = (e) => {
    if (e.target.name == "psw1") {
      setPsw1(e.target.value);
    } else {
      setPsw2(e.target.value);
    }
  };

  const changePassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (psw1 !== "" && psw1 == psw2) {
      if (!psw1.match(passwordPattern)) {
        setError("account.error.password");
        setIsLoading(false);
      } else {
        setError(null);
        DATANET_AUTH.confirmPasswordReset("email", params.Token, psw1, psw2)
          .then((snap) => {
            //history.push(ROUTES.SIGN_IN);
            setError(errorCodes[snap.status]);
            // console.log(snap.status);
            setOpenSnackBar(true);
            setIsLoading(false);
            setPsw1("");
            setPsw2("");
          })
          .catch((error) => {
            setError("auth." + error.code);
            setIsLoading(false);
          });
      }
    } else {
      setError("passwordNotSame");
      setIsLoading(false);
    }
  };

  const isValid = psw1 !== "" && psw1 == psw2;

  return (
    <form onSubmit={changePassword}>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          minHeight: "100vh",
          paddingRight: 12,
          paddingLeft: 12,
          marginTop: -62,
        }}
      >
        <Grid container item xs={12} md={4} xl={3}>
          <Grid item xs={12}>
            <Paper variant="outlined" style={{ minHeight: "80vh", padding: 32, position: "relative" }}>
              <Grid container justify="center" spacing={4}>
                <Grid style={{ marginTop: 32 }}>
                  <Grid item xs={12} container justify="center">
                    <img src={cardioLogo} style={{ height: 80 }} />
                  </Grid>
                  <Grid item xs={12} container justify="center">
                    <Typography
                      variant="h6"
                      style={{
                        paddingTop: 16,
                        fontSize: 24,
                        textAlign: "center",
                        fontWeight: 400,
                        //lineHeight: 1.3333,
                        marginBottom: 0,
                      }}
                    >
                      {t("resetPasswordTitleEmail")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 16,
                      marginBottom: 18,
                      color: "#202124",
                      fontSize: 16,
                      textAlign: "center",
                      fontWeight: 400,
                      letterSpacing: 0.1,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("resetPasswordTextPassword")}
                  </Typography>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">{t("password")}</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={viewPassword ? "text" : "password"}
                        name="psw1"
                        value={psw1}
                        error={!!error}
                        onChange={onchange}
                        labelWidth={(t("password") + " ").length * 7.75}
                        onKeyDown={() => {
                          setError(null);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setviewPassword(!viewPassword);
                              }}
                              onMouseDown={() => {
                                setviewPassword(!viewPassword);
                              }}
                              edge="end"
                            >
                              {viewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password2">{t("repeatPassword")}</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password2"
                        type={viewPassword ? "text" : "password"}
                        name="psw2"
                        value={psw2}
                        error={!!error}
                        onChange={onchange}
                        labelWidth={t("repeatPassword").length * 7.5}
                        onKeyDown={() => {
                          setError(null);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setviewPassword(!viewPassword);
                              }}
                              onMouseDown={() => {
                                setviewPassword(!viewPassword);
                              }}
                              edge="end"
                            >
                              {viewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {!!error && <FormHelperText error>{t(error)}</FormHelperText>}
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 18, marginBottom: 18 }}>
                  <Grid item xs={12} container justify="flex-end">
                    {isLoading ? (
                      <CircularProgress style={{ marginRight: 60 }} />
                    ) : (
                      <Button disabled={isCodeExpired || psw1 != psw2 || psw2 == ""} variant="outlined" color="primary" type="submit" onClick={changePassword}>
                        {t("resetPasswrod")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="center"
                style={{
                  position: "absolute",
                  width: "100%",
                  bottom: 8,
                  left: 0,
                }}
              >
                <Link to={ROUTES.HOME} style={{ textDecoration: "none" }}>
                  <Typography color="textSecondary">{t("home")}</Typography>
                </Link>
                <Typography color="textSecondary" style={{ marginRight: 4, marginLeft: 4 }}>
                  ·
                </Typography>
                <a href={"mailto:info@bexencardio.com"} style={{ textDecoration: "none" }}>
                  <Typography color="textSecondary">{t("help")}</Typography>
                </a>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={() => {
            setOpenSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              setOpenSnackBar(false);
            }}
            severity={error === "passwordErrorCode_1" ? "success" : "error"}
          >
            {t(error)}
          </Alert>
        </Snackbar>
      </Grid>
    </form>
  );
};

export default ResetPassword;

const errorCodes = {
  0: "passwordErrorCode_0",
  1: "passwordErrorCode_1",
  2: "passwordErrorCode_2",
  3: "passwordErrorCode_2",
  4: "passwordErrorCode_2",
  5: "passwordErrorCode_2",
  6: "passwordErrorCode_2",
};
