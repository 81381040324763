import { Grid, Hidden, Paper } from "@material-ui/core";
import React from "react";
import CreateAccount from "./CreateAccountForm";
import { red, grey, blue } from "@material-ui/core/colors";

const CreateAccountForm = () => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      //style={{ minHeight: "100vh" }}
    >
      <Hidden xsDown>
        <Grid container item xs={12} md={7} xl={5}>
          <Paper
            variant="outlined"
            style={{
              minHeight: "80vh",
              padding: 32,
              position: "relative",
              border: "1px solid #dadce0",
              borderRadius: 8,
            }}
          >
            <Grid container justify="center">
              <Grid
                style={{ marginTop: 16 }}
                item
                container
                xs={12}
                spacing={4}
                justify="center"
              >
                <CreateAccount />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid
          container
          spacing={4}
          style={{ minHeight: "80vh", position: "relative", padding: 8 }}
        >
          <CreateAccount />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default CreateAccountForm;
