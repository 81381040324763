import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Button, Typography, CardActions, makeStyles, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { ExpandMore, Room, Person, Edit } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";

const GroupSkeletonCard = ({ i }) => {
  const classes = useStyles();

  return (
    <Grid item lg={4} md={4} xl={3} xs={12} sm={6}>
      <Card className={classes.card} id={`grCard${i}`} variant="outlined">
        <CardContent>
          <Grid container alignItems="center">
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h4" component="h2" style={{ marginBottom: 0 }}>
                  <Skeleton variant="text" width={180} />
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  <Skeleton variant="text" width={240} />
                </Typography>
              </Grid>
              <Grid item>
                <Skeleton variant="circle" width={40} height={40} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ marginTop: 16, alignContent: "center", display: "flex", fontWeight: 600 }}>
                <Skeleton variant="text" width={260} />
                <Skeleton variant="circle" width={18} height={18} style={{ marginLeft: 6, marginTop: 3 }} />
              </Typography>
              <Grid style={{ display: "flex" }}>
                {[0, 1].map((v, i) => (
                  <Skeleton variant="text" width={68} height={36} style={{ borderRadius: 9, marginTop: 6, marginRight: 6 }} />
                ))}
              </Grid>
            </Grid>
            <Grid>
              <List
                dense
                subheader={
                  <Typography variant="subtitle2">
                    <Skeleton variant="text" width={140} />
                  </Typography>
                }
                style={{ marginTop: 16 }}
              >
                {[0].map((coordinator) => (
                  <ListItem style={{ padding: 0 }}>
                    <ListItemText primary={<Skeleton variant="text" width={160} />} secondary={<Skeleton variant="text" width={190} />} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>

        <CardActions style={{ justifyContent: "flex-end" }}>
          <Grid container justifyContent="flex-end">
            <Skeleton width={86} height={32} variant="rect" style={{ borderRadius: 20, marginLeft: 6 }} />
          </Grid>
        </CardActions>

        {/*} <MapDialog isOpen={isMapOpen} setisMapOpen={setisMapOpen} location={data.location} />*/}
      </Card>
    </Grid>
  );
};

export default GroupSkeletonCard;

const useStyles = makeStyles((theme) => ({
  card: {
    //padding: 6,
    borderRadius: 12,
    background: "#f7f8fa",
  },
  media: {
    height: 140,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
