import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
  createTheme,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AuthUserContext } from "../components/Session";
import { useTranslation } from "react-i18next";
import { Email, EmailOutlined, LockOutlined, Person, PersonOutline, Phone, PhoneOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import LangSelect from "../components/Navigation/LangSelect";
import theme from "../constants/theme";
import ButtonLoader from "../components/ButtonLoader";
import { createAccountFromToken } from "../components/DataNet/DataNet";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ErrorDialog from "../components/ErrorDialog";
import ExpiredTokenModal from "../components/ProgramForm/ExpiredTokenModal";
import { grey, red } from "@material-ui/core/colors";
import * as ROUTES from "../constants/routes";
import { passwordPattern } from "../constants/validations";

const RegisterUserFrom = () => {
  return (
    <Grid container item xs={12} style={{ minHeight: "84vh" }} justifyContent="center" alignItems="center">
      <AuthUserContext.Consumer>{(authUser) => (!!!authUser ? <RegisterUserForm /> : <LoggedUser />)}</AuthUserContext.Consumer>
    </Grid>
  );
};

export default RegisterUserFrom;

const LoggedUser = () => (
  <Grid container style={{ height: "85vh" }} alignContent="center" justifyContent="center">
    <Grid item xs={12} md={7} lg={5} container>
      <Paper style={{ width: "100%", height: "100%", padding: 16, borderRadius: 9, borderColor: red[700], backgroundColor: red[50] }} variant="outlined">
        <Typography variant="h5" style={{ marginBottom: 9 }}>
          Se requeire cerrar sesión
        </Typography>
        <Typography variant="body1">Para poder crear una cuenta se tiene que cerrar la actual sesión. Una vez se cierre, vuelva a acceder mediante el enlace.</Typography>
      </Paper>
    </Grid>
  </Grid>
);

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-=[\]{};':"\\|,.<>/?])[a-zA-Z\d!@#$%^&*()_\-=[\]{};':"\\|,.<>/?]{8,}$/;
const rege1 = new RegExp("/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im");
const rege2 = new RegExp("^([+]*[(][0-9]{1,3}[)])?[\\s]?([0-9]{2,4}[\\s.-]?){2,4}$");

const RegisterUserForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { token_hash, request_uuid } = useParams();

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [repeatEmail, setRepeatEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [policy, setpolicy] = useState(false);
  const [passwordvisible, setPasswordvisible] = useState(false);
  const [aceptManagement, setAceptManagement] = useState(true);
  const [isCreattingAccount, setIsCreattingAccount] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [expiredToken, setExpiredToken] = useState(false);
  const [openTokenExpiredModal, setOpenTokenExpiredModal] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [generalErrorMsg, setGeneralErrorMsg] = useState("");
  const [policyModalOpen, setPolicyModalOpen] = useState(false);
  const [userCreatedModalOpen, setUserCreatedModalOpen] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [worngTokenModalError, setWorngTokenModalError] = useState(false);

  const [nameError, setNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [repeatEmailError, setRepeatEmailError] = useState("");
  const [userPhoneError, setUserPhoneError] = useState("");

  const checkName = () => (!(name.length > 0 && name.length < 100) ? setNameError("nombre incorrecto") : setNameError(""));
  const checkFirstName = () => (!(firstName.length > 0 && firstName.length < 100) ? setFirstNameError("nombre incorrecto") : setFirstNameError(""));
  const checkPhone = () => setUserPhoneError(rege1.test(userPhone) || rege2.test(userPhone) ? "" : "Introduce un formato válido");
  const checkEmail = () => {
    if (email == "") {
      setEmailError(t("El campo no puede estar vacío"));
    } else if (!email.match(emailPattern)) {
      setEmailError(t("Introduce un email válido"));
    } else if (email !== repeatEmail && repeatEmail != "") {
      setEmailError(t("Los correos son diferentes"));
      setRepeatEmailError(t("Los correos son diferentes"));
    } else {
      setEmailError("");
      setRepeatEmailError("");
    }
  };
  const checkRepeatemail = () => {
    if (email == "") {
      if (repeatEmail === "") {
        setRepeatEmailError(t("El campo no puede estar vacío"));
      } else {
        if (!repeatEmail.match(emailPattern)) {
          setRepeatEmailError(t("Introduce un email válido"));
        } else {
          setEmailError(t("El campo no puede estar vacío"));
          setRepeatEmailError(t("Los correos son diferentes"));
        }
      }
    } else {
      if (email !== repeatEmail) {
        setEmailError(t("Los correos son diferentes"));
        setRepeatEmailError(t("Los correos son diferentes"));
      } else {
        if (repeatEmail.match(emailPattern)) {
          setEmailError("");
        } else {
          setRepeatEmailError(t("Introduce un email válido"));
        }
      }
    }
  };

  const checkPassword = () => {
    if (password.length < 8) {
      setPasswordError(t("la contraseña es demasiado corta"));
    } else if (password.length >= 72) {
      setPasswordError(t("la contraseña es demasiado larga"));
    } else if (!password.match(passwordPattern)) {
      setPasswordError(t("account.error.password"));
    } else {
      if (passwordRepeat !== "") {
        if (password !== passwordRepeat) {
          setPasswordError(t("account.error.repeat_password"));
          setRepeatPasswordError(t("account.error.repeat_password"));
        } else {
          setPasswordError("");
          setRepeatPasswordError("");
        }
      } else {
        setPasswordError("");
      }
    }
  };

  const checkPasswordRepeat = () => {
    if (password === "") {
      if (passwordRepeat.length < 8) {
        setRepeatPasswordError(t("la contraseña es demasiado corta"));
      } else if (passwordRepeat.length >= 72) {
        setRepeatPasswordError(t("la contraseña es demasiado larga"));
      } else if (!passwordRepeat.match(passwordPattern)) {
        setRepeatPasswordError(t("account.error.password"));
      } else {
        setRepeatPasswordError("");
      }
    } else {
      if (password != passwordRepeat) {
        setPasswordError(t("account.error.repeat_password"));
        setRepeatPasswordError(t("account.error.repeat_password"));
      } else {
        if (!passwordRepeat.match(passwordPattern)) {
          setRepeatPasswordError(t("account.error.password"));
        } else {
          setPasswordError("");
          setRepeatPasswordError("");
        }
      }
    }
  };

  const handleSubmint = () => {
    setIsCreattingAccount(true);
    const ERROR_CODES = {
      0: () => setRequestError(true),
      2: () => setWorngTokenModalError(true),
      3: () => setRequestError(true),
      4: () => setGeneralErrorMsg(t("Revise los campos y vuelva a enviar el formulario")),
      5: () => {
        setEmailError(t("El email ya está en uso"));
        setRepeatEmailError(t("El email ya está en uso"));
      },
      6: () => {
        setExpiredToken(true);
        setOpenTokenExpiredModal(true);
      },
    };
    const userData = {
      name: name,
      surnames: firstName,
      email: email,
      password: password,
      accept_management: policy,
      token: token_hash,
      request_uuid: request_uuid,
      phone: userPhone,
    };

    /*  setTimeout(() => {
      ERROR_CODES[6]();
      setIsCreattingAccount(false);
      setUserCreatedModalOpen(true);
    }, 2500);*/

    createAccountFromToken(userData).then((response) => {
      setIsCreattingAccount(false);
      if (response.status < 0) {
        //  setErrorMsg(t(`error.connection_${response.status}`));
      } else {
        if (response.status == 1) {
          //console.log("respuesta correcta");
          setUserCreatedModalOpen(true);
        } else {
          //console.log(response.status);
          ERROR_CODES[response.status]();
        }
      }
    });
  };

  useEffect(() => {
    setGeneralErrorMsg("");
  }, [name, firstName, userPhone, email, repeatEmail, password, passwordRepeat]);

  const isValid =
    name.length > 0 &&
    name.length < 100 &&
    firstName.length > 0 &&
    firstName.length < 100 &&
    email.match(emailPattern) &&
    email === repeatEmail &&
    password.match(passwordPattern) &&
    password === passwordRepeat &&
    (rege1.test(userPhone) || rege2.test(userPhone)) &&
    policy &&
    emailError == "";

  return (
    <Grid item xs={12} md={8} lg={6} xl={5} container>
      <Paper elevation={0} variant="outlined" className={classes.mainPaper}>
        <Grid container spacing={3}>
          <Grid item xs={12} container alignItems="center" direction="column" style={{ padding: 16, marginTop: 46, marginBottom: 32 }}>
            <Typography variant="h6" style={{ fontWeight: 400, fontSize: 24, marginBottom: 0 }}>
              {t("account.wellcome")}
            </Typography>
            <Typography variant="h4" color="primary" style={{ fontWeight: 500 }}>
              {t("account.title")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              disabled={expiredToken}
              fullWidth
              type="text"
              required
              id="username"
              label={t("common.name")}
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              onBlur={checkName}
              variant="outlined"
              autoComplete="username"
              error={nameError != "" || generalErrorMsg}
              helperText={nameError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutline color={nameError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              disabled={expiredToken}
              fullWidth
              id="outlined-basic"
              value={firstName}
              required
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError("");
              }}
              onBlur={checkFirstName}
              label={t("common.surnames")}
              error={firstNameError != "" || generalErrorMsg}
              helperText={firstNameError}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutline color={firstNameError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              disabled={expiredToken}
              fullWidth
              id="outlined-basic"
              required
              value={userPhone}
              onChange={(e) => {
                setUserPhone(e.target.value);
                setUserPhoneError("");
              }}
              onBlur={checkPhone}
              label={t("common.phone")}
              error={userPhoneError != "" || generalErrorMsg}
              helperText={userPhoneError}
              variant="outlined"
              type="tel"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneOutlined color={userPhoneError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={expiredToken}
              fullWidth
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              onBlur={checkEmail}
              error={emailError !== "" || generalErrorMsg}
              helperText={emailError}
              required
              label={t("common.email")}
              variant="outlined"
              autoComplete="email"
              type="email"
              inputMode="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined color={emailError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={expiredToken}
              fullWidth
              id="outlined-basic"
              value={repeatEmail}
              onBlur={checkRepeatemail}
              required
              onChange={(e) => {
                setRepeatEmail(e.target.value);
                setRepeatEmailError("");
              }}
              error={repeatEmailError !== "" || generalErrorMsg}
              helperText={repeatEmailError}
              label="Confirm email address"
              autoComplete="email"
              type="email"
              variant="outlined"
              inputMode="email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined color={repeatEmailError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={expiredToken}
              fullWidth
              type={passwordvisible ? "text" : "password"}
              name="password"
              autoComplete="new-password"
              label={t("common.password")}
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
              }}
              error={passwordError !== "" || generalErrorMsg}
              helperText={passwordError}
              onBlur={checkPassword}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined color={passwordError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color={passwordError != "" ? "error" : expiredToken ? "disabled" : "primary"}
                      onClick={() => setPasswordvisible(!passwordvisible)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {passwordvisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={expiredToken}
              fullWidth
              type={passwordvisible ? "text" : "password"}
              name="password"
              autoComplete="new-password"
              label={t("user.info.repeatPassword")}
              value={passwordRepeat}
              required
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
                setRepeatPasswordError("");
              }}
              onBlur={checkPasswordRepeat}
              error={repeatPasswordError !== "" || generalErrorMsg}
              helperText={repeatPasswordError}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined color={repeatPasswordError != "" ? "error" : expiredToken ? "disabled" : "primary"} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      color={repeatPasswordError != "" ? "error" : expiredToken ? "disabled" : "primary"}
                      onClick={() => setPasswordvisible(!passwordvisible)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {passwordvisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {generalErrorMsg && (
            <FormHelperText style={{ marginLeft: theme.spacing(2) }} error>
              {generalErrorMsg}
            </FormHelperText>
          )}

          <Grid item xs={12} container alignContent="center" direction="column" justifyContent="center">
            <FormControlLabel
              style={{ alignSelf: "center" }}
              control={<Checkbox checked={policy} required onChange={() => setpolicy(!policy)} name="policity" color="primary" />}
              label={
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setPolicyModalOpen(true);
                  }}
                  style={{ fontSize: 16, color: grey[600], marginBottom: 0 }}
                >
                  {t("rescuers.register.aceptConditions")}
                </Link>
              }
            />
            <FormControlLabel
              style={{ alignSelf: "center", marginTop: 6 }}
              control={<Checkbox checked={aceptManagement} onChange={() => setAceptManagement(!aceptManagement)} name="policity" color="primary" />}
              label={t("Acepto que distribuidor pueda ver el contenido del programa")}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6} lg={3} style={{ margin: 16 }}>
              <Button fullWidth variant="outlined" color="primary" onClick={handleSubmint} disabled={!isValid || isCreattingAccount || expiredToken || generalErrorMsg}>
                {t("common.createAccount")}
                {isCreattingAccount && <ButtonLoader />}
              </Button>
            </Grid>

            <Grid item xs={12} container justifyContent="center" alignItems="center" style={{ marginTop: 32, width: "100%", bottom: 0, left: 0, color: "rgba(0, 0, 0, 0.54)" }}>
              <Link style={{ textDecoration: "none" }} onClick={() => window.scrollTo(0, 0)}>
                <Typography color="textSecondary">{t("common.home")}</Typography>
              </Link>
              <Typography color="textSecondary" style={{ marginRight: 4, marginLeft: 4 }}>
                ·
              </Typography>
              <a href={"mailto:info@bexencardio.com?Subject=Error%20en%20crear%20usuario%20de%20un%20programa"} style={{ textDecoration: "none" }}>
                <Typography color="textSecondary">{t("common.reportCreteAccountError")}</Typography>
              </a>
              <Typography color="textSecondary" style={{ marginRight: 4, marginLeft: 4 }}>
                ·
              </Typography>
              <a href={t("common.bexencardioWebContact")} target="_blank" style={{ textDecoration: "none", marginRight: 4, marginLeft: 4 }}>
                <Typography color="textSecondary">bexencardio.com</Typography>
              </a>
              ·
              <LangSelect />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
      <ExpiredTokenModal open={openTokenExpiredModal} close={() => setOpenTokenExpiredModal()} setValidToken={() => setExpiredToken(false)} token={token_hash} request_uuid={request_uuid} />
      <Dialog open={worngTokenModalError}>
        <DialogTitle>{t("El enlace no es válido")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("El enlace del formulario no es válido, pongase en contacto con el distribuidor")}</DialogContentText>
          <DialogActions>
            <Button onClick={() => setWorngTokenModalError(false)} color="primary" variant="outlined">
              {t("common.ok")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={userCreatedModalOpen}>
        <DialogTitle id="alert-dialog-title">{t("Usuario creado")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" aling="justify">
            {t("El usuario se ha creado correctamente. Inicie sesión para acceder a la plataforma")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setUserCreatedModalOpen(false);
              history.push(ROUTES.SIGN_IN);
            }}
            color="primary"
            variant="outlined"
          >
            {t("common.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={requestError}>
        <DialogTitle>{t("Error en la petición")}</DialogTitle>
        <DialogContent>{t("Se ha produdico un error durante la solicitud")}</DialogContent>
        <DialogActions>
          <Button onClick={() => setRequestError(false)}>{t("common.ok")}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={policyModalOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"md"} fullWidth>
        <DialogTitle id="alert-dialog-title">{t("rescuers.register.privacity")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" aling="justify">
            <p>{t("rescuers.register.privacydescriptionI")}</p>
            <p>{t("rescuers.register.privacydescriptionI1")}</p>
            <p>{t("rescuers.register.privacydescriptionII")}</p>
            <p>{t("rescuers.register.privacydescriptionIII")}</p>
            <p>{t("rescuers.register.privacydescriptionIV")}</p>
            <p>{t("rescuers.register.privacydescriptionV")}</p>
            <p>{t("rescuers.register.privacydescriptionVI")}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPolicyModalOpen(false);
              setpolicy(false);
            }}
            variant="outlined"
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => {
              setPolicyModalOpen(false);
              setpolicy(true);
            }}
            color="primary"
            variant="outlined"
          >
            {t("common.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const useStyles = makeStyles({
  mainPaper: {
    width: "100%",
    padding: theme.spacing(3),
    borderRadius: 9,
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      marginTop: -32,
      border: 0,
    },
  },
});
