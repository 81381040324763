import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import AcordionECG from "../ECG/AcordionECG";
import { red } from "@material-ui/core/colors";
import LoadingPage from "../LoadingPage";
import DeviceTabInfoHeader from "../DeviceTabInfoHeader";

const DeviceEpisodesTab = ({ device, episodes, isEpisodesLoaded }) => {
  const { t } = useTranslation();

  const container = useRef(null);

  return (
    <Grid container spacing={2} style={{ marginTop: 12 }} justify="center">
      <Grid item xs={12}>
        <DeviceTabInfoHeader device={device} />
      </Grid>

      <Grid item xs={12} ref={container}>
        {!isEpisodesLoaded && <LoadingPage />}
        {(device.serial == "200700047" || device.serial == "200700075") && [0].map(() => <AcordionECG episodeInfo={episodeInfo} is_demo={true} />)}
        {device.serial != "200700047" && device.has_episodes && episodes.map((e, i) => <AcordionECG episodeInfo={e} is_demo={false} top={i == 0} bottom={i == episodes.length - 1} />)}
        {device.serial != "200700047" && device.serial != "200700075" && !device.has_episodes && (
          <Grid item xs={12} style={{ paddingTop: 16 }}>
            <Paper
              variant="outlined"
              style={{
                padding: 24,
                backgroundColor: red[50],
                borderRadius: 16,
              }}
            >
              <Grid item xs={12} container justify="center">
                <Grid item xs={12}>
                  <Typography variant="h5" color="error" style={{ marginBottom: 16 }}>
                    {t("device.episodes.licence_Disabled.title")}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{
                      font: "500 16px/20px 'Google Sans',Roboto,Arial,sans-serif",
                    }}
                  >
                    {t("device.episodes.has_episode_false")}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceEpisodesTab;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "30.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.secondary,
  },
  lastHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.secondary,
  },
  paper: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: 2,
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  propertyText: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
  },
}));

const episodeInfo = {
  episode_datetime: "2021-03-24T02:29:37",
  duration: 2000,
  episode_id: "71137a7d-1124-4110-a97b-e259beccf357",
};
