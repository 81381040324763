import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import ecgDoc from "../../assets/documents/20210728_163102585_a2.pdf";
//import { Worker } from "@react-pdf-viewer/core";
import useWindowDimensions from "../../functions/useWindowDimensions";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const ECG = ({ container, pdf_uri }) => {
  const { t } = useTranslation();

  const [data, setData] = useState([
    ["Time stamp", ""],
    [0, 0],
  ]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfWidth, setPdfWidth] = useState(0);
  const { height, width } = useWindowDimensions();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    let data = [["x", "ECG"]];
    for (let i = 0; i < 120; i++) {
      data.push([i, Math.round(Math.random() * 2) - 1]);
    }
    setData(data);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const executeScroll = () => container.current.scrollIntoView();

  useEffect(() => {
    let breakP;
    if (width <= 600) {
      breakP = BP.xs;
    } else if (width < 960) {
      breakP = BP.sm;
    } else if (width <= 1280) {
      breakP = BP.md;
    } else if (width < 1920) {
      breakP = BP.lg;
    } else {
      breakP = BP.xl;
    }
    setPdfWidth(width * breakP);
  }, [width]);

  return (
    <Grid item xs={12} container justify="center" style={{ marginTop: 8 }}>
      <Document file={`${window.location.origin}/app/episodes/${pdf_uri}/pdf`} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
        <Page pageNumber={pageNumber} width={pdfWidth} />
      </Document>
      <Grid item xs={12} container alignItems="center" style={{ marginTop: 6 }}>
        <Grid item xs={5} container justify="center">
          <IconButton
            disabled={pageNumber == 1}
            onClick={() => {
              executeScroll();
              setPageNumber(pageNumber - 1);
            }}
          >
            <NavigateBefore />
          </IconButton>
        </Grid>
        <Grid item xs={2} container justify="center">
          {t("pageCounter", { current: pageNumber, total: numPages })}
        </Grid>
        <Grid item xs={5} container justify="center">
          <IconButton
            disabled={pageNumber >= numPages}
            onClick={() => {
              setPageNumber(pageNumber + 1);
              executeScroll();
            }}
          >
            <NavigateNext />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ECG;

const options = {
  scales: {},
  elements: {
    line: {
      tension: 0,
    },
    point: {
      radius: 0,
      pointStyle: "line",
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const ECG_DATA = {
  status: 1,
  userStatus: {
    userId: "ee5851d2-49c6-4e21-86b6-2067ea6ab4c6",
    isLogged: true,
    hasRole: true,
    hasModule: true,
    error: 0,
  },
  data: {
    episodeid: "00000000-0000-0000-0000-000000000000",
    creation_time: "0001-01-01T00:00:00",
    ecg: [
      0, 1.25, 3.375, 4.625, 5.5, 6.25, 6.875, 7.375, 7.75, 8.125, 8.375, 8.625, 8.75, 8.875, 9, 9.125, 9.25, 9.25, 9.375, 9.375, 9.375, 9.375, 9.375, 9.375, 9.375, 9.375, 9.375, 9.375, 9.375, 9.25,
      9.25, 9.25, 9.25, 9.25, 9.25, 9.25, 9.25, 9.25, 9.125, 9.125, 9.125, 9, 9, 9, 9, 8.875, 8.875, 8.875, 8.875, 8.875, 8.875, 8.875, 8.875, 8.875, 8.75, 8.75, 8.75, 8.75, 8.75, 8.75, 8.625, 8.625,
      8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.375, 8.375, 8.375, 8.375, 8.375, 8.375, 8.25, 8.25, 8.25, 8.25, 8.25, 8.25, 8.25, 8.125, 8.125, 8.125, 8.125, 8.125, 8.125, 8.125, 8, 8, 8, 8, 7.875, 7.875,
      7.875, 7.875, 7.75, 7.75, 7.75, 7.75, 7.875, 8.375, 8.625, 8.875, 9.125, 9.25, 9.375, 9.5, 9.625, 9.625, 9.75, 9.75, 9.75, 9.875, 9.875, 9.875, 9.875, 9.875, 9.875, 9.875, 9.75, 9.75, 9.75,
      9.625, 9.625, 9.625, 9.5, 9.5, 9.5, 9.5, 9.5, 9.5, 9.5, 9.5, 9.5, 9.5, 9.375, 9.375, 9.375, 9.25, 9.25, 9.25, 9.25, 9.25, 9.25, 9.25, 9.125, 9.125, 9.125, 9.125, 9.125, 9, 9, 9, 9, 9, 9, 9,
      8.875, 8.875, 8.875, 8.75, 8.75, 8.75, 8.75, 8.75, 8.75, 8.625, 8.625, 8.625, 8.625, 8.625, 8.5, 8.5, 8.5, 8.5, 8.5, 8.5, 8.375, 8.375, 8.375, 8.25, 8.25, 8.25, 8.25, 8.25, 8.25, 8.25, 8.125,
      8.125, 8.125, 8.125, 8, 8, 8, 7.875, 7.875, 7.875, 7.875, 7.875, 7.875, 7.875, 7.875, 7.75, 7.75, 7.75, 7.75, 7.75, 7.75, 7.75, 7.75, 7.75, 7.75, 7.75, 7.625, 7.625, 7.625, 7.625, 7.625, 7.5,
      7.5, 7.5, 7.5, 7.5, 7.375, 7.375, 7.375, 7.375, 7.25, 7.25, 7.25, 7.25, 7.25, 7.125, 7.125, 7.125, 7.125, 7.125, 7.125, 7, 7, 7, 6.875, 6.875, 6.875, 6.875, 6.875, 6.875, 6.875, 6.875, 6.875,
      6.875, 6.875, 6.875, 6.75, 6.75, 6.75, 6.75, 6.75, 6.625, 6.625, 6.625, 6.625, 6.625, 6.625, 6.625, 6.625, 6.625, 6.625, 6.625, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.375, 6.375, 6.375, 6.375, 6.375,
      6.375, 6.375, 6.375, 6.375, 6.25, 6.25, 6.125, 6.125, 6.125, 6.125, 6.125, 6.125, 6.125, 6.125, 6, 6, 6.125, 6.125, 6.25, 6.375, 6.5, 6.5, 6.5, 6.625, 6.75, 6.75, 6.875, 6.875, 7, 7, 7, 7, 7,
      7.125, 7.125, 7.125, 7.25, 7.375, 7.375, 7.375, 7.5, 7.5, 7.5, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.625, 7.5, 7.375, 7.375, 7.25, 7.125,
      7.125, 7, 6.875, 6.875, 6.75, 6.625, 6.625, 6.625, 6.625, 6.5, 6.5, 6.5, 6.5, 6.375, 6.25, 6.125, 6, 5.875, 5.75, 5.625, 5.5, 5.375, 5.25, 5.25, 5.125, 5.125, 5, 5, 4.875, 4.875, 4.75, 4.75,
      4.75, 4.75, 4.625, 4.625, 4.75, 4.625, 4.625, 4.625, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.375, 4.375, 4.375, 4.375, 4.375, 4.375, 4.375, 4.375, 4.375, 4.25, 4.375, 4.125, 2.625, 0.625, 0, 0, 0.25,
      0.5, 0.625, 0.75, 0.875, 1, 1.125, 1.25, 1.25, 1.375, 1.375, 1.5, 1.5, 1.5, 1.625, 1.625, 1.625, 1.625, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.625, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5,
      1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1.375, 1.375, 1.375, 1.25, 1.25, 1.25, 1.125, 1.25, 1.125, 1.25, 1.375, 1.375, 1.5, 1.75, 2.125, 2.375, 2.75, 3.125, 3.5, 3.875, 4.375, 4.875, 5.375, 6, 6.625,
      7.125, 7.75, 8.5, 9.125, 9.875, 10.625, 11.25, 12, 12.75, 13.5, 14.25, 15, 15.625, 16.25, 16.75, 17.125, 17.375, 17.375, 17.375, 17.25, 17, 16.625, 16.25, 15.75, 15.125, 14.625, 14, 13.25, 12.5,
      11.875, 11.125, 10.5, 9.875, 9.25, 8.625, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4.125, 3.75, 3.375, 3.125, 2.75, 2.375, 2.125, 1.875, 1.625, 1.375, 1.25, 1.125, 1, 0.875, 0.625, 0.625, 0.5, 0.375,
      0.25, 0.25, 0.125, 0.125, 0.125, 0.125, 0.125, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    events: [
      {
        time_stamp: 0,
        id: 21,
        id_str: null,
        shock_energy: 0,
      },
    ],
    status: [
      {
        time_stamp: 0,
        temperature: 0,
        batt_charge: 0,
        batt_voltage: 13978,
        capacitor_voltage: 0,
        shock_energy: 0,
        rcp_time: 0,
        error_code: 0,
        rythm_type: 0,
      },
    ],
  },
};

var data2 = ECG_DATA.data.ecg.map((i, k) => ({ uv: i, time: k }));

/*ECG_DATA.data.ecg.map((i, k) => {
  k % 250 == 0 && console.log(k);
});*/

const BP = {
  xs: 0.85,
  sm: 0.8,
  md: 0.6,
  lg: 0.55,
  xl: 0.5,
};
