import { Dialog, DialogTitle, DialogContent, Fade, Popper, DialogActions, Button, Paper, Grid } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import * as API_KEYS from "../../constants/apiKeys";
import React, { useState } from "react";
import { LocationOn } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";
import moment from "moment";

const MapDialog = ({ isOpen, location, setisMapOpen }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={() => setisMapOpen(false)}>
      <DialogTitle>{`${t("location.modal.title")} (${moment(location.date_modification).format("DD/MM/YYYY · HH:mm")})`}</DialogTitle>
      <DialogContent>
        <Grid caontainer spacing={2}>
          <Grid item xs={12} style={{ height: "60vh", width: "90vw" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: API_KEYS.MAPS_API_KEY,
              }}
              defaultCenter={{
                lat: location.latitude,
                lng: location.longitude,
              }}
              defaultZoom={16}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                map.setOptions({
                  disableDefaultUI: false,
                  panControl: true,
                  mapTypeControl: true,
                  mapTypeId: "satellite",
                  streetViewControl: true,
                })
              }
            >
              <LocationOn
                key={"id1"}
                style={{
                  color: red[500],
                  position: "absolute",
                  transform: "translate(-50%, -95%)",
                }}
                lat={location.latitude}
                lng={location.longitude}
                text="prueba texto"
              />
            </GoogleMapReact>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setisMapOpen(false)} color="primary" autoFocus variant="outlined">
          {t("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapDialog;
