import moment from "moment";

const batteryRemainingCalc = ({ hasComs = true, syncDate = moment(), software, battery, updateSource }) => {
  if (parseInt(software) >= 207) return battery?.remaining_percentage;
  // como los equipos antiguis envian por sigfox la fechad e expiración, hay que coger ese dato siempre que se envia por sifgox que es updateSource != 0

  const batteryDate = !!battery?.manufacture_date && updateSource == 1 ? moment(battery?.manufacture_date) : moment(battery?.expiration_date).subtract(5, "years");

  const batt = syncDate.diff(batteryDate, "days");
  const D22 = 3990;
  const TABLEE10 = 4000; //current mA
  const TABLEE11 = 8; //time in seconds
  const H34 = batt; // dias con la batería
  const D19 = 77; // 0.077754521; //discharge_standBy_new
  const I34 = Math.round(parseInt(((H34 / 365) * 12).toFixed(0)));

  const J34 = batt - I34;

  // datos variables
  const K34 = 4; // datos de partida de fábrica
  const L34 = I34; //cargas del condensador en test mensual
  const M34 = 4; // datos de partida de fábrica

  const params = {
    coms: {
      discharge_standby: (H34 * 24 * D19) / (D22 * 10),
      nTest_Monthly: (I34 * 4000) / (D22 * 10),
      nTest_Daily: (J34 * 650) / (D22 * 10),
      discharge_running: 0, // (K34 * 86 * 10) / (D22 * 6),
      discharge_run_HV: (L34 * TABLEE11 * TABLEE10) / (D22 * 36),
      discharge_open_cover: 0, //(M34 * 20 * 630) / (D22 * 600),
    },
    standalone: {
      discharge_standby: (H34 * 24 * D19) / (D22 * 10),
      nTest_Monthly: (I34 * 270) / (D22 * 10),
      nTest_Daily: (J34 * 83) / (D22 * 10),
      discharge_running: 0, //(K34 * 86 * 10) / (D22 * 6),
      discharge_run_HV: (L34 * TABLEE11 * TABLEE10) / (D22 * 36),
      discharge_open_cover: (M34 * 20 * 630) / (D22 * 600),
    },
  };
  //console.log("bateria", batt, params.coms);

  const deviceBatteryOption = hasComs ? params.coms : params.standalone;
  const remainingBattery = 100 - Object.keys(deviceBatteryOption).reduce((acc, current) => acc + deviceBatteryOption[current], 0);

  return Math.min(100, Math.max(Math.min(Math.round(remainingBattery), battery?.remaining_percentage), 0));
};

export default batteryRemainingCalc;
