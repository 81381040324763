export const DATANET_BASE = window.location.origin + "/app";

export const HOME = "/";
export const SIGN_IN = "/login";
export const ACCOUNT_NEW = "/accountnew";

export const DEVICE = "/devices";
export const PROGRAMS = "/programs";

export const USER = "/users";
export const SOURCES = "/sources";
export const SOURCES_IN_PROGRAM = "/programs/:programId/sources";
export const SOURCES_IN_GROUP = "/programs/:programId/groups/:groupId/sources";
export const GROUPS = "/groups";
export const GROUPS_IN_PROGRAM = "/programs/:programId/groups";
export const DEVICES = "/devices";
export const DEVICES_IN_GROUP = "/programs/:programId/groups/:groupId/devices";
export const DEVICES_IN_PROGRAM = "/programs/:programId/devices";
export const ACCESSORIES = "/accessories";
export const ACCES_IN_PROG = "/programs/:programId/accessories";
export const ACCES_IN_GROUP = "/programs/:programId/groups/:groupId/accessories";
export const USERS = "/users";
export const USERS_IN_GROUP = "/programs/:programId/groups/:groupId/users";
export const USERS_IN_PROGRAM = "/programs/:programId/users";
export const RESCUERS = "/formacion/formulario-inscripcion/:lang";
export const ACCOUNT_VALIDATION = "/accountvalidation/:status";
export const REQUEST_NEW_PASSWROD = "/pw_forget";
export const PASSWOR_DCHANGE = "/passwordReset/:Token";
export const REGISTER_USER_FROM_PROGRAM = "/userRegistration/:token_hash/:request_uuid";

export const RELEASE_NOTES = "/releasesnotes";
