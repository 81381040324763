import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { blue, blueGrey, green, red } from "@material-ui/core/colors";
import { Close, Edit, FormatListNumberedRtlRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const _ = require("lodash");

const DeviceHourEditor = ({ openingTime, setOpeningTime, editable }) => {
  const { t } = useTranslation();

  const [is24Open, setIs24Open] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [customschedule, setCustomSchedule] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const [originalTime, setOriginalTime] = useState(openingTime);
  const [days, setDays] = useState([false, false, false, false, false, false, false]);
  const resetDialog = () => {
    setEditorOpen(false);
  };

  const saveChanges = () => {
    let per = periods;
    per = per.map((p) => ({
      start: parseInt(p.start.replace(":", "")),
      end: parseInt(p.end.replace(":", "")),
    }));
    per.sort((a, b) => (a.start > b.start ? 1 : b.start > a.start ? -1 : 0));
    let aux = per;
    if (per.length > 1) {
      for (let i = 1; i < per.length; i++) {
        if (per[i].start < per[1 - 1].end) {
          aux[i - 1].end = Math.max(per[i - 1].end, per[i].end);
          aux.splice(i, 1);
        }
      }
      per = aux;
    }

    let auxOpening = {
      days: [],
      original: openingTime.original ? openingTime.original : [],
    };
    openingTime.days.map((d, k) => {
      if (days[k]) {
        if (isClose) {
          auxOpening.days[k] = {
            periods: [],
            is24Open: false,
          };
        } else if (is24Open) {
          auxOpening.days[k] = {
            periods: [],
            is24Open: true,
          };
        } else {
          auxOpening.days[k] = {
            periods: per,
            is24Open: false,
          };
        }
        auxOpening.original[k] = originalTime.days[k];
      } else {
        auxOpening.days[k] = openingTime.days[k];
      }
    });
    setOpeningTime(auxOpening);
    resetDialog();
  };

  const editDay = (k) => {
    setEditorOpen(true);
    setSelectedDay(k);
    setDays(days.map((d, i) => i == k));
    setIsClose(openingTime.days[k].periods.length == 0 && !openingTime.days[k].is24Open);
    setIs24Open(openingTime.days[k].is24Open);
    setPeriods(
      openingTime.days[k].periods.map((p) => ({
        start: `${(p.start / 100).toFixed(2).toString().replace(".", ":")}`,
        end: `${(p.end / 100).toFixed(2).toString().replace(".", ":")}`,
      }))
    );
  };

  useEffect(() => {
    if (isClose || is24Open) {
      //setPeriods([]);
    } else {
      if (periods.length == 0) {
        setPeriods([{ start: "", end: "" }]);
      }
    }
  }, [isClose, is24Open, customschedule]);

  useEffect(() => {
    if (editorOpen) {
      if (isClose || is24Open) {
        setCustomSchedule(false);
      } else {
        setCustomSchedule(true);
      }
    }
  }, [editorOpen]);

  return (
    <>
      {editable && (
        <Grid item xs={12}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 500,
              fontSize: 22,
              lineHeight: "30px",
              marginTop: 16,
            }}
          >
            {t("device.location.availability")}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {openingTime.days.map((d, k) => (
          <Grid
            container
            style={{
              paddingTop: 6,
              paddingBottom: 6,
              paddingRight: 6,
              paddingLeft: 16,
              minHeight: 60,
              backgroundColor: k % 2 == 0 && blueGrey[50],
            }}
          >
            <Grid container item xs={7} alignItems={"center"}>
              <Typography variant="h6" style={{ fontSize: 18 }}>
                {t(`day${k}`)}
              </Typography>
            </Grid>
            <Grid container item xs={5} justifyContent="flex-end" alignItems="center" style={{ paddingRight: !editable && 16 }}>
              <Grid direction={"column"} justifyContent="flex-end">
                {editable && !!openingTime.original && !!openingTime.original[k] && <DayTimeText d={openingTime.original[k]} t={t} original />}
                <DayTimeText d={d} t={t} />
              </Grid>
              <Grid item>
                {editable && (
                  <IconButton color="primary" style={{ alignSelf: "flex-end", marginLeft: 16 }} onClick={() => editDay(k)}>
                    <Edit fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Dialog
        //onClose={handleClose}
        open={editorOpen}
        fullWidth
        maxWidth={"sm"}
        scroll="body"
        //PaperProps={{}}
      >
        <DialogTitle color="textSecondary"></DialogTitle>
        <DialogContent style={{ overflowY: "hidden" }}>
          <Grid container spacing={3}>
            <Grid container item xs={12} justifyContent="center">
              <Typography variant="h6" color="textPrimary">
                {t("device.timeEditor.title")}
              </Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="space-evenly">
              {[t("common.day.L"), t("common.day.M"), t("common.day.X"), t("common.day.J"), t("common.day.V"), t("common.day.S"), t("common.day.D")].map((d, k) => (
                <IconButton
                  style={{
                    width: 50,
                    height: 50,
                    border: 1,
                    backgroundColor: days[k] && blue[100],
                  }}
                  onClick={() => {
                    days[k] = !days[k];
                    setDays(days.map((d) => d));
                  }}
                >
                  {d}
                </IconButton>
              ))}
            </Grid>
            <Grid item xs={12}>
              <RadioGroup aria-label="gender" name="gender1" style={{ display: "inline" }}>
                <FormControlLabel
                  value="open"
                  control={<Radio />}
                  checked={is24Open}
                  label={t("24Open")}
                  onClick={() => {
                    setIsClose(false);
                    setIs24Open(true);
                    setCustomSchedule(false);
                  }}
                />
                <FormControlLabel
                  value="closed"
                  control={<Radio />}
                  checked={isClose}
                  label={t("closed")}
                  onClick={() => {
                    setIsClose(true);
                    setIs24Open(false);
                    setCustomSchedule(false);
                  }}
                />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  checked={customschedule}
                  label={t("customSchedule")}
                  onClick={() => {
                    setIsClose(false);
                    setIs24Open(false);
                    setCustomSchedule(true);
                  }}
                />
              </RadioGroup>
            </Grid>
            {!is24Open &&
              !isClose &&
              periods.map((p, k) => (
                <>
                  <Grid item xs={11} container spacing={2} direction="row">
                    <Grid item xs={6}>
                      <TextField
                        value={p.start.length == 5 ? p.start : "0" + p.start}
                        id="outlined-basic"
                        label={t("openTime")}
                        variant="outlined"
                        type="time"
                        fullWidth
                        focused
                        onChange={(e) => {
                          let per = periods;
                          per[k].start = e.target.value;
                          setPeriods(per.map((m) => m));
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={p.end.length == 5 ? p.end : "0" + p.end}
                        id="outlined-basic"
                        label={t("closeTime")}
                        variant="outlined"
                        type="time"
                        fullWidth
                        focused
                        error={parseInt(periods[k].start) > parseInt(p.end)}
                        onChange={(e) => {
                          let per = periods;
                          per[k].end = e.target.value;
                          setPeriods(per.map((m) => m));
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={1} justifyContent="flex-start">
                    <IconButton
                      color="primary"
                      disabled={k == 0 && periods.length == 1}
                      onClick={() => {
                        if (periods.length > 0) {
                          let per = periods;
                          per.splice(k, 1);
                          setPeriods(per.map((e) => e));
                        }
                      }}
                    >
                      <Close fontSize="medium" color="action" />
                    </IconButton>
                  </Grid>
                </>
              ))}

            {!is24Open && !isClose && (
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    let newPeriods = periods;
                    newPeriods.push({ start: "", end: "" });
                    setPeriods(newPeriods.map((e) => e));
                  }}
                >
                  <Typography>{t("addTime")}</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetDialog} color="primary" style={{ textTransform: "none" }}>
            <Typography>{t("common.close")}</Typography>
          </Button>
          <Button onClick={saveChanges} disabled={!periods.every((p) => parseInt(p.start) <= parseInt(p.end)) && !is24Open && !isClose} color="primary" style={{ textTransform: "none" }}>
            <Typography>{t("saveOpenningTime")}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeviceHourEditor;

const DayTimeText = ({ d, t, original }) =>
  d.periods.length == 0 ? (
    <Grid item>
      <Typography
        variant="body1"
        style={{
          fontSize: 16,
          color: original ? "GrayText" : d.is24Open ? green[500] : red[500],
          textDecoration: original && "line-through",
          textDecorationColor: "grey",
          textAlign: "end",
        }}
      >
        {d.is24Open ? t("24Open") : t(`closed`)}
      </Typography>
    </Grid>
  ) : (
    <Grid direction={"column"}>
      {d.periods.map((p) => (
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            textDecoration: original && "line-through",
            textAlign: "end",
          }}
        >
          {`${(p.start / 100).toFixed(2).toString().replace(".", ":")} - ${(p.end / 100).toFixed(2).toString().replace(".", ":")}`}
        </Typography>
      ))}
    </Grid>
  );
