import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  ListItem,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FilterModal = (props) => {
  const {
    coordinatorsList,
    maxDevices,
    maxRescuers,
    setFilter,
    handleClose,
    filterObject,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [coordinator, setCorrdinator] = useState(
    !!filterObject ? filterObject.coordinator : ""
  );
  const [devicesNumber, setDevicesNumber] = useState(
    !!filterObject
      ? [filterObject.devices.min, filterObject.devices.max]
      : [0, maxDevices]
  );
  const [rescuersNumber, setrescuersNumber] = useState(
    !!filterObject
      ? [filterObject.rescuers.min, filterObject.rescuers.max]
      : [0, maxRescuers]
  );
  const [groupState, setGroupState] = useState(
    !!filterObject ? filterObject.status : -1
  );

  const handleCoordinatorChange = (event, newValue) => {
    // console.log("nuevo valor", event.target.value);
    setCorrdinator(event.target.value);
  };

  const handleStateChange = (event) => {
    setGroupState(event.target.value);
  };

  const devicesHandleChange = (event, newValue) => {
    setDevicesNumber(newValue);
  };

  const rescuersHandleChange = (event, newValue) => {
    setrescuersNumber(newValue);
  };

  const valuetext = (value) => {
    return `${value}`;
  };

  const filter = () => {
    let filterObject = {};
    filterObject.coordinator = coordinator !== null && coordinator;
    filterObject.devices = { min: devicesNumber[0], max: devicesNumber[1] };
    filterObject.rescuers = { min: rescuersNumber[0], max: rescuersNumber[1] };
    filterObject.status = groupState;
    setFilter(filterObject);
    handleClose(false);
  };

  return (
    <Grid item xs={12} lg={4} xl={8}>
      <Paper>
        <Grid container className={classes.formcontainer} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("groups.filter.title")} </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {t("common.coordinator")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={coordinator}
                onChange={handleCoordinatorChange}
                label={t("common.coordinator")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {coordinatorsList.map((coordinator) => (
                  <MenuItem value={coordinator.userid}>
                    <ListItem style={{ padding: 0 }}>
                      <ListItemAvatar>
                        <Avatar color="primary">
                          {coordinator.name.charAt(0).toUpperCase()}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={coordinator.name + " " + coordinator.lastname}
                        secondary={coordinator.user_name}
                      />
                    </ListItem>
                    {coordinator.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                {t("common.state")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={groupState}
                onChange={handleStateChange}
                label={t("common.state")}
              >
                <MenuItem value={-1}> {t("common.all")}</MenuItem>
                <MenuItem value={0}>{t("common.correct")}</MenuItem>
                <MenuItem value={1}>{t("common.warning")}</MenuItem>
                <MenuItem value={2}>{t("common.actionrequired")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography id="range-slider" gutterBottom>
                {t("groups.filter.deviceNumber")}
              </Typography>
              <Slider
                value={devicesNumber}
                onChange={devicesHandleChange}
                max={maxDevices}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography id="range-slider" gutterBottom>
                {t("groups.filter.rescuerNumber")}
              </Typography>
              <Slider
                value={rescuersNumber}
                onChange={rescuersHandleChange}
                max={maxRescuers}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
              />
            </div>
          </Grid>
          <Grid itemn xs={12} container justify="flex-end" spacing={2}>
            <Grid item>
              <Button color="secondary" onClick={() => handleClose(false)}>
                {t("common.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={filter}>
                {t("common.apply")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default FilterModal;

const useStyles = makeStyles({
  formcontainer: {
    padding: 24,
  },
});
