import {
  Container,
  Grid,
  Tabs,
  Tab,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeviceAlertTab from "../components/DeviceTabs/DeviceAlertTab";
import DeviceDetailTab from "../components/DeviceTabs/DeviceDetailTab";
import PageHeader from "../components/PageHeader";
import moment from "moment";
import { Add, BatteryCharging30TwoTone, FilterList, Search, NfcOutlined, BatteryFull, AccessAlarms, Nfc } from "@material-ui/icons";
import AddAccessory from "../components/AddAccessory";
import { unmountComponentAtNode } from "react-dom";
import { withAuthorization } from "../components/Session";
import * as DATANETAPI from "../components/DataNet/DataNet";

const AccessoriesPage = () => {
  const location = useLocation();
  const { programId, groupId } = useParams();
  const { t } = useTranslation();

  const [inputText, setinputText] = useState("");
  const [accessories, setAccessories] = useState([]);
  const [isFiltered, setisFiltered] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newAccesoryType, setNewAccesoryType] = useState("");
  const [areAccessoriesLoaded, setAreAccesoriesLoaded] = useState(false);
  const [batteryFilter, setBatteryFilter] = useState(false);
  const [padsFilter, setPadsFilter] = useState(false);

  const searchHandler = (event) => {
    console.log(event);
    setinputText(event.target.value);
    filter(event.target.value, padsFilter, batteryFilter);
  };

  const filter = (inputText, pads, battery) => {
    let filteredaccs = [];
    accesoriesBack.forEach((acc) => {
      if (
        acc.serial
          .toUpperCase()
          .concat(acc.reference ? acc.reference.toUpperCase() : "-")
          .includes(inputText.toUpperCase())
      ) {
        acc.type == "t_pads" && !pads && filteredaccs.push(acc);
        acc.type == "t_battery" && !battery && filteredaccs.push(acc);
      }
    });
    inputText !== "" && setisFiltered(true);
    setAccessories(filteredaccs);
  };

  useEffect(() => {
    setAreAccesoriesLoaded(false);
    DATANETAPI.getAccessories(programId, groupId).then((snap) => {
      accesoriesBack = snap.data;
      if (snap.data !== undefined) {
        accesoriesBack = snap.data;
        setAccessories(accesoriesBack);
        setAreAccesoriesLoaded(true);
      } else {
        setAccessories([]);
        setAreAccesoriesLoaded(true);
        // console.log("Not accessories available");
      }
    });
  }, [programId, groupId]);

  return (
    <Container>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <PageHeader title={t("accessories.title")} />
        </Grid>

        <Grid item>
          <Paper style={{ padding: 12 }}>
            <Grid container direction="row" alignItems="center">
              <Grid item container alignItems="center" xs={6} justify="flex-start">
                <Grid item>
                  <TextField
                    label={t("common.search")}
                    value={inputText}
                    onChange={searchHandler}
                    margin="dense"
                    placeholder={t("common.search")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <IconButton
                  size="small"
                  color={!batteryFilter ? "primary" : "textSecondary"}
                  style={{ paddingRight: 8, paddingLeft: 12 }}
                  onClick={() => {
                    setBatteryFilter(!batteryFilter);
                    filter(inputText, padsFilter, !batteryFilter);
                  }}
                >
                  <BatteryFull />
                </IconButton>

                <IconButton
                  size="small"
                  onClick={() => {
                    setPadsFilter(!padsFilter);
                    filter(inputText, !padsFilter, batteryFilter);
                  }}
                  color={!padsFilter ? "primary" : "textSecondary"}
                  style={{ paddingLeft: 8 }}
                >
                  <Nfc />
                </IconButton>
              </Grid>

              <Grid item xs={6} container justify="flex-end">
                <Grid item container alignItems="center" justify="flex-end">
                  <div style={{ padding: 6 }}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body2">{t("accessories.new.addNew")}</Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="small"
                          aria-label="add"
                          style={{ paddingRight: 8 }}
                          disabled={!!!groupId}
                          onClick={() => {
                            setAddModalOpen(true);
                            setNewAccesoryType("t_battery");
                          }}
                        >
                          <BatteryFull />
                        </IconButton>
                        <IconButton
                          size="small"
                          aria-label="add"
                          style={{ paddingLeft: 8 }}
                          disabled={!!!groupId}
                          onClick={() => {
                            setAddModalOpen(true);
                            setNewAccesoryType("t_pads");
                          }}
                        >
                          <Nfc />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                  <AddAccessory open={addModalOpen} handleClose={setAddModalOpen} models={devicesModelsBack} type={newAccesoryType} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <TableContainer component={Paper}>
            {!areAccessoriesLoaded && <LinearProgress />}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("accessories.type")}</TableCell>
                  <TableCell align="center">{t("device.details.accesories.lot")}</TableCell>
                  <TableCell align="center">{t("device.details.accesories.ref")}</TableCell>
                  <TableCell align="center">{t("device.details.accesories.expirationDate")}</TableCell>
                  <TableCell align="center">{t("device.details.accessories.remaining")}</TableCell>
                  <TableCell align="right">{t("common.compatibility")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accessories.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    //style={{ background: row.status.main == 2 && red[100] }} //para los expirados
                  >
                    <TableCell component="th" scope="row">
                      <Grid container alignItems="center">
                        {row.type === "t_battery" ? <BatteryFull color="primary" /> : <NfcOutlined color="primary" style={{ marginRight: 4 }} />}
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                          {" " + t("common." + row.type)}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">{row.serial}</TableCell>
                    <TableCell align="center">{row.reference ? row.reference : "-"}</TableCell>
                    <TableCell align="center">{moment(row.expiration_date).format("MMM Do YY")}</TableCell>
                    <TableCell align="center">{row.total - row.used}</TableCell>
                    <TableCell align="right">{row.compatibility.map((c, i) => (i !== row.compatibility.length - 1 ? c + ", " : c))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {accessories.length === 0 && areAccessoriesLoaded && (
              <div style={{ marginTop: 32, marginLeft: 12 }}>
                <Typography variant="h3">{t("accessories.notFound")}</Typography>
              </div>
            )}
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(AccessoriesPage);

const devicesModelsBack = ["Reanibex 100", "Reanibex 200", "Reanibex 300", "Reanibex 500"];

var accesoriesBack = [
  {
    type: "t_pads",
    id: 154127,
    serial: "50623-0773",
    reference: "KSA 0501 B",
    expiration_date: "2021-02-05T11:00:07.865465",
    total: 5,
    used: 3,
    compatibility: ["Reanibex 200", "Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_pads",
    id: 154128,
    serial: "50623-0777",
    reference: "KSA 0501 B",
    expiration_date: "2023-02-05T11:00:07.865465",
    total: 10,
    used: 3,
    compatibility: ["Reanibex 200", "Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_pads",
    id: 154129,
    serial: "50625-0777",
    reference: "KSA 0501 B",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 10,
    used: 3,
    compatibility: ["Reanibex 200", "Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 154125,
    serial: "2015/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 114125,
    serial: "2016/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 200"],
  },
  {
    type: "t_battery",
    id: 124125,
    serial: "2415/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 153125,
    serial: "2015/21410356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 1545254,
    serial: "2015/33070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 944125,
    serial: "2015/270356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 754125,
    serial: "12015/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
  {
    type: "t_battery",
    id: 1034125,
    serial: "82015/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 0,
    compatibility: ["Reanibex 200"],
  },
  {
    type: "t_battery",
    id: 924125,
    serial: "prueba",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 1,
    compatibility: ["Reanibex 300", "Reanibex 500"],
  },
];
