import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../components/PageHeader";
import ProgramsCard from "../components/ProgramsCard";
import { AuthUserContext, withAuthorization } from "../components/Session";
import { getAllProgramsByUser } from "../components/DataNet/DataNet";
import ErrorDialog from "../components/ErrorDialog";
import { Add, Cancel, Check, Delete, Edit, Opacity, Remove, Search, ViewList, ViewModule, Visibility } from "@material-ui/icons";
import ProgramDialog from "../components/ProgramForm/ProgramDialog";
import DeleteDialog from "../components/ProgramForm/DeleteDialog";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { grey, purple } from "@material-ui/core/colors";
import LoadingProgramCard from "../components/ProgramsCard/LoadingProgramCard";

const ProgramsPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const roles = [
    { id: "915c8c3c-849a-49e0-8032-ac4f9feb2956", name: "PROGRAMS_DELETE" },
    { id: "7fc44b6a-c81d-4423-beb9-ba92417ab865", name: "PROGRAMS_ADD" },
    { id: "7b309536-cf4c-47b3-8a97-71fe2f1a475e", name: "PROGRAMS_UPDATE" },
    { id: "26c214da-3bf8-410e-a861-804ddfb814d9", name: "DEVICE_ADD" },
  ];

  const [programs, setPrograms] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [areProgramsLoaded, setAreProgramsLoaded] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [listMode, setListMode] = useState(true);
  const [openProgramModal, setOpenProgramModal] = useState(false);
  const [editProgram, seteditProgram] = useState(false);
  const [editinId, setEditinId] = useState("");
  const [opendeleteModal, setOpendeleteModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    !openProgramModal && loadData();
  }, [openProgramModal]);

  useEffect(() => {
    !opendeleteModal && loadData();
  }, [opendeleteModal]);

  const loadData = () => {
    setAreProgramsLoaded(false);
    getAllProgramsByUser().then((snap) => {
      if (snap.status < 0) {
        setErrorMsg(t(`error.connection_${snap.status}`));
        setPrograms([]);
      } else {
        setPrograms(snap.data);
      }
      setAreProgramsLoaded(true);
    });
  };

  const handleCreate = () => {
    seteditProgram(false);
    setOpenProgramModal(true);
  };

  const handleEdit = (id) => {
    seteditProgram(true);
    setOpenProgramModal(true);
    setEditinId(programs.filter((p) => p?.programId == id)[0]);
  };

  const handleDelete = (id) => {
    setOpendeleteModal(true);
    setOpenProgramModal(false);
    setEditinId(programs.filter((p) => p?.programId == id)[0]);
  };

  const canDelete = (id, userPrograms) => {
    return !userPrograms.find((p) => p.id === id)?.groups?.length > 0;
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser.roles.some((role) => roles.some((r) => role.id === r.id)) ? (
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PageHeader title={t("programs.title")} />
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: 12 }}>
                  <Grid container item md={12}>
                    <Grid item xs={8} md={4}>
                      <TextField
                        label={t("common.search")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={(e) => setFilterName(e.target.value)}
                        placeholder={t("common.search")}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={8} container direction="row" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                      <Grid item>
                        <Tooltip title={t("añadir programa")}>
                          <IconButton aria-label="delete" color="primary" onClick={handleCreate}>
                            <Add style={{ fontSize: 26 }} />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Tooltip title={t("cambiar vista")}>
                          <IconButton aria-label="delete" color="primary" onClick={() => setListMode(!listMode)}>
                            {!listMode ? <ViewModule style={{ fontSize: 26 }} /> : <ViewList style={{ fontSize: 26 }} />}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <LinearProgress style={{ borderRadius: 50, opacity: areProgramsLoaded ? 0 : 1 }} />
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              {programs.length > 0 ? (
                listMode ? (
                  programs?.filter((k) => filterName === "" || k?.name.toUpperCase().includes(filterName.toUpperCase())).length > 0 ? (
                    programs
                      ?.filter((k) => filterName === "" || k?.name.toUpperCase().includes(filterName.toUpperCase()))
                      .map((p, i) => (
                        <Grid key={i} item md={4} xs={12} sm={6}>
                          <ProgramsCard
                            imageUrl={window.location.origin + "/app/programs/" + p.programId + "/image"}
                            title={p.name}
                            description={p.def}
                            id={p.programId}
                            edit={() => handleEdit(p.programId)}
                            remove={() => handleDelete(p.programId)}
                            canDelete={canDelete(p.programId, authUser.programas)}
                            groups={authUser.programas}
                          />
                        </Grid>
                      ))
                  ) : (
                    <Grid container alignItems="center" direction="column">
                      <Typography variant="h4" color="textSecondary" style={{ marginTop: 64, justifySelf: "center" }}>
                        {t("programs.notFound")}
                      </Typography>
                    </Grid>
                  )
                ) : (
                  <ProgramsTable authUser={authUser} canDelete={canDelete} filterName={filterName} handleDelete={handleDelete} history={history} programs={programs} handleEdit={handleEdit} t={t} />
                )
              ) : areProgramsLoaded ? (
                <Grid container alignItems="center" direction="column">
                  <Typography variant="h4" style={{ marginTop: 64 }}>
                    {t("No se han podido cargar los programas")}
                  </Typography>
                  <Button variant="outlined" style={{ marginTop: 32 }} color="primary" onClick={loadData}>
                    {t("volver a cargar")}
                  </Button>
                </Grid>
              ) : (
                [0, 1, 2, 3, 4, 5].map((i) => (
                  <Grid key={i} item md={4} xs={12} sm={6}>
                    <LoadingProgramCard />
                  </Grid>
                ))
              )}
            </Grid>

            <ErrorDialog errorMsg={errorMsg} clearError={() => setErrorMsg("")} />
            <ProgramDialog isOpen={openProgramModal} setIsOpen={setOpenProgramModal} edit={editProgram} editingData={editinId} />
            <DeleteDialog isOpen={opendeleteModal} close={setOpendeleteModal} data={editinId} />
          </Container>
        ) : (
          <>{history.push(ROUTES.PROGRAMS + "/" + authUser.programas[0].id + "/groups")}</>
        )
      }
    </AuthUserContext.Consumer>
  );
};

const ProgramsTable = ({ programs, filterName, history, authUser, handleEdit, canDelete, handleDelete, t }) => (
  <TableContainer component={Paper} style={{ margin: 6 }}>
    <Table aria-label="simple table" size="small">
      <TableHead style={{ backgroundColor: "#023f88" }}>
        <TableRow>
          <TableCell style={{ color: grey[100], fontSize: 18 }}>{t("common.name")}</TableCell>
          <TableCell style={{ color: grey[100], fontSize: 18 }}>{t("common.description")}</TableCell>
          <TableCell style={{ color: grey[100], fontSize: 18 }} align="right">
            {t("common.actions")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {programs
          ?.filter((k) => filterName === "" || k?.name.toUpperCase().includes(filterName.toUpperCase()))
          .map((p, i) => (
            <TableRow key={i}>
              <TableCell>{p.name}</TableCell>
              <TableCell>{p.def}</TableCell>
              <TableCell align="right" style={{ display: "flex", marginBottom: -1 }}>
                <IconButton aria-label="delete" size="small" onClick={() => history.push(ROUTES.PROGRAMS + "/" + p.programId + ROUTES.GROUPS)}>
                  <Visibility color="primary" />
                </IconButton>

                <IconButton aria-label="delete" size="small" onClick={() => handleEdit(i)} style={{ marginRight: 6, marginLeft: 6 }}>
                  <Edit color="primary" />
                </IconButton>

                <Tooltip
                  title={<Typography variant="body2">{canDelete(p.programId, authUser.programas) ? t("Eliminar programa") : t("Elimina los grupos para poder eliminar el programa")}</Typography>}
                >
                  <div>
                    <IconButton aria-label="delete" size="small" onClick={() => handleDelete(i)} disabled={!canDelete(p.programId, authUser.programas)}>
                      <Delete color={canDelete(p.programId, authUser.programas) ? "error" : ""} />
                    </IconButton>
                  </div>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const condition = (authUser) => !!authUser; //(authUser) => !!authUser;

export default withAuthorization(condition)(ProgramsPage);
