import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActions, Button, Paper, Grid, Tooltip, makeStyles, IconButton, Menu, MenuItem } from "@material-ui/core";
import { blueGrey, grey, purple, red } from "@material-ui/core/colors";
import { Delete, DescriptionOutlined, Edit, MoreVert, Settings, ShopOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import login_img from "../../assets/images/r100_s.jpg";

const ProgramsCard = ({ imageUrl, title, description, id, edit, remove, canDelete, groups }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [hover, setHover] = useState(false);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [deviceSetupOpen, setDeviceSetupOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.card} id={`grCard${id}`} variant="outlined" elevation={0} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardContent style={{ flex: 1, minHeight: 128 }}>
        <Grid container alignItems="center">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h4" component="h2" color="secondary" style={{ marginBottom: 0, whiteSpace: "normal", fontWeight: 500 }}>
                {title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {description.charAt(0).toUpperCase() + description.slice(1)}
              </Typography>
            </Grid>
            <Grid item xs={2} container alignContent="flex-start" justifyContent="flex-end">
              <IconButton color="secondary" aria-label="delete" onClick={handleClick} /*onClick={() => edit(data.groupid)}*/>
                <MoreVert />
              </IconButton>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    edit();
                  }}
                >
                  <Edit color="primary" style={{ marginRight: 9 }} />
                  {t("common.edit")}
                </MenuItem>

                <MenuItem
                  disabled={!canDelete}
                  onClick={() => {
                    remove();
                    handleClose();
                  }}
                >
                  <Tooltip title={<Typography variant="body2">{canDelete ? t("Eliminar programa") : t("Elimina los grupos para poder eliminar el programa")}</Typography>}>
                    <Delete color="error" style={{ marginRight: 9 }} />
                  </Tooltip>
                  {t("delete")}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      <Grid container justifyContent="flex-end" style={{ width: "100%" }}>
        <Button
          color="primary"
          variant="contained"
          style={{
            margin: 9,
            marginRight: 12,
            marginBottom: 12,
            textTransform: "none",
            borderRadius: 16,
            minWidth: 84,
          }}
          onClick={() => {
            window.scrollTo(0, 0);
            history.push(ROUTES.PROGRAMS + "/" + id + ROUTES.GROUPS);
          }}
        >
          {t("programCard.viewGroupsCount", { count: groups.find((p) => p.id === id)?.groups?.length })}
        </Button>
      </Grid>
    </Card>
    /*
    <Card className={classes.card} id={`grCard${id}`} variant="outlined" elevation={0} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardContent style={{ flex: 1, background: "#f7f8fa", borderRadius: 12, padding: 0 }}>
        <CardActionArea style={{ margin: 0, padding: 16 }} onClick={() => history.push(ROUTES.PROGRAMS + "/" + id + ROUTES.GROUPS)}>
          <Grid container alignItems="center">
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2" color="secondary" style={{ marginBottom: 6, whiteSpace: "normal", fontWeight: 500 }}>
                  {title}
                </Typography>
                <Typography variant="subtitle1" component="p" color="textSecondary" style={{ lineHeight: 1.4 }}>
                  {description.charAt(0).toUpperCase() + description.slice(1)}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ flex: 1 }}></Grid>
          </Grid>
        </CardActionArea>
      </CardContent>

      <Grid container direction="row" justifyContent="flex-end" style={{ padding: 12 }}>
        <Tooltip title={<Typography variant="body2">{canDelete ? t("Eliminar programa") : t("Elimina los grupos para poder eliminar el programa")}</Typography>}>
          <div>
            <Button size="small" onClick={remove} disabled={!canDelete} variant="contained" style={{ color: canDelete && red[500], borderRadius: 20, textTransform: "none" }}>
              {t("delete")}
            </Button>
          </div>
        </Tooltip>

        <Button size="small" color="primary" className={classes.editButton} onClick={edit} variant="contained" style={{ borderRadius: 20, textTransform: "none" }}>
          {t("common.edit")}
        </Button>
      </Grid>
    </Card>*/
  );
  /*return (
    <Card
      style={{
        borderRadius: hover ? 16 : 12,
        border: "1px solid",
        borderColor: blueGrey[100],
        transition: "all 0.25s ease",
        transform: hover && "translate3D(0,-1px,0) scale(1.03)",
        boxShadow: hover && "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
      }}
      elevation={0}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
  
      <CardActionArea onClick={() => history.push(ROUTES.PROGRAMS + "/" + id + ROUTES.GROUPS)}>
        <CardMedia component="img" alt="Program Image" height="360" image={login_img} title={title} style={{ objectFit: "cover" }} />
        <CardContent
          style={{
            position: "absolute",
            bottom: 0,
            background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 20%, rgba(255,255,255,0.8) 60%, rgba(115,110,116,1) 100%)",
            width: "92%",
          }}
        >
          <Typography gutterBottom variant="h5" color="secondary" style={{ fontWeight: 600, textTransform: "uppercase", marginBottom: 0 }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" component="p" color="textSecondary" style={{ marginBottom: 38 }}>
            {description.charAt(0).toUpperCase() + description.slice(1)}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ justifyContent: "flex-end", marginTop: -48, paddingBottom: 9 }}>
        <Tooltip title={<Typography variant="body2">{canDelete ? t("Eliminar programa") : t("Elimina los grupos para poder eliminar el programa")}</Typography>}>
          <div>
            <Button size="small" onClick={remove} disabled={!canDelete} variant="contained" style={{ color: canDelete && red[500] }}>
              {t("delete")}
            </Button>
          </div>
        </Tooltip>

        <Button size="small" color="primary" onClick={edit} variant="contained">
          {t("common.edit")}
        </Button>
      </CardActions>
    </Card>
  );*/
};

export default ProgramsCard;

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    borderRadius: 12,
    background: "#f7f8fa",
  },
  media: {
    height: 140,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
}));
