import { Button, IconButton, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { AccountCircle, ExpandMore, Language } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUserLanguaje } from "../DataNet/DataNet";

const LangSelect = ({ icon }) => {
  const [anchor, setanchor] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLanguaje = (lang) => {
    i18n.changeLanguage(lang);
    moment.locale(lang, langsLocale[lang]);
    updateUserLanguaje(lang)
      .catch((err) => console.log("error al actualizar el idioma", err))
      .then((snap) => {});
  };

  return (
    <>
      <Button aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" type="text" onClick={handleMenu} variant={icon ? "outlined" : "text"} color="secondary">
        {icon && <Language style={{ marginRight: 6 }} />}
        {t("navigation.languaje.current")}
        <ExpandMore />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem button onClick={() => (onChangeLanguaje("es"), setAnchorEl(false))}>
          <ListItemText>{t("navigation.languaje.es")}</ListItemText>
        </MenuItem>
        <MenuItem button onClick={() => (onChangeLanguaje("en"), setAnchorEl(false))}>
          <ListItemText>{t("navigation.languaje.en")}</ListItemText>
        </MenuItem>
        <MenuItem button onClick={() => (onChangeLanguaje("it"), setAnchorEl(false))}>
          <ListItemText>{t("navigation.languaje.it")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LangSelect;

export const langsLocale = {
  es: {
    months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split("_"),
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    monthsParseExact: true,
    weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split("_"),
    weekdaysShort: "Dom_Lun_Mar_Mié_Jue_Vie_Sáb".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Ju_Vi_Sá".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D [de] MMMM [de] YYYY",
      LLL: "D [de] MMMM [de] YYYY HH:mm",
      LLLL: "dddd D [de] MMMM [de] YYYY HH:mm",
    },
    calendar: {
      sameDay: "[Hoy a] LT",
      nextDay: "[Mañana a] LT",
      nextWeek: "dddd [a] LT",
      lastDay: "[Ayer a] LT",
      lastWeek: "dddd [pasado a] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "en %s",
      past: "hace %s",
      s: "unos segundos",
      m: "un minuto",
      mm: "%d minutos",
      h: "una hora",
      hh: "%d horas",
      d: "un día",
      dd: "%d días",
      M: "un mes",
      MM: "%d meses",
      y: "un año",
      yy: "%d años",
    },
    dayOfMonthOrdinalParse: /\d{1,2}(er|o)/,
    ordinal: function (number) {
      return number + (number === 1 ? "er" : "o");
    },
    meridiemParse: /AM|PM/,
    isPM: function (input) {
      return input.charAt(0) === "P";
    },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? "AM" : "PM";
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  },
  it: {
    months: "Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre".split("_"),
    monthsShort: "Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic".split("_"),
    monthsParseExact: true,
    weekdays: "Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato".split("_"),
    weekdaysShort: "Dom_Lun_Mar_Mer_Gio_Ven_Sab".split("_"),
    weekdaysMin: "Do_Lu_Ma_Mi_Gi_Ve_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D [di] MMMM [di] YYYY",
      LLL: "D [di] MMMM [di] YYYY HH:mm",
      LLLL: "dddd D [di] MMMM [di] YYYY HH:mm",
    },
    calendar: {
      sameDay: "[Oggi alle] LT",
      nextDay: "[Domani alle] LT",
      nextWeek: "dddd [alle] LT",
      lastDay: "[Ieri alle] LT",
      lastWeek: "dddd [scorso alle] LT",
      sameElse: "L",
    },
    relativeTime: {
      future: "tra %s",
      past: "%s fa",
      s: "alcuni secondi",
      m: "un minuto",
      mm: "%d minuti",
      h: "un" + "ora",
      hh: "%d ore",
      d: "un giorno",
      dd: "%d giorni",
      M: "un mese",
      MM: "%d mesi",
      y: "un anno",
      yy: "%d anni",
    },
    dayOfMonthOrdinalParse: /\d{1,2}º/,
    ordinal: "%dº",
    meridiemParse: /AM|PM/,
    isPM: function (input) {
      return input.charAt(0) === "P";
    },
    meridiem: function (hours, minutes, isLower) {
      return hours < 12 ? "AM" : "PM";
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  },
};
