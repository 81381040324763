import * as API from "../../constants/routes";

/* test API */
const config = {
  urlBase: window.location.origin + "/app", // "http://192.168.1.145:5000/app",
};

const getAllProgramsByUser = () =>
  new Promise((resolve, reject) => {
    const path = `${config.urlBase}/programs`;
    resolve(makeAndManageRequest(path, "GET"));
  });

const getAllGroups = (programId) =>
  new Promise((resolve, reject) => {
    let path = `${config.urlBase}/groups`;
    if (programId !== undefined) {
      path = `${config.urlBase}/programs/${programId}/groups`;
    }
    resolve(makeAndManageRequest(path, "GET"));
  });

const getGroup = (groupId) =>
  new Promise((resolve, reject) => {
    if (groupId !== undefined) {
      var path = `${config.urlBase}/groups/${groupId}`;
      resolve(makeAndManageRequest(path, "GET"));
    }
  });

const getRegisteredRescuers = () =>
  new Promise((resolve, reject) => {
    var path = `${config.urlBase}/rescuers`;
    resolve(makeAndManageRequest(path, "GET"));
  });

const getAvailableCoordinators = (programId) =>
  new Promise((resolve, reject) => {
    let path = `${config.urlBase}/coordinators`;
    if (programId !== undefined) {
      path = `${config.urlBase}/programs/${programId}/coordinators`;
    }
    resolve(makeAndManageRequest(path, "GET"));
  });

const getAccessories = (programId, groupId) =>
  new Promise((resolve, reject) => {
    let path = `${config.urlBase}/accessories`;
    if (programId !== undefined) {
      path = `${config.urlBase}/programs/${programId}/accessories`;
      if (groupId !== undefined) {
        path = `${config.urlBase}/programs/${programId}/groups/${groupId}/accessories`;
      }
    }
    resolve(makeAndManageRequest(path, "GET"));
  });

const getUsers = (programId, groupId) =>
  new Promise((resolve, reject) => {
    var path = `${config.urlBase}/users`;
    if (programId !== undefined) {
      var path = `${config.urlBase}/programs/${programId}/users`;
      if (groupId !== undefined) {
        path = `${config.urlBase}/programs/${programId}/groups/${groupId}/users`;
      }
    }
    resolve(makeAndManageRequest(path, "GET"));
  });

const getUser = (userId) =>
  new Promise((resolve, reject) => {
    if (userId !== undefined) {
      var path = `${config.urlBase}/users/${userId}`;
      resolve(makeAndManageRequest(path, "GET"));
    } else {
      console.log("No se ha pasado ningun userId");
      resolve({});
    }
  });

const getDevices = (programId, groupId) =>
  new Promise((resolve, reject) => {
    let path = `${config.urlBase}/devices`;
    if (programId !== undefined) {
      path = `${config.urlBase}/programs/${programId}/devices`;
      if (groupId !== undefined) {
        path = `${config.urlBase}/programs/${programId}/groups/${groupId}/devices`;
      }
    }
    resolve(makeAndManageRequest(path, "GET"));
  });

const getDevice = (deviceId) =>
  new Promise((resolve, reject) => {
    if (deviceId !== undefined) {
      var path = `${config.urlBase}/devices/${deviceId}`;
      resolve(makeAndManageRequest(path, "GET"));
    } else {
      console.log("No se ha pasado ningun deviceId");
      resolve({});
    }
  });

const getDeviceEvents = (deviceId, filter) =>
  new Promise((resolve, reject) => {
    //console.log("entro");
    const path = `${config.urlBase}/devices/${deviceId}/events`;

    if (!!deviceId && !filter) {
      resolve(makeAndManageRequest(path, "GET"));
    } else if (filter !== undefined) {
      const path = `${config.urlBase}/devices/${deviceId}/events`;
      resolve(makeAndManageRequest(path, "POST", filter));
    }
  });

const createGroup = (group) =>
  new Promise((resolve, reject) => {
    if (group !== undefined) {
      var path = `${config.urlBase}/groups/add`;
      resolve(makeAndManageRequest(path, "POST", group));
    }
  });

const deleteGroup = (id) =>
  new Promise((resolve, reject) => {
    if (id != undefined) {
      var path = `${config.urlBase}/groups/${id}`;
      resolve(makeAndManageRequest(path, "DELETE"));
    }
    resolve({ status: 5 });
  });

const editGroup = (group) =>
  new Promise((resolve, reject) => {
    if (group !== undefined) {
      var path = `${config.urlBase}/groups/edit`;
      resolve(makeAndManageRequest(path, "POST", group));
    }
  });

const createDevice = (devices) =>
  new Promise((resolve, reject) => {
    //console.log(devices);
    if (devices !== undefined) {
      var path = `${config.urlBase}/devices/`;
      resolve(makeAndManageRequest(path, "POST", devices));
    } else {
      resolve();
    }
  });

const getLimboDevice = (device) =>
  new Promise((resolve, reject) => {
    if (device !== undefined) {
      var path = `${config.urlBase}/devices/add/R100/`;
      resolve(makeAndManageRequest(path, "POST", device));
    } else {
      console.log("no se ha pasado información del dispositivo");
      resolve();
    }
  });

const moveLimboDevice = (device) =>
  new Promise((resolve, reject) => {
    if (device !== undefined) {
      var path = `${config.urlBase}/devices/add/R100/`;
      resolve(makeAndManageRequest(path, "PUT", device));
    } else {
      console.log("no se ha pasado información del dispositivo");
      resolve();
    }
  });

const editDevice = (devices, id) =>
  new Promise((resolve, reject) => {
    if (!!devices && !!id) {
      var path = `${config.urlBase}/devices/${id}`;
      resolve(makeAndManageRequest(path, "PUT", devices));
    } else {
      console.log("no se ha pasado información del dispositivo");
      resolve();
    }
  });

const deleteDevice = (id, model) =>
  new Promise((resolve, reject) => {
    if (!!id) {
      const path = model == "Reanibex 100" ? `${config.urlBase}/devices/${id}/R100` : `${config.urlBase}/devices/${id}`;
      resolve(makeAndManageRequest(path, "DELETE"));
    } else {
      console.log("no se ha pasado información del dispositivo");
      resolve();
    }
  });

const moveDevice = (id, moveForm) =>
  new Promise((resolve, reject) => {
    if (id != undefined) {
      const path = `${config.urlBase}/devices/${id}/move`;
      resolve(makeAndManageRequest(path, "PUT", moveForm));
    } else {
      console.log("no se ha pasado el idtenificador del dispositivo");
      resolve();
    }
  });

const addRescuer = (rescuer) =>
  new Promise((resolve, reject) => {
    if (rescuer !== undefined) {
      const path = `${config.urlBase}/rescuers`;
      resolve(makeAndManageRequest(path, "POST", rescuer));
    } else {
      console.log("No se han psado datos del rescatador");
      resolve();
    }
  });

const editConfig = (configuration, groupId) =>
  new Promise((resolve, reject) => {
    if (groupId !== undefined) {
      const path = `${config.urlBase}/groups/${groupId}/conf/edit`;
      resolve(makeAndManageRequest(path, "POST", configuration));
    } else {
      console.log("No se han pasado datos del groupID");
      resolve();
    }
  });

const currentUser = () => {
  const path = `${config.urlBase}/session/user`;
  return makeAndManageRequest(path, "GET");
};

const editUser = (user, userId) =>
  new Promise((resolve, reject) => {
    if (user !== undefined && userId !== undefined) {
      const path = `${config.urlBase}/users/${userId}`;
      resolve(makeAndManageRequest(path, "POST", user));
    } else {
      console.log("no se han pasado datos del usuario y/O del userID");
      resolve();
    }
  });

const changePassword = (passForm, userId) =>
  new Promise((resolve, reject) => {
    if (passForm !== undefined && userId !== undefined) {
      var path = `${config.urlBase}/users/${userId}/changepassword`;
      fetch(path, {
        method: "POST",
        body: JSON.stringify(passForm),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return { status: -1 };
          }
        })
        .then((data) => resolve(data))
        .catch((data) => reject(data));
    }
  });

const createAccount = (accountForm) =>
  new Promise((resolve, reject) => {
    if (accountForm !== undefined) {
      const path = `${config.urlBase}/account/create`;
      resolve(makeAndManageRequest(path, "POST", accountForm));
    } else {
      console.log("falta datos del formulario");
      resolve();
    }
  });

const getConfOptions = (groupid) =>
  new Promise((resolve, reject) => {
    if (groupid !== null) {
      const path = `${config.urlBase}/groups/${groupid}/conf/options`;
      resolve(makeAndManageRequest(path, "GET"));
    } else {
      console.log("falta datos del grupo");
      resolve();
    }
  });

const newVersionGroup = (groupid) =>
  new Promise((resolve, reject) => {
    if (!!groupid) {
      const path = `${config.urlBase}/groups/${groupid}/checkVersion`;
      resolve(makeAndManageRequest(path, "GET"));
    } else {
      console.log("falta datos del grupo");
      resolve();
    }
  });

const updateUserLanguaje = (lang) => {
  const path = `${config.urlBase}/users/langs`;
  return makeAndManageRequest(path, "POST", { lang: lang });
};

const getEpisodes = (deviceid) =>
  new Promise((resolve, reject) => {
    if (deviceid !== undefined) {
      const path = `${config.urlBase}/devices/${deviceid}/episodes`;
      resolve(makeAndManageRequest(path, "GET"));
    } else {
      console.log("falta datos del dispositivo");
      resolve();
    }
  });

const getNotificationHistory = () => {
  const path = `${config.urlBase}/users/notifications/history`;
  return makeAndManageRequest(path, "GET");
};

const setReviewNofitifications = (notifications) =>
  new Promise((resolve, reject) => {
    if (notifications !== undefined) {
      const path = `${config.urlBase}/users/notifications/review`;
      resolve(makeAndManageRequest(path, "POST", notifications));
    } else {
      console.log("falta datos de notificación");
      resolve();
    }
  });

const editProgram = (program_id, data) =>
  new Promise((resolve, reject) => {
    if (!!program_id) {
      const path = `${config.urlBase}/programs/${program_id}`;
      resolve(makeAndManageRequest(path, "PUT", data));
    } else {
      console.log("falta datos de programa");
      resolve();
    }
  });

const createProgram = (data) =>
  new Promise((resolve, reject) => {
    const path = `${config.urlBase}/programs/add`;
    resolve(makeAndManageRequest(path, "POST", data));
  });

const removeProgram = (program_id) =>
  new Promise((resolve, reject) => {
    if (!!program_id) {
      const path = `${config.urlBase}/programs/${program_id}`;
      resolve(makeAndManageRequest(path, "DELETE"));
    } else {
      console.log("falta datos id del programa");
      resolve();
    }
  });

const createAccountFromToken = (userData) => {
  const path = `${config.urlBase}/account/registration`;
  return makeAndManageRequest(path, "POST", userData);
};

const requestNewAccountToken = (token, requestUid) => {
  const path = `${config.urlBase}/account/resetTokenRegistration`;
  return makeAndManageRequest(path, "POST", { token_hash: token, request_uuid: requestUid });
};

const makeAndManageRequest = (path, method, body) => {
  return new Promise((resolve, reject) => {
    try {
      const m = {
        GET: () => fetch(path),
        DELETE: () => fetch(path, { method: "DELETE" }),
        PUT: () => fetch(path, { method: method, body: JSON.stringify(body ?? {}), headers: { "Content-Type": "application/json" } }),
        POST: () => fetch(path, { method: method, body: JSON.stringify(body ?? {}), headers: { "Content-Type": "application/json" } }),
      };
      const f = m[method]();
      f.then((response) => {
        if (response.ok || response.redirected) {
          return response.json();
        } else {
          return { status: -1 };
        }
      })
        .then((data) => resolve(data))
        .catch((err) => resolve({ status: -2, msg: err }));
    } catch (err) {
      resolve({ status: -2, msg: err });
    }
  });
};

export {
  getAllProgramsByUser,
  getAllGroups,
  getGroup,
  getAvailableCoordinators,
  getAccessories,
  getUsers,
  getUser,
  getDevices,
  getDevice,
  createGroup,
  editGroup,
  getRegisteredRescuers,
  addRescuer,
  editConfig,
  editDevice,
  createDevice,
  currentUser,
  editUser,
  changePassword,
  getLimboDevice,
  moveLimboDevice,
  createAccount,
  getConfOptions,
  newVersionGroup,
  updateUserLanguaje,
  getDeviceEvents,
  getEpisodes,
  deleteDevice,
  moveDevice,
  deleteGroup,
  getNotificationHistory,
  setReviewNofitifications,
  makeAndManageRequest,
  editProgram,
  createProgram,
  removeProgram,
  createAccountFromToken,
  requestNewAccountToken,
};
