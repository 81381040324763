import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import React from "react";
import { useTranslation } from "react-i18next";

export const R100ConfigChangedModal = ({ open, handleClose, isNew }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ color: orange[500] }}>
        {t("Cuidado")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t("groupMove.settings.text")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="error">
          {isNew ? t("groups.new.title.add") : t("common.cancel")}
        </Button>
        <Button variant="outlined" onClick={() => handleClose(true)} color="primary" autoFocus>
          {t("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
