import { Modal, Fade, Paper, makeStyles, Container, Grid, Dialog, AppBar, Toolbar, Slide, Button, Typography, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import AddGroupModal from "./AddGroupModal";
import FilterModal from "./FilterModal";
import MapModal from "./MapModal";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GroupsModals = (props) => {
  const classes = useStyles();
  const { open, handleClose, type } = props;
  const { t } = useTranslation();

  if (type == "add") {
    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => handleClose(false)} aria-label="close">
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("groups.new.title.add")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <AddGroupModal {...props} />
          </Grid>
        </Grid>
      </Dialog>
    );
  } else {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => handleClose(false)}
        closeAfterTransition
        //BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} unmountOnExit>
          <Grid container justify="center" alignItems="center">
            {type == "map" && <MapModal {...props} />}
            {type == "filter" && <FilterModal {...props} />}
            {type == "add" && (
              <Grid container justifyContent="center">
                <Grid item xs={12} lg={8}>
                  <AddGroupModal {...props} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Fade>
      </Modal>
    );
  }
};

export default GroupsModals;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
