import { Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../constants/routes";
import { Link, useParams } from "react-router-dom";
import ProgramLink from "./ProgramLink";

const GroupUsers = ({ programsList }) => {
  const { t } = useTranslation();
  const { programId, groupId } = useParams();
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <ProgramLink />
        <Link to={ROUTES.PROGRAMS + "/" + programId + ROUTES.GROUPS} color="inherit">
          {programsList[programId]}
        </Link>
        <Typography color="textPrimary">{programsList[groupId]}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" color="secondary" style={{ marginTop: 18 }}>
        {t("navigation.users")}
      </Typography>
    </>
  );
};

export default GroupUsers;

/**    <Breadcrumbs aria-label="breadcrumb">
      <Link to={ROUTES.PROGRAMS}>
        <Typography variant="h5">{t("navigation.programs")}</Typography>
      </Link>
      <Link to={ROUTES.PROGRAMS + "/" + programId + ROUTES.GROUPS}>
        <Typography variant="h5">{programsList[programId]}</Typography>
      </Link>

      <Link>
        <Typography variant="h5">{t("navigation.groups")}</Typography>
      </Link>

      <Typography variant="h5" color="textPrimary">
        {programsList[groupId]}
      </Typography>

      <Typography variant="h5" color="textPrimary">
        {t("breadcrumbs.allUsers")}
      </Typography>
    </Breadcrumbs> */
