import React, { useEffect, useState } from "react";
import { FormControl, Grid, makeStyles, TextField, Typography, FormHelperText, IconButton, Select, MenuItem, InputLabel } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import GoogleMapReact from "google-map-react";
import * as API_KEYS from "../../constants/apiKeys";
import { Edit, LocationOn } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { blueGrey, deepPurple, red } from "@material-ui/core/colors";
import MomentUtils from "@date-io/moment";
import MoveDeviceSelec from "../MoveDeviceSelec";
import { isNumber } from "lodash";
import DeviceHourEditor from "./DeviceHourEditor";
import { AuthUserContext } from "../Session";
import moment from "moment";

const DeviceForm = ({ currentposition, setFormValues, value, setisFormValid, editForm, isNew, user, programId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const unabizRegex = /^[0-9a-fA-F]{8}$/;

  const [expirationDateBatt, setExpirationDateBatt] = useState(!!!value.expirationDateBatt ? moment().add(1, "M") : value.expirationDateBatt);
  const [expirationDatePads, setExpirationDatePads] = useState(!!!value.expirationDatePads ? moment().add(1, "M") : value.expirationDatePads);
  const [isMarkerSet, setisMarkerSet] = useState(!!value);
  const [location, setLocation] = useState(!!!value.location ? { latitude: "", longitude: "" } : value.location);
  const [formErrors, setFormErrors] = useState({});
  const [name, setname] = useState(!!value.name ? value.name : "");
  const [code, setCode] = useState(!!value.code ? value.code : "");
  const [map, setMap] = useState(null);
  const [locationName, setLocationName] = useState(value.location !== undefined && !!value.location.name ? value.location.name : "");
  const [locationDescription, setLocationDescription] = useState(value.location !== undefined && !!value.location.description ? value.location.description : "");
  const [serialNumber, setserialNumber] = useState(!!value.serial ? value.serial : "");
  const [modelName, setmodelName] = useState(value.model != "other" ? value.model : "");
  const [manufacturer, setmanufacturer] = useState(value.model !== "other" ? value.manufacturer : "");
  const [openingTime, setOpeningTime] = useState(value.openingTime ? value.openingTime : DEFAULT_OPENINGTIME);

  const [unabizId, setUnabizId] = useState(value.unabiz_id ? value.unabiz_id : "");
  const [unabizAlert, setUnabizAlert] = useState(value.unabiz_alert ? value.unabiz_alert : false);
  const [groupList, setGroupList] = useState(!!user ? user.groupList : "");

  const getGroupList = () => {
    var group_list = [];
    if (!!user && user !== undefined) {
      var group_list = user.groups;

      // Program_Manager_L1
      if (user.roles.some((x) => (x.id = "1162af87-cd83-4dc6-8110-4ab68d19de33"))) {
        var program = user.programas.find((x) => x.id == programId);
        group_list = program.groups;
      }
    }
    return group_list;
  };

  const mapClickHandler = ({ x, y, lat, lng, event }) => {
    setFormErrors({ ...formErrors, latitude: null, longitude: null });
    setLocation({
      ...location,
      latitude: lat,
      longitude: lng,
    });
    setisMarkerSet(true);
  };

  const handleModel = (event) => {
    setmodelName(event.target.value);
  };

  const checkDate = (date) => {
    let msg = null;
    if (!!!date) {
      msg = t("account.error.empty");
    } else {
      msg = !date.isSameOrAfter(new Date()) ? t("accessories.new.invalidDateMin") : msg;
      msg = !date.isValid() ? t("accessories.new.invalidDate") : msg;
    }
    return msg;
  };

  const handleDateChangeBatt = (date) => {
    setExpirationDateBatt(date);
    setFormErrors({ ...formErrors, expirationDateBatt: checkDate(date) });
  };

  const handleDateChangePads = (date) => {
    setExpirationDatePads(date);
    setFormErrors({ ...formErrors, expirationDatePads: checkDate(date) });
  };

  const handleName = (event) => {
    setname(event.target.value);
  };

  const handleCode = (event) => {
    setCode(event.target.value);
  };

  const handleManufacturer = (event) => {
    setmanufacturer(event.target.value);
  };

  const handleSerialNumber = (event) => {
    setserialNumber(event.target.value);
  };
  const checkSerialNumber = () => {
    /** para nuestros modelos, tenemos que comprobar
     * cada uno de los formatos, para otros,
     * solo es requerido el campo
     */
    var msg =
      serialNumber === ""
        ? t("account.error.empty")
        : new RegExp(SN_FORMAT_REGEX[value.model]).test(serialNumber)
        ? null
        : t("account.error.format_sn", {
            format: SN_PLACEHOLDER[value.model],
          });
    setFormErrors({ ...formErrors, serialNumber: msg });
  };

  const checkName = () => {
    setFormErrors({
      ...formErrors,
      name: name === "" ? t("account.error.empty") : null,
    });
  };

  const checkManufacturer = () => {
    setFormErrors({
      ...formErrors,
      manufacturer: manufacturer === "" ? t("account.error.empty") : null,
    });
  };

  const checkCode = () => {
    setFormErrors({
      ...formErrors,
      code: code === "" ? t("account.error.empty") : null,
    });
  };

  const checkModel = () => {
    setFormErrors({
      ...formErrors,
      model: modelName === "" ? t("account.error.empty") : null,
    });
  };

  const checkColcation = (itemName, value) => {
    setFormErrors({
      ...formErrors,
      [itemName]: value === "" ? t("account.error.empty") : null,
    });
  };

  const getFormData = (groupid = value?.groupid, programid = value?.programid) => {
    const values = {
      programid: programid,
      groupid: groupid,
      name: name,
      serial: serialNumber,
      manufacturer: manufacturer,
      disabled: false,
      model: modelName,
      location: {
        ...location,
        update_source: 5,
        name: locationName,
        description: locationDescription,
      },
      expirationDateBatt: expirationDateBatt,
      expirationDatePads: expirationDatePads,
      code: code,
      openingTime: { days: openingTime.days, is24Open: false },
      unabiz_id: unabizId,
      unabiz_alert: unabizAlert,
    };
    setisFormValid(true);
    setFormValues(values);
  };

  useEffect(() => {
    /* cuando se vuelve al formulario otra vez,
    se verifican los campos para ver que son correctos */
    if (!isNew) {
      var msg =
        serialNumber === ""
          ? t("account.error.empty")
          : new RegExp(SN_FORMAT_REGEX[value.model]).test(serialNumber)
          ? null
          : t("account.error.format_sn", {
              format: SN_PLACEHOLDER[value.model],
            });
      setFormErrors({
        ...formErrors,
        serialNumber: msg,
        name: name === "" ? t("account.error.empty") : null,
        manufacturer: manufacturer === "" ? t("account.error.empty") : null,
        model: modelName === "" ? t("account.error.empty") : null,
        code: value.model == "Reanibex 100" && code === "" ? t("account.error.empty") : null,
      });
    }
  }, []);

  useEffect(() => {
    setGroupList(getGroupList());
  }, []);

  useEffect(() => {
    if (isNumber(location.longitude) && isNumber(location.latitude)) {
      !!map &&
        map.panTo({
          lat: isNaN(location.latitude) ? 0 : parseFloat(location.latitude),
          lng: isNaN(location.longitude) ? 0 : parseFloat(location.longitude),
        });
      setisMarkerSet(true);
    } else {
      setisMarkerSet(false);
    }
  }, [location]);

  useEffect(() => {
    const areFieldsValid = name !== "" && serialNumber !== "" && manufacturer !== "" && modelName !== "" && locationName !== "" && locationDescription !== "" && isMarkerSet;
    if (value.model == "Reanibex 100") {
      if (!isNew) formErrors["code"] = null;
      checkLocation(location) && (isNew ? code !== "" : true) && Object.keys(formErrors).every((e) => !!!formErrors[e]) && areFieldsValid //&& name !== "" && !!!formErrors.serialNumber
        ? getFormData()
        : setisFormValid(false);
    } else {
      // console.log(unabizId, unabizAlert, unabizRegex.test(unabizId));
      checkLocation(location) && (unabizAlert ? unabizRegex.test(unabizId) : true) && Object.keys(formErrors).every((e) => !!!formErrors[e]) && areFieldsValid //&& !!!formErrors.expirationDateBatt && !!!formErrors.expirationDatePads &&     name !== "" && !!!formErrors.serialNumber && manufacturer !== "" && modelName !== ""
        ? getFormData()
        : setisFormValid(false);
    }
  }, [name, serialNumber, manufacturer, modelName, location, code, expirationDatePads, expirationDateBatt, formErrors, locationName, locationDescription, openingTime, unabizId, unabizAlert]);

  const checkLocation = () => !isNaN(location.latitude) && !isNaN(location.longitude);

  const validateUnabiz = () => {
    setFormErrors({
      ...formErrors,
      unabizId: unabizAlert && !unabizRegex.test(unabizId) ? t("El campo tiene que tener 8 caracteres en hexadecimal, numeros y letras de la A la F") : null,
    });
  };

  const handleApiLoaded = (map, maps) => {
    setMap(map);
  };

  return (
    <Grid item xs={12} container justify="center" style={{ marginTop: 24 }}>
      <Grid container className={classes.formcontainer} spacing={3} item xs={12} lg={9}>
        <Grid item xs={12}>
          <Typography color="secondary" style={{ fontWeight: 500, fontSize: 22, lineHeight: "30px" }}>
            {t("devices.new.title.form")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            value={name}
            required
            onChange={handleName}
            label={t("common.name")}
            error={!!formErrors.name}
            helperText={formErrors.name}
            onBlur={checkName}
            placeholder="Ej: R100 entrada Edificio A"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            value={manufacturer}
            onChange={handleManufacturer}
            required
            label={t("common.manufacturer")}
            placeholder={t("common.manufacturer")}
            error={!!formErrors.manufacturer}
            helperText={formErrors.manufacturer}
            onBlur={checkManufacturer}
            variant="outlined"
            disabled={value.manufacturer === "Bexen Cardio"}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            value={modelName}
            required
            label={t("common.model")}
            placeholder={t("common.model")}
            error={!!formErrors.model}
            helperText={formErrors.model}
            onBlur={checkModel}
            variant="outlined"
            onChange={handleModel}
            disabled={value.manufacturer === "Bexen Cardio"}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={value.model !== "Reanibex 100" ? 12 : 7}>
          <TextField
            value={serialNumber}
            onChange={handleSerialNumber}
            label={t("common.serialNumber")}
            placeholder={SN_PLACEHOLDER[value.model]}
            variant="outlined"
            required
            disabled={editForm && value.model === "Reanibex 100"}
            onBlur={checkSerialNumber}
            error={!!formErrors.serialNumber}
            helperText={formErrors.serialNumber}
            fullWidth
          />
        </Grid>

        {value.model === "Reanibex 100" && !editForm && (
          <Grid item xs={12} sm={5}>
            <TextField
              value={code}
              label={t("common.code")}
              required
              disabled={editForm}
              onBlur={checkCode}
              error={!!formErrors.code}
              helperText={formErrors.code}
              placeholder={t("common.code")}
              variant="outlined"
              onChange={handleCode}
              fullWidth
            />
          </Grid>
        )}

        {value.model !== "Reanibex 100" && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                    label={t("device.details.accesories.expirationDate.batt")}
                    format="MM/yyyy"
                    required
                    error={!!formErrors.expirationDateBatt}
                    helperText={formErrors.expirationDateBatt}
                    views={["year", "month"]}
                    minDate={new Date()}
                    value={expirationDateBatt}
                    onChange={handleDateChangeBatt}
                    InputAdornmentProps={{ position: "start" }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                    required
                    label={t("device.details.accesories.expirationDate.pads")}
                    format="MM/yyyy"
                    views={["year", "month"]}
                    error={!!formErrors.expirationDatePads}
                    helperText={formErrors.expirationDatePads}
                    minDate={new Date()}
                    value={expirationDatePads}
                    onChange={handleDateChangePads}
                    InputAdornmentProps={{ position: "start" }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </>
        )}

        {!isNew && !!user && (
          <>
            <Grid item xs={12}>
              <Typography
                color="secondary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  lineHeight: "30px",
                  marginTop: 16,
                }}
              >
                {t("grupo vinculado")}
              </Typography>
            </Grid>

            <AuthUserContext.Consumer>{(AuthUser) => <MoveDeviceSelec device={value} user={AuthUser} setNewGroup={getFormData} />}</AuthUserContext.Consumer>
          </>
        )}

        <Grid item xs={12}>
          <Typography
            color="secondary"
            style={{
              fontWeight: 500,
              fontSize: 22,
              lineHeight: "30px",
              marginTop: 16,
              color: !checkLocation(location) && red[500],
            }}
          >
            {t("devices.new.location")}
          </Typography>

          {value.model === "Reanibex 100" && (
            <Typography variant="body1" color="textSecondary">
              {t("devices.new.location.r100Connected")}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            value={locationName}
            label={t("device.location.name")}
            placeholder={t("device.location.namePlaceholder")}
            required
            type="text"
            variant="outlined"
            error={!!formErrors.locationName}
            helperText={formErrors.locationName}
            onBlur={() => checkColcation("locationName", locationName)}
            onChange={(e) => {
              setLocationName(e.target.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            value={locationDescription}
            label={t("device.location.description")}
            placeholder={t("device.location.descriptionPlaceholder")}
            error={!!formErrors.locationDescription}
            helperText={formErrors.locationDescription}
            required
            type="text"
            variant="outlined"
            onBlur={() => checkColcation("locationDescription", locationDescription)}
            onChange={(e) => {
              setLocationDescription(e.target.value);
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={!!location && location.latitude}
            label={t("latitude")}
            placeholder={t("latitudePlaceholder")}
            required
            error={!!formErrors.latitude}
            helperText={formErrors.latitude}
            type="number"
            variant="outlined"
            onChange={(e) => {
              setFormErrors({
                ...formErrors,
                latitude: e.target.value == "" ? t("account.error.empty") : null,
              });

              setLocation({
                ...location,
                latitude: parseFloat(e.target.value),
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={!!location && location.longitude}
            label={t("longitude")}
            required
            error={!!formErrors.longitude}
            helperText={formErrors.longitude}
            type="number"
            placeholder={t("longitudePlaceholder")}
            variant="outlined"
            onChange={(e) => {
              setFormErrors({
                ...formErrors,
                longitude: e.target.value == "" ? t("account.error.empty") : null,
              });
              setLocation({
                ...location,
                longitude: parseFloat(e.target.value),
              });
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ height: "60vh", padding: 0, margin: 12, border: !!formErrors.longitude || !!formErrors.latitude ? " 2px solid red" : "", borderColor: red[500] }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: API_KEYS.MAPS_API_KEY,
            }}
            defaultCenter={
              !!currentposition
                ? currentposition
                : {
                    lat: 43.1859375,
                    lng: -2.5095625,
                  }
            }
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={15}
            //  style={{ border: "2px solid" }}
            onClick={mapClickHandler}
            options={{
              streetViewControl: true,
              mapTypeId: "satellite",
              mapTypeControl: true,
              fullscreenControl: false,
            }}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {isMarkerSet && (
              <LocationOn
                style={{
                  color: red[500],
                  position: "absolute",
                  transform: "translate(-50%, -95%)",
                }}
                lat={location.latitude}
                lng={location.longitude}
              />
            )}
          </GoogleMapReact>
        </Grid>

        <DeviceHourEditor openingTime={openingTime} setOpeningTime={setOpeningTime} editable />

        {value.model !== "Reanibex 100" && (
          <>
            <Grid item xs={12}>
              <Typography color="secondary" style={{ fontWeight: 500, fontSize: 22, lineHeight: "30px" }}>
                {t("devices.new.unabiz.title")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("device.unabiz.id")}
                placeholder={t("device.unabiz.idPlaceholder")}
                required={unabizAlert}
                error={!!formErrors.unabizId}
                helperText={formErrors.unabizId}
                type="text"
                variant="outlined"
                value={unabizId}
                onChange={(e) => setUnabizId(e.target.value)}
                onBlur={() => validateUnabiz()}
                fullWidth
              />
            </Grid>
            <Grid container item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="RecuerAlertSelect_label">{t("device.unabiz.alert.label")}</InputLabel>
                <Select value={unabizAlert} onChange={(e) => setUnabizAlert(e.target.value)} id="RecuerAlertSelect" labelId="RecuerAlertSelect_label" label={t("device.unabiz.alert.label")}>
                  <MenuItem value={false}> {t("device.unabiz.alert.option_0")} </MenuItem>
                  <MenuItem value={true}> {t("device.unabiz.alert.option_1")} </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceForm;

const SN_FORMAT_REGEX = {
  "Reanibex 100": "^(2007|A001)[0-9]{5}$",
  "Reanibex 200": "^2001[0-9]{4,4}$",
  "Reanibex 300": "^2004[0-9]{4,4}$",
  "Reanibex 500": "^2005[0-9]{4,4}$",
  other: "[sS]*",
};

const SN_PLACEHOLDER = {
  "Reanibex 100": "",
  "Reanibex 200": "2001XXXX",
  "Reanibex 300": "2004XXXX",
  "Reanibex 500": "2005XXXX",
  other: "SN",
};

const useStyles = makeStyles((theme) => ({
  formcontainer: {
    padding: 24,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DEFAULT_OPENINGTIME = {
  days: [
    {
      is24Open: true,
      periods: [],
    },
    {
      is24Open: true,
      periods: [],
    },
    {
      is24Open: true,
      periods: [],
    },
    {
      is24Open: true,
      periods: [],
    },
    {
      is24Open: true,
      periods: [],
    },
    { is24Open: false, periods: [] },
    {
      is24Open: false,
      periods: [],
    },
  ],
  is24Open: false,
};
