import * as COMNS from "../constants/comsTypes";

const getDeviceComunications = (options) => {
  let coms = [];
  if (options?.wifi == 1) coms.push(COMNS.WIFI);
  if (options?.sigfox == 1) coms.push(COMNS.SIGFOX);
  return coms.length === 0 ? [COMNS.STANDALONE] : coms;
};

export default getDeviceComunications;
