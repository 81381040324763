import { Breadcrumbs, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";

const AllGroups = () => {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography color="textPrimary">{t("breadcrumbs.allGroups")}</Typography>
      </Breadcrumbs>
      <Typography variant="h3" color="secondary" style={{ marginTop: 18 }}>
        {t("navigation.groups")}
      </Typography>
    </>
  );
};

export default AllGroups;
