import { FormControl, makeStyles, MenuItem, Select, InputLabel, Grid, Zoom } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as DATANETAPI from "../DataNet/DataNet";
import ErrorDialog from "../ErrorDialog";

const ModelSelect = ({ models, model, setModel, setGroup, group }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [isSelectedGroup, setIsSelectedGroup] = useState(false);
  const { programId, groupId } = useParams();
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");

  const handleChange = (event) => {
    setModel(event.target.value);
  };

  const handleGroup = (event) => {
    setSelectedGroup(event.target.value);
    setGroup(event.target.value);
  };

  useEffect(() => {
    if (groups.length === 1) {
      //console.log("entro a actualziar", groups[0].id);
      setSelectedGroup(groups[0].id.toString());
      setGroup(groups[0].id.toString());
    }
  }, [groups]);

  useEffect(() => {
    let groups = [];
    if (groupId !== undefined) {
      DATANETAPI.getGroup(groupId).then((snap) => {
        if (snap.status >= 0) {
          groups.push({ id: snap.data.groupid, name: snap.data.name });
          setGroups(groups);
          setIsSelectedGroup(true);
        } else {
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        }
      });
    } else {
      DATANETAPI.getAllGroups(programId).then((snap) => {
        snap.data.forEach((group) => {
          groups.push({ id: group.groupid, name: group.name });
        });
        setGroups(groups);
        setIsSelectedGroup(true);
      });
    }
    !!group && setSelectedGroup(group);
  }, []);

  return (
    <Grid item style={{ height: "50vh" }} container spacing={4} alignContent="center" justifyContent="center">
      {!isSelectedGroup ? (
        <>
          <Grid item xs={12} sm={4} lg={4}>
            <Skeleton variant="rect" style={{ marginTop: 9, height: 64 }} />
          </Grid>

          <Grid item xs={12} sm={4} lg={4}>
            <Skeleton variant="rect" style={{ marginTop: 9, height: 64 }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={4} lg={4}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel id="group">{t("common.group")}</InputLabel>
              <Select labelId="group" value={selectedGroup} onChange={handleGroup} disabled={!isSelectedGroup || groups.length == 1} label={t("common.group")}>
                {groups.map((group) => (
                  <MenuItem value={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} lg={4}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel id="model">{t("common.model")}</InputLabel>
              <Select labelId="model" disabled={!isSelectedGroup} value={model} onChange={handleChange} label={t("common.model")}>
                {models.map((model, key) => (
                  <MenuItem value={key}>{model}</MenuItem>
                ))}
                <MenuItem value={"other"}>{t("common.other")} </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      )}

      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
    </Grid>
  );
};

export default ModelSelect;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
