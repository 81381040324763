import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../constants/routes";

import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from "@material-ui/core";

const AccountValidationPage = () => {
  const { t } = useTranslation();
  const { status } = useParams();

  return (
    <Grid container justify="center">
      <Dialog open={true} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        {status === "1" ? (
          <DialogTitle id="alert-dialog-slide-title">{t("account.validation.success.title")}</DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-slide-title">{t("account.validation.error.title")}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" aling="justify">
            {status === "1" ? <p>{t("account.validation.success.desc")}</p> : <p>{t("account.validation.error.desc")}</p>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {status === "1" ? (
            <>
              <Button color="primary" component={Link} to={`${ROUTES.SIGN_IN}`} autoFocus>
                {t("account.validation.success.button")}
              </Button>
            </>
          ) : (
            <>
              <Button color="primary" component={Link} to={`${ROUTES.ACCOUNT_NEW}`} autoFocus>
                {t("account.validation.error.button")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AccountValidationPage;
