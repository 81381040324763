import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Edit, Info, InfoOutlined, InfoRounded, More, MoreVert, Settings } from "@material-ui/icons";
import { Error, ExpandMore, Image, Person, Room, WarningOutlined, DeleteRounded } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import * as COMS from "../../constants/comsTypes";
import MapDialog from "../MapDialog";
import { blueGrey, orange, purple, red } from "@material-ui/core/colors";
import DeviceSetupForm from "../DeviceSetupForm";
import { AuthUserContext } from "../Session";
import AddDevice from "../AddDevice";

const GroupCard = ({ data, edit, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deviceSetupOpen, setDeviceSetupOpen] = useState(false);
  const [openAddDevice, setOpenAddDevice] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.card} id={`grCard${index}`} variant="outlined" elevation={0} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardContent style={{ flex: 1 }}>
        <Grid container alignItems="center">
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h5" component="h2" color="secondary" style={{ marginBottom: 0, whiteSpace: "normal", fontWeight: 500 }}>
                {data.name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {data.def}
              </Typography>
            </Grid>

            <Grid item xs={2} container alignContent="flex-start" justifyContent="flex-end">
              <IconButton color="secondary" aria-label="delete" onClick={handleClick} /*onClick={() => edit(data.groupid)}*/>
                <MoreVert />
              </IconButton>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    edit(data.groupid);
                  }}
                >
                  <Edit color="primary" style={{ marginRight: 9 }} />
                  {t("common.edit")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenAddDevice(true);
                  }}
                >
                  <Add color="primary" style={{ marginRight: 9 }} />
                  {t("devices.toolbar.tooltip.addDevice")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeviceSetupOpen(true);
                    handleClose();
                  }}
                >
                  <Settings color="primary" style={{ marginRight: 9 }} />
                  {t("group.r100Conf.button")}
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" style={{ marginTop: 16, alignContent: "center", display: "flex", fontWeight: 600 }}>
                {t("GourCard.Coms")}
                <Tooltip
                  interactive
                  placement="top"
                  classes={{
                    tooltip: {
                      maxWidth: 500,
                    },
                  }}
                  title={
                    <Typography>
                      {t("groudCard.typeComsDesc")}
                      {COMS.STANDALONE}
                    </Typography>
                  }
                >
                  <InfoOutlined fontSize="small" color="primary" style={{ marginLeft: 6 }} />
                </Tooltip>
              </Typography>

              {data?.comsTypes?.map((v, i) => (
                <Chip label={v} key={i} variant="default" size="small" color="secondary" style={{ borderRadius: 9, marginTop: 6, marginRight: 6 }} />
              ))}
              {(!!!data?.comsTypes || data?.comsTypes?.length == 0) && (
                <Chip label={t("unassigned")} variant="outlined" size="small" color="secondary" style={{ borderRadius: 9, marginTop: 6, marginRight: 6 }} />
              )}
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <List
              dense
              subheader={
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  {t("groups.card.coordinators")}
                </Typography>
              }
              style={{ marginTop: 16, fontWeight: 600 }}
            >
              {data.coordinators.map((coordinator, i) => (
                <ListItem key={i} style={{ padding: 0 }}>
                  <ListItemText primary={coordinator.name + " " + coordinator.lastname} secondary={coordinator.user_name} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>

      <Grid container justifyContent="flex-end" style={{ width: "100%" }}>
        <Button
          color="primary"
          variant="contained"
          style={{
            margin: 9,
            marginRight: 12,
            marginBottom: 12,
            textTransform: "none",
            borderRadius: 16,
            minWidth: 84,
          }}
          onClick={() => {
            window.scrollTo(0, 0);
            history.push(`${ROUTES.PROGRAMS}/${data.programid + ROUTES.GROUPS}/${data.groupid + ROUTES.DEVICES}`);
          }}
        >
          {t("groupsCard.ViewDevices", { number: data.devicesnumber })}
        </Button>
      </Grid>
      {<AuthUserContext.Consumer>{(authUser) => <AddDevice open={openAddDevice} handleClose={setOpenAddDevice} authUser={authUser} selectedGroupID={data.groupid} />}</AuthUserContext.Consumer>}
      {deviceSetupOpen && (
        <DeviceSetupForm
          open={deviceSetupOpen}
          handleClose={setDeviceSetupOpen}
          groupId={data?.groupid} // siempre deberia de haber un grupo
        />
      )}
    </Card>
  );
};

export default GroupCard;

const useStyles = makeStyles((theme) => ({
  card: {
    //padding: 6,
    borderRadius: 12,
    background: "#f7f8fa",
  },
  media: {
    height: 140,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
