import moment from "moment";

const getDeviceStatus = (device, outOfSync, battery = 100, software) => {
  //console.log(device, outOfSync, battery);
  const { device_status, update } = device;
  /**
   *  updateSource
   *  0 Fábrica
   *  1 USB_Sync
   *  2 SIGFOX
   *  3 WIFI
   */
  const UPDATE_STATE = {
    1: "errorStatus",
    2: "sigfoxNewErrorStatus",
    3: "wifiErrorStatus",
    4: "sigfoxNewErrorStatus",
  };

  const ErrorCodesByVersion = getErrorCodes(software);

  const low_Battery = 15;
  const replace_Battery = 10;

  /* puede darse el caso que el equipo no haya sido sincronizado o hace mucho que no se sincroniza
  , se muestra el error de sincronización pendiente , y se revisa si hay actualizaciones pendientes*/
  if (device_status?.updateSource == 0 || outOfSync) {
    return {
      statusResult: 1,
      errors: [
        /*{ ...CODES[0], id: 0 }*/
      ],
    };
  }

  /* sen analiza el estado de la tapa para comprobar si realmente está abierta o no*/
  const sourceErrorStatus = device_status?.[UPDATE_STATE[device_status?.updateSource]];

  let isCoverOpen = device_status?.alertStatus?.coverStatus?.isOpen;

  if (isCoverOpen) {
    // si se ha actualizado despues de la detección dea tapa abierta, se revisa los datos de la comunicación para ver si sigue la tapa abierta

    const coverAlertChange = moment(device_status?.alertStatus?.coverStatus?.lastChange);
    const lastStateUpdate = moment(device_status?.updateDate);

    isCoverOpen = coverAlertChange.isAfter(lastStateUpdate) ? isCoverOpen : sourceErrorStatus?.open_cover === 1;
  }

  let response = { statusResult: 0, errors: isCoverOpen ? [{ ...CODES[256], id: 256, date: moment(device_status?.alertStatus?.coverStatus?.lastChange)?.format("DD/MM/YYYY") }] : [] };
  let pendingUpdates = false;

  if (!!update) {
    /* Como en el estado se muestrar los eventos de actualizaciones,
     esto solo se utiliza para ver si hay acguan actualización pendiente para poner el statusResult a 1 */
    pendingUpdates = Object.keys(update).some((US) => update[US] == 1);
  }

  /* se analiza el calculo de la batería para asegurarse de dar los avisos de bateria */
  if (battery <= replace_Battery) {
    response.errors.push({ ...CODES[33], id: 33 });
  } else if (battery <= low_Battery) {
    response.errors.push({ ...CODES[32], id: 32 });
  }
  try {
    if (device_status?.updateSource !== 0) {
      // se filtran los erores para que el 0 no aparezca como error, se trata del "error" sin errores
      let errors = Object.keys(sourceErrorStatus)
        .filter((k) => k.includes("error") && sourceErrorStatus[k] != 0)
        .map((k) => ({ ...CODES[sourceErrorStatus[k]], id: sourceErrorStatus[k] }));

      let statusResult = 0;
      if (errors.length > 0) {
        statusResult = errors.every((e) => e.type === "warning") ? 1 : 2;
      } else {
        statusResult = response?.errors?.every((e) => e.type === "warning") && response?.errors.length > 0 ? 1 : pendingUpdates ? 1 : 0;
      }
      // se filtra para evitar codigos duplicados

      response = { statusResult: statusResult, errors: elimininarDuplicados(response?.errors?.concat([...new Set(errors.map((item) => item.id))].map((id) => errors.find((item) => item.id === id)))) };
    } else {
      response.errors = [];
      response.statusResult = response?.errors?.every((e) => e.type === "warning") ? 1 : 2;
    }

    return response;
  } catch (error) {
    return response;
  }
};

export default getDeviceStatus;

const elimininarDuplicados = (data) => {
  const uniqueMap = new Map();
  Object.values(data).forEach((obj) => {
    uniqueMap.set(obj.id, obj);
  });
  return Array.from(uniqueMap.values());
};

const getErrorCodes = (software) => {
  if (software <= 209) return CODES;
  // para modificar los codigos de error originales se hacen las modificaciones para la version 210 o superior
  let newCodes = { ...CODES };
  newCodes[63].type = "warning";
  return newCodes;
};

const CODES = {
  "-1": { errorCode: "eERR_Factory_data", type: "warning" }, // este error lo da el RDN no el equipo
  0: { errorCode: "eERR_NONE", type: "warning" },
  1: { errorCode: "eERR_OPTION_DISABLED", type: "warning" },
  2: { errorCode: "eERR_NV_SZ", type: "error" },
  4: { errorCode: "eERR_NFC_INFO", type: "error" },
  5: { errorCode: "eERR_ZP_DATA", type: "error" },
  6: { errorCode: "eERR_AUDIO_MAX", type: "error" },
  10: { errorCode: "eERR_DEF_CHARGING_FAST", type: "error" },
  11: { errorCode: "eERR_DEF_CHARGING_SLOW", type: "error" },
  12: { errorCode: "eERR_DEF_DISCHARGING_H", type: "error" },
  13: { errorCode: "eERR_DEF_DISCHARGING_R", type: "error" },
  14: { errorCode: "eERR_DEF_SHOCK", type: "error" },
  15: { errorCode: "eERR_DEF_CURRENT_SENSE", type: "error" },
  20: { errorCode: "eERR_ELECTRODE_EXPIRED", type: "warning" },
  21: { errorCode: "eERR_ELECTRODE_USED", type: "warning" },
  22: { errorCode: "eERR_ELECTRODE_NOT_CONNECTED", type: "warning" },
  23: { errorCode: "eERR_ELECTRODE_WRONG", type: "warning" }, //v210
  30: { errorCode: "eERR_BATTERY_EXPIRED", type: "warning" },
  31: { errorCode: "eERR_BATTERY_UNKNOWN", type: "warning" },
  32: { errorCode: "eERR_BATTERY_LOW", type: "warning" },
  33: { errorCode: "eERR_BATTERY_REPLACE", type: "warning" },
  34: { errorCode: "eERR_BATTERY_TEMP_OUT_RANGE", type: "warning" },
  35: { errorCode: "eERR_BATTERY_TEMP_OUT_RANGE_OFF", type: "warning" },
  36: { errorCode: "eRR_RTC", type: "warning" }, //v210
  40: { errorCode: "eERR_SMON_DCMAIN_TOO_HIGH", type: "error" },
  41: { errorCode: "eERR_SMON_DCMAIN_TOO_LOW", type: "error" },
  42: { errorCode: "eERR_SMON_18V_TOO_HIGH", type: "error" },
  43: { errorCode: "eERR_SMON_18V_TOO_LOW", type: "error" },
  50: { errorCode: "eERR_ADS_DEVICE_ID", type: "error" },
  51: { errorCode: "eERR_ADS_DEVICE_ACCESS", type: "error" },
  52: { errorCode: "eERR_ADS_CALIBRATION", type: "error" },
  53: { errorCode: "eERR_ADS_PAT_CONNECTED", type: "warning" },
  60: { errorCode: "eERR_BOOT_PROCESSOR", type: "error" },
  61: { errorCode: "eERR_BOOT_NO_UPDATE", type: "warning" }, //v210 Eliminado
  62: { errorCode: "eERR_BOOT_PROCESSOR_UPDATE", type: "warning" },
  63: { errorCode: "eERR_BOOT_SPI_MEMORY", type: "error" }, //v210 se modifica a warning
  70: { errorCode: "eERR_AUDIO_PLAYER", type: "warning" },
  71: { errorCode: "eERR_AUDIO_QUEUE_FULL", type: "warning" },
  80: { errorCode: "eERR_COMM_UART_SELECT", type: "warning" },
  81: { errorCode: "eERR_COMM_UART_BAUD_RATE", type: "warning" },
  82: { errorCode: "eERR_COMM_UART_RX_ERROR", type: "warning" },
  //90-99 · se eliminarn por no uso
  100: { errorCode: "eERR_COMM_WIFI_INIT", type: "warning" },
  101: { errorCode: "eERR_COMM_WIFI_CHECK_STATUS", type: "warning" },
  102: { errorCode: "eERR_COMM_WIFI_INIT_SYNC", type: "warning" },
  103: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_DATA", type: "warning" },
  104: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_BAUD", type: "warning" },
  105: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_FLOW", type: "warning" },
  106: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_PARITY", type: "warning" },
  107: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_STOP", type: "warning" },
  108: { errorCode: "eERR_COMM_WIFI_INIT_GET_MAC_ADDRESS", type: "warning" },
  109: { errorCode: "eERR_COMM_WIFI_INIT_GET_IP_ADDRESS", type: "warning" },
  110: { errorCode: "eERR_COMM_WIFI_INIT_OPEN", type: "warning" },
  111: { errorCode: "eERR_COMM_WIFI_CERT_CLIENT_ROUTER", type: "warning" },
  112: { errorCode: "eERR_COMM_WIFI_CERT_CLIENT_ROUTER_CA", type: "warning" },
  113: { errorCode: "eERR_COMM_WIFI_CERT_CLIENT_ROUTER_KEY", type: "warning" },
  114: { errorCode: "eERR_COMM_WIFI_CERT_EAP_CONFIG", type: "warning" },
  115: { errorCode: "eERR_COMM_WIFI_CERT_SERVER_TLS_CA", type: "warning" },
  116: { errorCode: "eERR_COMM_WIFI_CERT_SERVER_TLS_CONFIG", type: "warning" },
  117: { errorCode: "eERR_COMM_WIFI_CERT_LIST", type: "warning" },
  118: { errorCode: "eERR_COMM_WIFI_CERT_RESET_STORE", type: "warning" },
  //119 · se eliminarn por no uso
  120: { errorCode: "eERR_COMM_WIFI_OPEN_TCP", type: "warning" },
  121: { errorCode: "eERR_COMM_WIFI_CLOSE_TCP", type: "warning" },
  122: { errorCode: "eERR_COMM_WIFI_OPEN_UDP", type: "warning" },
  123: { errorCode: "eERR_COMM_WIFI_CLOSE_UDP", type: "warning" },
  124: { errorCode: "eERR_COMM_WIFI_OPEN_UDP_SERVER", type: "warning" },
  125: { errorCode: "eERR_COMM_WIFI_CLOSE_UDP_SERVER", type: "warning" },
  126: { errorCode: "eERR_COMM_WIFI_OPEN_TLS", type: "warning" },
  127: { errorCode: "eERR_COMM_WIFI_CLOSE_TLS", type: "warning" }, //v210
  129: { errorCode: "eERR_COMM_WIFI_SERVER_ALIVE", type: "warning" }, //v210
  130: { errorCode: "eERR_COMM_WIFI_SEND_FRAME_TEST", type: "warning" }, //v210
  131: { errorCode: "eERR_COMM_WIFI_SEND_FRAME_ALERT", type: "warning" }, //v210
  132: { errorCode: "eERR_COMM_WIFI_SEND_FRAME_GPS", type: "warning" }, //v210
  133: { errorCode: "eERR_COMM_WIFI_SEND_EPISODE", type: "warning" }, //v210
  134: { errorCode: "eERR_COMM_WIFI_SEND_TEST", type: "warning" }, //v210
  135: { errorCode: "eERR_COMM_WIFI_SEND_DATA", type: "warning" }, //v210
  136: { errorCode: "eERR_COMM_WIFI_RECEIVE_UPGRADE_FILE", type: "warning" }, //v210
  137: { errorCode: "eERR_COMM_WIFI_RECEIVE_CONFIG_FILE", type: "warning" }, //v210
  139: { errorCode: "eRR_COMM_WIFI_SERVER_RPS_ER", type: "warning" }, //v210
  140: { errorCode: "eERR_COMM_SIGFOX", type: "warning" },
  141: { errorCode: "eERR_COMM_SIGFOX_STATUS", type: "warning" },
  142: { errorCode: "eERR_COMM_SIGFOX_MAX_POWER", type: "warning" },
  143: { errorCode: "eERR_COMM_SIGFOX_RX", type: "warning" },
  144: { errorCode: "eERR_COMM_SIGFOX_TEST_TX_FREQ", type: "warning" },
  145: { errorCode: "eERR_COMM_SIGFOX_TEST_RX_FREQ", type: "warning" },
  146: { errorCode: "eERR_COMM_SIGFOX_CW_TEST_START", type: "warning" },
  147: { errorCode: "eERR_COMM_SIGFOX_CW_TEST_STOP", type: "warning" },
  148: { errorCode: "eERR_COMM_SIGFOX_RX_GFSK_TEST", type: "warning" },
  150: { errorCode: "eERR_COMM_GPS", type: "warning" },
  160: { errorCode: "eERR_COMM_ACC", type: "warning" },
  161: { errorCode: "eERR_COMM_ACC_PRESENCE", type: "warning" },
  162: { errorCode: "eERR_COMM_ACC_CONFIGURATION", type: "warning" },
  163: { errorCode: "eERR_COMM_ACC_INTERRUPT", type: "warning" },
  //164-175 · Eliminados por no uso v210
  180: { errorCode: "eERR_KEYS", type: "error" },
  181: { errorCode: "eERR_LEDS", type: "error" },
  189: { errorCode: "eRR_CRYPTO_AUTH", type: "warning" }, //V210
  190: { errorCode: "eRR_EXPIRED_CERT_TLS", type: "warning" },
  191: { errorCode: "eRR_EXPIRED_SOON_CERT_TLS", type: "warning" },
  192: { errorCode: "eRR_EXPIRED_WPA_EAP", type: "warning" },
  193: { errorCode: "eRR_EXPIRED_SOON_WPA_EAP", type: "warning" },

  /* detección de tapa abierta
   * Para evitar hacer modificaciones, se añade como un warning en la posición 256, que no puede ser ocupada por errores del equipo (MAX 255)
   */
  256: { errorCode: "eERR_COVER_OPEN", type: "warning" },
  //300: { errorCode: "eERR_BAD_TEST_FORMAT", type: "warning" }, // error cuando se validan los test durante los procesos de sincronización
};

const CODES209 = {
  0: { errorCode: "eERR_NONE", type: "warning" },
  1: { errorCode: "eERR_OPTION_DISABLED", type: "warning" },
  2: { errorCode: "eERR_NV_SZ", type: "error" },
  4: { errorCode: "eERR_NFC_INFO", type: "error" },
  5: { errorCode: "eERR_ZP_DATA", type: "error" },
  6: { errorCode: "eERR_AUDIO_MAX", type: "error" },
  10: { errorCode: "eERR_DEF_CHARGING_FAST", type: "error" },
  11: { errorCode: "eERR_DEF_CHARGING_SLOW", type: "error" },
  12: { errorCode: "eERR_DEF_DISCHARGING_H", type: "error" },
  13: { errorCode: "eERR_DEF_DISCHARGING_R", type: "error" },
  14: { errorCode: "eERR_DEF_SHOCK", type: "error" },
  15: { errorCode: "eERR_DEF_CURRENT_SENSE", type: "error" },
  20: { errorCode: "eERR_ELECTRODE_EXPIRED", type: "warning" },
  21: { errorCode: "eERR_ELECTRODE_USED", type: "warning" },
  22: { errorCode: "eERR_ELECTRODE_NOT_CONNECTED", type: "warning" },
  //23
  30: { errorCode: "eERR_BATTERY_EXPIRED", type: "warning" },
  31: { errorCode: "eERR_BATTERY_UNKNOWN", type: "warning" },
  32: { errorCode: "eERR_BATTERY_LOW", type: "warning" },
  33: { errorCode: "eERR_BATTERY_REPLACE", type: "warning" },
  34: { errorCode: "eERR_BATTERY_TEMP_OUT_RANGE", type: "warning" },
  35: { errorCode: "eERR_BATTERY_TEMP_OUT_RANGE_OFF", type: "warning" },
  //36
  40: { errorCode: "eERR_SMON_DCMAIN_TOO_HIGH", type: "error" },
  41: { errorCode: "eERR_SMON_DCMAIN_TOO_LOW", type: "error" },
  42: { errorCode: "eERR_SMON_18V_TOO_HIGH", type: "error" },
  43: { errorCode: "eERR_SMON_18V_TOO_LOW", type: "error" },
  50: { errorCode: "eERR_ADS_DEVICE_ID", type: "error" },
  51: { errorCode: "eERR_ADS_DEVICE_ACCESS", type: "error" },
  52: { errorCode: "eERR_ADS_CALIBRATION", type: "error" },
  53: { errorCode: "eERR_ADS_PAT_CONNECTED", type: "warning" },
  60: { errorCode: "eERR_BOOT_PROCESSOR", type: "error" },
  61: { errorCode: "eERR_BOOT_NO_UPDATE", type: "warning" },
  62: { errorCode: "eERR_BOOT_PROCESSOR_UPDATE", type: "warning" },
  63: { errorCode: "eERR_BOOT_SPI_MEMORY", type: "error" }, //
  70: { errorCode: "eERR_AUDIO_PLAYER", type: "warning" },
  71: { errorCode: "eERR_AUDIO_QUEUE_FULL", type: "warning" },
  80: { errorCode: "eERR_COMM_UART_SELECT", type: "warning" },
  81: { errorCode: "eERR_COMM_UART_BAUD_RATE", type: "warning" },
  82: { errorCode: "eERR_COMM_UART_RX_ERROR", type: "warning" },
  90: { errorCode: "eERR_COMM_WIFI_INIT", type: "warning" }, //
  91: { errorCode: "eERR_COMM_WIFI_CHECK_STATUS", type: "warning" }, //
  92: { errorCode: "eERR_COMM_WIFI_INIT_FACTORY_RESET_MAC", type: "warning" }, //
  93: { errorCode: "eERR_COMM_WIFI_INIT_SYNC", type: "warning" }, //
  94: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_HEADER", type: "warning" }, //
  95: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_DATA", type: "warning" }, //
  96: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_BAUD", type: "warning" }, //
  97: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_FLOW", type: "warning" }, //
  98: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_PARITY", type: "warning" }, //
  99: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_STOP", type: "warning" }, //
  100: { errorCode: "eERR_COMM_WIFI_INIT_GET_MAC_ADDRESS", type: "warning" },
  101: { errorCode: "eERR_COMM_WIFI_INIT_CHECK_STATUS", type: "warning" },
  102: { errorCode: "eERR_COMM_WIFI_INIT_GET_VERSION", type: "warning" },
  103: { errorCode: "eERR_COMM_WIFI_INIT_NETWORK_UP", type: "warning" },
  104: { errorCode: "eERR_COMM_WIFI_INIT_NETWORK_DOWN", type: "warning" },
  105: { errorCode: "eERR_COMM_WIFI_INIT_SAVE_VARIABLES", type: "warning" },
  106: { errorCode: "eERR_COMM_WIFI_INIT_GET_IP_ADDRESS", type: "warning" },
  107: { errorCode: "eERR_COMM_WIFI_INIT_CACERT_FILE", type: "warning" },
  108: { errorCode: "eERR_COMM_WIFI_INIT_CACERT_OPEN", type: "warning" },
  109: { errorCode: "eERR_COMM_WIFI_INIT_CACERT_LENGTH", type: "warning" },
  110: { errorCode: "eERR_COMM_WIFI_INIT_CACERT_READ", type: "warning" },
  111: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_TLS_CACERT", type: "warning" },
  112: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_TLS_CACERT_OPEN", type: "warning" },
  113: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_TLS_CACERT_CLOSE", type: "warning" },
  114: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_TLS_CACERT_CREATE", type: "warning" },
  115: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_TLS_CACERT_DELETE", type: "warning" },
  116: { errorCode: "eERR_COMM_WIFI_TX_RX", type: "warning" },
  117: { errorCode: "eERR_COMM_WIFI_TX_RX_GET_SERVER_ALIVE", type: "warning" },
  118: { errorCode: "eERR_COMM_WIFI_TX_RX_POST_INFO_DATA", type: "warning" },
  119: { errorCode: "eERR_COMM_WIFI_TX_RX_GET_UPGRADE_FILE", type: "warning" }, //
  120: { errorCode: "eERR_COMM_WIFI_TX_RX_UPGRADE_FILE_OPEN", type: "warning" },
  121: { errorCode: "eERR_COMM_WIFI_TX_RX_UPGRADE_FILE_WRITE", type: "warning" },
  122: { errorCode: "eERR_COMM_WIFI_TX_RX_UPGRADE_FILE_FLUSH", type: "warning" },
  123: { errorCode: "eERR_COMM_WIFI_TX_RX_SEND_TCP_DATA", type: "warning" },
  124: { errorCode: "eERR_COMM_WIFI_TX_RX_SEND_UDP_DATA", type: "warning" },
  125: { errorCode: "eERR_COMM_WIFI_TX_RX_SEND_TLS_DATA", type: "warning" },
  126: { errorCode: "eERR_COMM_WIFI_TX_RX_GET_CONF_FILE", type: "warning" },
  //27
  //29-37
  //39
  140: { errorCode: "eERR_COMM_SIGFOX", type: "warning" },
  141: { errorCode: "eERR_COMM_SIGFOX_STATUS", type: "warning" },
  142: { errorCode: "eERR_COMM_SIGFOX_MAX_POWER", type: "warning" },
  143: { errorCode: "eERR_COMM_SIGFOX_RX", type: "warning" },
  144: { errorCode: "eERR_COMM_SIGFOX_TEST_TX_FREQ", type: "warning" },
  145: { errorCode: "eERR_COMM_SIGFOX_TEST_RX_FREQ", type: "warning" },
  146: { errorCode: "eERR_COMM_SIGFOX_CW_TEST_START", type: "warning" },
  147: { errorCode: "eERR_COMM_SIGFOX_CW_TEST_STOP", type: "warning" },
  148: { errorCode: "eERR_COMM_SIGFOX_RX_GFSK_TEST", type: "warning" },
  150: { errorCode: "eERR_COMM_GPS", type: "warning" },
  160: { errorCode: "eERR_COMM_ACCELEROMETER", type: "warning" },
  161: { errorCode: "eERR_COMM_ACC_WRITE_CTRL_REG1", type: "warning" },
  162: { errorCode: "eERR_COMM_ACC_WRITE_CTRL_REG2", type: "warning" },
  163: { errorCode: "eERR_COMM_ACC_WRITE_CTRL_REG4", type: "warning" },
  164: { errorCode: "eERR_COMM_ACC_WRITE_CTRL_REG5", type: "warning" }, //
  165: { errorCode: "eERR_COMM_ACC_WRITE_CTRL_REG6", type: "warning" }, //
  166: { errorCode: "eERR_COMM_ACC_WRITE_INT2_CFG", type: "warning" }, //
  167: { errorCode: "eERR_COMM_ACC_WRITE_INT2_THS", type: "warning" }, //
  168: { errorCode: "eERR_COMM_ACC_WRITE_INT2_DURATION", type: "warning" }, //
  169: { errorCode: "eERR_COMM_ACC_WRITE_INT1_CFG", type: "warning" }, //
  170: { errorCode: "eERR_COMM_ACC_WRITE_INT1_THS", type: "warning" }, //
  171: { errorCode: "eERR_COMM_ACC_WRITE_INT1_DURATION", type: "warning" }, //
  172: { errorCode: "eERR_COMM_ACC_READ", type: "warning" }, //
  173: { errorCode: "eERR_COMM_ACC_READ_PRESENCE", type: "warning" }, //
  174: { errorCode: "eERR_COMM_ACC_READ_XYZ_DATA", type: "warning" }, //
  175: { errorCode: "eERR_COMM_ACC_INT2", type: "warning" }, //
  180: { errorCode: "eERR_KEYS", type: "error" },
  181: { errorCode: "eERR_LEDS", type: "error" },
  //89
  190: { errorCode: "eRR_EXPIRED_CERT_TLS", type: "warning" },
  191: { errorCode: "eRR_EXPIRED_WPA_EAP", type: "warning" },
  192: { errorCode: "eRR_NO_LOAD_CERT_TLS", type: "warning" },
  193: { errorCode: "eRR_NO_LOAD_WPA_EAP", type: "warning" },
};

const CODES210 = {
  0: { errorCode: "eERR_NONE", type: "warning" },
  1: { errorCode: "eERR_OPTION_DISABLED", type: "warning" },
  2: { errorCode: "eERR_NV_SZ", type: "error" },
  4: { errorCode: "eERR_NFC_INFO", type: "error" },
  5: { errorCode: "eERR_ZP_DATA", type: "error" },
  6: { errorCode: "eERR_AUDIO_MAX", type: "error" },
  10: { errorCode: "eERR_DEF_CHARGING_FAST", type: "error" },
  11: { errorCode: "eERR_DEF_CHARGING_SLOW", type: "error" },
  12: { errorCode: "eERR_DEF_DISCHARGING_H", type: "error" },
  13: { errorCode: "eERR_DEF_DISCHARGING_R", type: "error" },
  14: { errorCode: "eERR_DEF_SHOCK", type: "error" },
  15: { errorCode: "eERR_DEF_CURRENT_SENSE", type: "error" },
  20: { errorCode: "eERR_ELECTRODE_EXPIRED", type: "warning" },
  21: { errorCode: "eERR_ELECTRODE_USED", type: "warning" },
  22: { errorCode: "eERR_ELECTRODE_NOT_CONNECTED", type: "warning" },
  23: { errorCode: "eERR_ELECTRODE_WRONG", type: "warning" },
  30: { errorCode: "eERR_BATTERY_EXPIRED", type: "warning" },
  31: { errorCode: "eERR_BATTERY_UNKNOWN", type: "warning" },
  32: { errorCode: "eERR_BATTERY_LOW", type: "warning" },
  33: { errorCode: "eERR_BATTERY_REPLACE", type: "warning" },
  34: { errorCode: "eERR_BATTERY_TEMP_OUT_RANGE", type: "warning" },
  35: { errorCode: "eERR_BATTERY_TEMP_OUT_RANGE_OFF", type: "warning" },
  36: { errorCode: "eRR_RTC", type: "warning" },
  40: { errorCode: "eERR_SMON_DCMAIN_TOO_HIGH", type: "error" },
  41: { errorCode: "eERR_SMON_DCMAIN_TOO_LOW", type: "error" },
  42: { errorCode: "eERR_SMON_18V_TOO_HIGH", type: "error" },
  43: { errorCode: "eERR_SMON_18V_TOO_LOW", type: "error" },
  50: { errorCode: "eERR_ADS_DEVICE_ID", type: "error" },
  51: { errorCode: "eERR_ADS_DEVICE_ACCESS", type: "error" },
  52: { errorCode: "eERR_ADS_CALIBRATION", type: "error" },
  53: { errorCode: "eERR_ADS_PAT_CONNECTED", type: "warning" },
  60: { errorCode: "eERR_BOOT_PROCESSOR", type: "warning" },
  61: { errorCode: "eERR_BOOT_PROCESSOR_UPDATE", type: "warning" },
  62: { errorCode: "eERR_BOOT_SPI_MEMORY", type: "warning" },
  70: { errorCode: "eERR_AUDIO_PLAYER", type: "warning" },
  71: { errorCode: "eERR_AUDIO_QUEUE_FULL", type: "warning" },
  80: { errorCode: "eERR_COMM_UART_SELECT", type: "warning" },
  81: { errorCode: "eERR_COMM_UART_BAUD_RATE", type: "warning" },
  82: { errorCode: "eERR_COMM_UART_RX_ERROR", type: "warning" },
  100: { errorCode: "eERR_COMM_WIFI_INIT", type: "warning" },
  101: { errorCode: "eERR_COMM_WIFI_CHECK_STATUS", type: "warning" },
  102: { errorCode: "eERR_COMM_WIFI_INIT_SYNC", type: "warning" },
  103: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_DATA", type: "warning" },
  104: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_BAUD", type: "warning" },
  105: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_FLOW", type: "warning" },
  106: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_PARITY", type: "warning" },
  107: { errorCode: "eERR_COMM_WIFI_INIT_CONFIG_UART_STOP", type: "warning" },
  108: { errorCode: "eERR_COMM_WIFI_INIT_GET_MAC_ADDRESS", type: "warning" },
  109: { errorCode: "eERR_COMM_WIFI_INIT_GET_IP_ADDRESS", type: "warning" },
  110: { errorCode: "eERR_COMM_WIFI_INIT_OPEN", type: "warning" },
  111: { errorCode: "eERR_COMM_WIFI_CERT_CLIENT_ROUTER", type: "warning" },
  112: { errorCode: "eERR_COMM_WIFI_CERT_CLIENT_ROUTER_CA", type: "warning" },
  113: { errorCode: "eERR_COMM_WIFI_CERT_CLIENT_ROUTER_KEY", type: "warning" },
  114: { errorCode: "eERR_COMM_WIFI_CERT_EAP_CONFIG", type: "warning" },
  115: { errorCode: "eERR_COMM_WIFI_CERT_SERVER_TLS_CA", type: "warning" },
  116: { errorCode: "eERR_COMM_WIFI_CERT_SERVER_TLS_CONFIG", type: "warning" },
  117: { errorCode: "eERR_COMM_WIFI_CERT_LIST", type: "warning" },
  118: { errorCode: "eERR_COMM_WIFI_CERT_RESET_STORE", type: "warning" },
  120: { errorCode: "eERR_COMM_WIFI_OPEN_TCP", type: "warning" },
  121: { errorCode: "eERR_COMM_WIFI_CLOSE_TCP", type: "warning" },
  122: { errorCode: "eERR_COMM_WIFI_OPEN_UDP", type: "warning" },
  123: { errorCode: "eERR_COMM_WIFI_CLOSE_UDP", type: "warning" },
  124: { errorCode: "eERR_COMM_WIFI_OPEN_UDP_SERVER", type: "warning" },
  125: { errorCode: "eERR_COMM_WIFI_CLOSE_UDP_SERVER", type: "warning" },
  126: { errorCode: "eERR_COMM_WIFI_OPEN_TLS", type: "warning" },
  127: { errorCode: "eERR_COMM_WIFI_CLOSE_TLS", type: "warning" },
  129: { errorCode: "eERR_COMM_WIFI_SERVER_ALIVE", type: "warning" },
  130: { errorCode: "eERR_COMM_WIFI_SEND_FRAME_TEST", type: "warning" },
  131: { errorCode: "eERR_COMM_WIFI_SEND_FRAME_ALERT", type: "warning" },
  132: { errorCode: "eERR_COMM_WIFI_SEND_FRAME_GPS", type: "warning" },
  133: { errorCode: "eERR_COMM_WIFI_SEND_EPISODE", type: "warning" },
  134: { errorCode: "eERR_COMM_WIFI_SEND_TEST", type: "warning" },
  135: { errorCode: "eERR_COMM_WIFI_SEND_DATA", type: "warning" },
  136: { errorCode: "eERR_COMM_WIFI_RECEIVE_UPGRADE_FILE", type: "warning" },
  137: { errorCode: "eERR_COMM_WIFI_RECEIVE_CONFIG_FILE", type: "warning" },
  139: { errorCode: "eRR_COMM_WIFI_SERVER_RPS_ER", type: "warning" },
  140: { errorCode: "eERR_COMM_SIGFOX", type: "warning" },
  141: { errorCode: "eERR_COMM_SIGFOX_STATUS", type: "warning" },
  142: { errorCode: "eERR_COMM_SIGFOX_MAX_POWER", type: "warning" },
  143: { errorCode: "eERR_COMM_SIGFOX_RX", type: "warning" },
  144: { errorCode: "eERR_COMM_SIGFOX_TEST_TX_FREQ", type: "warning" },
  145: { errorCode: "eERR_COMM_SIGFOX_TEST_RX_FREQ", type: "warning" },
  146: { errorCode: "eERR_COMM_SIGFOX_CW_TEST_START", type: "warning" },
  147: { errorCode: "eERR_COMM_SIGFOX_CW_TEST_STOP", type: "warning" },
  148: { errorCode: "eERR_COMM_SIGFOX_RX_GFSK_TEST", type: "warning" },
  150: { errorCode: "eERR_COMM_GPS", type: "warning" },
  160: { errorCode: "eERR_COMM_ACC", type: "warning" },
  161: { errorCode: "eERR_COMM_ACC_PRESENCE", type: "warning" },
  162: { errorCode: "eERR_COMM_ACC_CONFIGURATION", type: "warning" },
  163: { errorCode: "eERR_COMM_ACC_INTERRUPT", type: "warning" },
  180: { errorCode: "eERR_KEYS", type: "error" },
  181: { errorCode: "eERR_LEDS", type: "error" },
  189: { errorCode: "eRR_CRYPTO_AUTH", type: "warning" },
  190: { errorCode: "eRR_EXPIRED_CERT_TLS", type: "warning" },
  191: { errorCode: "eRR_EXPIRED_SOON_CERT_TLS", type: "warning" },
  192: { errorCode: "eRR_EXPIRED_WPA_EAP", type: "warning" },
  193: { errorCode: "eRR_EXPIRED_SOON_WPA_EAP", type: "warning" },
};
