import React from "react";
import { Grid } from "@material-ui/core";
import DeviceDetailTab from "../DeviceTabs/DeviceDetailTab";

const Confirmation = ({ device }) => {
  return (
    <Grid item xs={12} container justify="center" style={{ marginTop: 24 }} spacing={2}>
      <DeviceDetailTab device={device} isNewDevice={true} />
    </Grid>
  );
};

export default Confirmation;
