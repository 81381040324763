import React, { useRef, useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { ExpandMore, Print } from "@material-ui/icons";
import ECG from "./index";
import { useTranslation } from "react-i18next";
import { grey } from "@material-ui/core/colors";
import ecgDoc from "../../assets/documents/20210728_163102585_a2.pdf";
import FakeECG from "./FakeECG";

const AcordionECG = ({ episodeInfo, is_demo, top, bottom }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const container = useRef(null);
  var moment = require("moment");
  const [firstOpen, setfirstOpen] = useState(false);

  const msToTime = (s) => {
    // Pad to 2 or 3 digits, default is 2
    function pad(n, z) {
      z = z || 2;
      return ("00" + n).slice(-z);
    }

    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    var total_time = "";

    if (hrs !== 0) {
      total_time += `${pad(hrs)}  ${t("common.hours")} `;
      //total_time += total_time + " " + pad(hrs) + " " + t("common.hours");
    }

    if (mins !== 0) {
      total_time += `${pad(mins)}  ${t("common.minutes")} `;
      //total_time = total_time + " " + pad(mins) + " " + t("common.minutes");
    }

    if (secs !== 0) {
      total_time += `${pad(secs)}.${pad(ms, 1)} ${t("common.seconds")}`;
      //total_time = total_time + " " + pad(secs) + " " + t("common.seconds");
    } else {
      total_time += `00.${pad(ms, 3)[0]} ${t("common.seconds")}`;
    }

    return total_time; //+ " " + pad(ms,3) + " " + t("common.miliseconds");

    //return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  };

  return (
    <Accordion
      ref={container}
      onClick={() => setfirstOpen(true)}
      style={{
        borderRadius: `${top ? "16px" : "0px"} ${top ? "16px" : "0px"} ${
          bottom ? "16px" : "0px"
        } ${bottom ? "16px" : "0px"}`,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>
          {t("device.episode.date", {
            date: moment(episodeInfo.episode_datetime).format("DD/MM/YYYY"),
          })}
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {t("device.episode.time", {
            time: moment(episodeInfo.episode_datetime).format("HH:mm:ss"),
          })}
        </Typography>
        <Typography className={classes.lastHeading}>
          {t("duration", { time: msToTime(episodeInfo.duration) })}
        </Typography>
        <Typography>
          <IconButton
            onClick={() => {
              var pdf = `${window.location.origin}/app/episodes/${episodeInfo.episode_id}/pdf`;
              if (is_demo) {
                pdf = ecgDoc;
              }
              window.open(pdf, episodeInfo.episode_id);
            }}
          >
            <Print />
          </IconButton>
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor: grey[50] }}>
        <Grid container justify="center">
          {is_demo && (
            <FakeECG container={container} pdf_uri={episodeInfo.episode_id} />
          )}
          {!is_demo && firstOpen && (
            <ECG container={container} pdf_uri={episodeInfo.episode_id} />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AcordionECG;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: "center",
    flexBasis: "30.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    alignSelf: "center",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    color: theme.palette.text.secondary,
  },
  lastHeading: {
    fontSize: theme.typography.pxToRem(15),
    alignSelf: "center",
    flexBasis: "33.33%",
    color: theme.palette.text.secondary,
  },
  paper: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: 2,
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
}));
