import React from "react";
import { AuthUserContext } from "../Session";
import * as ROUTES from "../../constants/routes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Typography } from "@material-ui/core";

const roles = [
  { id: "915c8c3c-849a-49e0-8032-ac4f9feb2956", name: "PROGRAMS_DELETE" },
  { id: "7fc44b6a-c81d-4423-beb9-ba92417ab865", name: "PROGRAMS_ADD" },
  { id: "7b309536-cf4c-47b3-8a97-71fe2f1a475e", name: "PROGRAMS_UPDATE" },
  { id: "26c214da-3bf8-410e-a861-804ddfb814d9", name: "DEVICE_ADD" },
];

const ProgramLink = () => {
  const { t } = useTranslation();
  return (
    <AuthUserContext.Consumer>
      {(authUser) => {
        if (authUser.roles.some((role) => roles.some((r) => role.id === r.id))) {
          return (
            <Link to={ROUTES.PROGRAMS} color="inherit">
              {t("navigation.programs")}
            </Link>
          );
        }
        return <Typography color="textPrimary">{t("navigation.programs")}</Typography>;
      }}
    </AuthUserContext.Consumer>
  );
};

export default ProgramLink;
