import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import * as API_KEYS from "../../constants/apiKeys";
import { Button, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import LoadingPage from "../LoadingPage";
import { red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const MapModal = (props) => {
  const classes = useStyles();

  const { handleClose, groups } = props;
  const [location, setlocation] = useState({});
  const [isLoaded, setisLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let lat = 0;
    let lng = 0;
    groups.map((group) => {
      lat += group.location.latitude;
      lng += group.location.longitude;
    });
    setlocation({ lat: lat / groups.length, lng: lng / groups.length });
    setisLoaded(true);
  }, []);

  return (
    <Grid item xs={12} lg={6} xl={8}>
      <Paper>
        <Grid container spacing={3} className={classes.formcontainer}>
          <Grid item xs={12} style={{ height: "80vh" }}>
            {!isLoaded ? (
              <LoadingPage />
            ) : (
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: API_KEYS.MAPS_API_KEY,
                }}
                defaultCenter={location}
                defaultZoom={10}
              >
                {groups.map((gr) => (
                  <LocationOn
                    style={{
                      color: red[500],
                      position: "absolute",
                      transform: "translate(-50%, -95%)",
                    }}
                    lat={gr.location.latitude}
                    lng={gr.location.longitude}
                  />
                ))}
              </GoogleMapReact>
            )}
          </Grid>

          <Grid itemn xs={12} container justify="flex-end" spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => handleClose(false)}>
                {t("common.close")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default MapModal;

const useStyles = makeStyles({
  formcontainer: {
    padding: 24,
  },
});
