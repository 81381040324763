import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as ROUTES from "../../constants/routes";

const NotEmptyProgramDialog = ({ close, open, programID }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Dialog open={open}>
      <DialogTitle>{t("Programa sin grupo")}</DialogTitle>
      <DialogContent>{t("Para poder añadir un cliente, es necesario tener al menos un grupo asignado al programa.")}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {t("common.close")}
        </Button>
        <Button variant="outlined" color="primary" disabled={!!!programID} onClick={() => history.push(ROUTES.PROGRAMS + "/" + programID + ROUTES.GROUPS)}>
          {t("Ver grupos")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotEmptyProgramDialog;
