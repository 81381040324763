import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const ErrorDialog = ({ errorMsg, clearError }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={errorMsg !== ""} onClose={clearError} fullWidth maxWidth={"xs"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t("error.connection.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{errorMsg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearError} variant="contained" color="primary">
          {t("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
