import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import BexenCardioLogo from "../../assets/images/0_6_BEXEN_Cardio_Vertic.png";

const LoadingPage = () => {
  return (
    <Grid container justify="center" alignItems="center" direction="column" style={{ height: "85vh" }}>
      <img src={BexenCardioLogo} style={{ width: "30vh", marginBottom: "-28vh" }} />
      <CircularProgress color={"secondary"} thickness={0.5} style={{ minHeight: "40vh", minWidth: "40vh" }} />
    </Grid>
  );
};

export default LoadingPage;
