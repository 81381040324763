import { Container, Grid, Tabs, Tab, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, DialogContentText, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeviceAlertTab from "../components/DeviceTabs/DeviceAlertTab";
import DeviceDetailTab from "../components/DeviceTabs/DeviceDetailTab";
import PageHeader from "../components/PageHeader";
import { ArrowBack, TrafficOutlined } from "@material-ui/icons";
import { withAuthorization } from "../components/Session";
import DeviceEpisodesTab from "../components/DeviceTabs/DeviceEpisodesTab";
import * as DATANETAPI from "../components/DataNet/DataNet";
import { AuthUserContext, withAuthentication } from "../components/Session";
import * as ROUTES from "../constants/routes";
import { red } from "@material-ui/core/colors";
import { DEVICE_DELETE_R100 } from "../constants/roles";
import ErrorDialog from "../components/ErrorDialog";
import ButtonLoader from "../components/ButtonLoader";
var _ = require("lodash/core");

const DevicePage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [device, setDevice] = useState();
  const [isDeviceLoaded, setIsDeviceLoaded] = useState(false);
  const [episodes, setEpisodes] = useState([]);
  const [isEpisodesLoaded, setIsEpisodesLoaded] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [isError, setIsError] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [isDeviceFound, setIsDeviceFound] = useState(false);
  const [canDelete100, setCanDelete100] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = () => {
    DATANETAPI.getDevice(id)
      .then((snap) => {
        if (snap?.status < 0) {
          setConnectionErrorMsg(t(`error.connection_${snap.status}`));
          setDevice();
          setIsDeviceFound(false);
          setIsDeviceLoaded(true);
        } else {
          if (snap.data !== undefined) {
            deviceBack = snap.data;

            DATANETAPI.getGroup(snap.data.groupid).then((snap) => {
              if (snap.status >= 0) {
                deviceBack.groupName = snap.data.name;
                setIsDeviceFound(true);
                setDevice(deviceBack);
                setIsDeviceLoaded(true);
              } else {
                setConnectionErrorMsg(t(`error.connection_${snap.status}`));
              }
            });
          } else {
            setDevice();
            setIsDeviceFound(false);
            setIsDeviceLoaded(true);
          }
        }
      })
      .catch((err) => {
        setDevice();
        setIsDeviceFound(false);
      });
  };

  useEffect(() => {
    let reaload = setInterval(() => {
      getData();
    }, 40 * 1000);

    return () => {
      clearTimeout(reaload);
    };
  }, []);

  useEffect(() => {
    setIsDeviceLoaded(false);
    getData();

    DATANETAPI.getEpisodes(id).then((snap) => {
      if (snap?.data !== null && snap?.data !== undefined && snap?.status === 1) {
        setEpisodes(snap.data);
        setIsEpisodesLoaded(true);
      } else {
        snap?.status < 0 && setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        setIsEpisodesLoaded(false);
        setEpisodes([]);
      }
    });
  }, [id]);

  const deleteDevice = () => {
    /** eliminar el dispositivo */
    setIsDeleting(true);
    DATANETAPI.deleteDevice(id, device.model).then((snap) => {
      if (snap?.status < 0) {
        setConnectionErrorMsg(t(`error.connection_${snap.status}`));
      } else {
        if (snap.data !== undefined) {
          if (snap.status == 1) {
            setOpenAlert(true);
            setIsError(false);
          } else {
            setOpenAlert(true);
            setIsError(true);
          }
        } else {
          //alert("Error API Request");
        }
      }
      setIsDeleting(false);
    });
    setDeleteModal(false);
  };

  const setupDeletePrivileges = (authUser) => {
    setCanDelete100(authUser.roles.some((x) => x.name_id === "DELETE_DEVICE_R100"));
  };

  if (isDeviceLoaded) {
    if (isDeviceFound) {
      /** dispositivo cargado y encontrado */
      return (
        <Grid container spacing={1} justify="center">
          {<AuthUserContext.Consumer>{(authUser) => !!authUser && setupDeletePrivileges(authUser)}</AuthUserContext.Consumer>}
          <Grid item xs={12} md={10} xl={8}>
            <Button style={{ marginTop: 24, fontSize: 30, fontSize: 16, textTransform: "none" }} color="primary" variant="text" onClick={() => history.goBack()}>
              <ArrowBack style={{ paddingRight: 6, paddingLeft: -6 }} />
              {t("common.goBack")}
            </Button>
            <PageHeader title={device.name} />
          </Grid>

          <Grid item xs={12} md={10} xl={8}>
            <Paper variant="outlined">
              <Tabs value={value} indicatorColor="primary" variant="fullWidth" onChange={handleChange}>
                <Tab label={t("device.tabs.details")} />
                <Tab label={t("device.tabs.episodes")} disabled={device.model != "Reanibex 100"} />
                <Tab label={t("device.tabs.alerts")} disabled={device.model != "Reanibex 100"} />
              </Tabs>
            </Paper>
          </Grid>

          <Grid item xs={12} md={10} xl={8}>
            <TabPanel value={value} index={0}>
              <DeviceDetailTab device={device} reload={getData} />

              {(device.model !== "Reanibex 100" || canDelete100) && (
                <Grid container spacing={2} style={{ marginTop: 24 }} justify="center">
                  <Grid item xs={12}>
                    <Paper className={classes.paper} variant="outlined">
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "30px", color: red[600] }}>{t("actions")}</Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                          <Grid item xs={6}>
                            <Typography variant="body1">{t("deletedevice")}</Typography>
                          </Grid>
                          <Grid item xs={6} container justify="flex-end">
                            <Button
                              variant="outlined"
                              disabled={deleteModal || isDeleting}
                              style={{ color: !deleteModal && !isDeleting && red[400], borderColor: !deleteModal && !isDeleting && red[400] }}
                              onClick={() => setDeleteModal(TrafficOutlined)}
                            >
                              {t("delete")}
                              {(isDeleting || deleteModal) && <ButtonLoader />}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              )}
              <Dialog onClose={() => setDeleteModal(false)} open={deleteModal}>
                <DialogTitle style={{ color: red[400] }}>{t("device.delete.title")}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{t("device.delete.body", { name: device.name })}</DialogContentText>
                  <Grid container justify="center">
                    <TextField
                      label={t("common.name")}
                      variant="outlined"
                      value={deviceName}
                      fullWidth
                      onChange={(e) => {
                        setDeviceName(e.target.value);
                      }}
                      style={{ marginTop: 6 }}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button disabled={deviceName !== device.name || isDeleting} style={{ color: deviceName === device.name && red[400] }} onClick={deleteDevice}>
                    {t("delete")}
                    {isDeleting && <ButtonLoader />}
                  </Button>
                </DialogActions>
              </Dialog>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DeviceEpisodesTab device={device} episodes={episodes} isEpisodesLoaded={isEpisodesLoaded} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DeviceAlertTab deviceId={id} device={device} />
            </TabPanel>
          </Grid>

          <Dialog open={openAlert}>
            <DialogTitle id="alert-dialog-title">{!isError ? t("devices.delete.dialog.title") : t("devices.delete.dialog.title.error")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{!isError ? t("devices.delete.dialog.description") : t("devices.delete.dialog.description.error")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenAlert(false);
                  if (!isError) history.goBack();
                }}
                variant="contained"
                color="primary"
              >
                {t("devices.new.dialog.agree")}
              </Button>
            </DialogActions>
          </Dialog>
          <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
        </Grid>
      );
    } else {
      /* dispositivo cargado pero no encontrado */
      return (
        <Grid container justify="center" alignItems="center" style={{ height: "85vh" }}>
          <Grid item xs={12} sm={10} md={6} xl={4} container justify="center">
            <Typography variant="h5" align="center">
              {t("deviceNotFoundTitle")}
            </Typography>
            <Button variant="contained" color="primary" style={{ textTransform: "none", marginTop: 16 }} onClick={() => history.push(ROUTES.DEVICES)}>
              {t("common.devices")}
            </Button>
          </Grid>
          <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
        </Grid>
      );
    }
  } else {
    /** dispositivo no cargado */
    return (
      <Grid container spacing={1} justify="center">
        <Grid item xs={12} md={10} xl={8}>
          <Button style={{ marginTop: 24, fontSize: 30, fontSize: 16 }} color="secondary">
            <ArrowBack />
            {" " + t("common.goBack")}
          </Button>
          <PageHeader title={""} />
        </Grid>

        <Grid item xs={12} md={10} xl={8}>
          <Paper variant="outlined">
            <Tabs value={0} indicatorColor="secondary" variant="fullWidth">
              <Tab label={t("device.tabs.details")} />
              <Tab label={t("device.tabs.episodes")} disabled />
              <Tab label={t("device.tabs.alerts")} disabled />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} md={10} xl={8}>
          <TabPanel value={value} index={0}>
            <DeviceDetailTab
              device={{
                location: { latitude: 0, longitude: 0 },
                battery: { info: { expiration_date: new Date() } },
                pads: { info: { expiration_date: new Date() } },
              }}
              IsPermanentLoading={true}
            />
          </TabPanel>
        </Grid>
        <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
      </Grid>
    );
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(DevicePage);

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 16,
    borderColor: red[200],
    padding: theme.spacing(2),
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
}));

var deviceBack = {
  id: 6,
  name: "R100 I+D",
  serial: "2020/20202414",
  manufacturer: "Bexen Cardio",
  disabled: false,
  conectivity: false,
  model: "Reanibex 100",
  status: { main: 0 },
  location: {
    name: "Osatu S.coop",
    latitude: 43.1854194,
    longitude: -2.5099712,
  },
  battery: {
    type: "t_battery",
    id: 124125,
    serial: "2415/21070356",
    expiration_date: "2022-02-05T11:00:07.865465",
    total: 1,
    used: 1,
    compatibility: ["Reanibex 100"],
  },
  pads: {
    type: "t_pads",
    id: 154127,
    serial: "50623-0773",
    reference: "KSA 0501 B",
    expiration_date: "2021-02-05T11:00:07.865465",
    total: 5,
    used: 3,
    compatibility: ["Reanibex 100"],
  },
  config: {
    device_setting: {
      langs: {
        default: 1,
        list: ["02_EN", "01_ES"],
      },
      cpr: {
        cpr1: 120,
        cpr2: 120,
        msg_long: 1,
      },
      metronome: {
        ratio_adult: 1,
        ratio_pediatric: 0,
        rate: 1,
      },
      shocks: {
        consecutive_shocks: 1,
        energy1_adult: 200,
        energy2_adult: 200,
        energy3_adult: 200,
        energy1_pediatric: 50,
        energy2_pediatric: 75,
        energy3_pediatric: 90,
      },
      patient: {
        patient_type: 1,
      },
      misc: {
        mains_filter: 50,
        utc_time: 1,
        volume: 2,
      },
      wifi: {
        eap: 1,
        ssid: "<SSID2>",
        pass: "<ENTER PASS>",
      },
    },
  },

  alerts: [
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-17T14:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-18T12:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-16T18:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-16T11:45:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-15T18:35:45",
      event_id: 5,
    },
    {
      event_type: 4,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-14T18:35:45",
      event_id: 5,
    },
    {
      event_type: 2,
      date: "2021/3/12",
      time: "25:07",
      event_datetime: "2020-02-14T10:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-13T13:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-13T06:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-11T22:35:45",
      event_id: 5,
    },
    {
      event_type: 1,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-11T12:35:45",
      event_id: 5,
    },
    {
      event_type: 2,
      date: "2020/2/17",
      time: "25:07",
      event_datetime: "2020-02-08T14:35:45",
      event_id: 5,
    },
  ],
};
