import { Button, Grid, IconButton, LinearProgress, Paper, Dialog, Typography, Menu, MenuItem } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { ArrowDropDownRounded, CheckRounded, DateRangeOutlined, FilterListRounded, Info, ReportProblem, Search, Usb, Wifi, FlashOn, OfflineBoltRounded } from "@material-ui/icons";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Skeleton } from "@material-ui/lab";
import exampleImage from "../../assets/images/alertExample.PNG";
import * as DATANETAPI from "../DataNet/DataNet";
import DeviceTabInfoHeader from "../DeviceTabInfoHeader";
import ErrorDialog from "../ErrorDialog";

const DeviceAlertTab = ({ deviceId, device }) => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [openExampleModal, setOpenExampleModal] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filteredDates, setFilteredDates] = useState({ start: new Date(), end: new Date() });
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");

  const searchAlerts = () => {
    /*setFilteredDates( { start: startDate, end: endDate })*/
    setLoading(true);
    getAlerts();
  };

  useEffect(() => {
    getAlerts();
  }, []);

  const handleFilterMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenFilterMenu(true);
  };

  const getAlerts = () => {
    /**Modificar para que pille las fechas de inicio y fin */
    /** los datos de inicio y fin estan en el obeto del estado "filteredDates" */

    DATANETAPI.getDeviceEvents(deviceId, filteredDates).then((snap) => {
      if (snap.status < 0) {
        setConnectionErrorMsg(t(`error.connection_${snap.status}`));
        setAlerts([]);
      } else {
        if (snap.data !== null && snap.data !== undefined && snap.status === 1) {
          setAlerts(snap.data.events);
        } else {
          setAlerts([]);
        }
      }
      setLoading(false);
    });
  };

  return (
    <Grid container spacing={3} style={{ marginTop: 12 }} justify="center">
      <Grid item xs={12}>
        <DeviceTabInfoHeader device={device} />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 16 }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div style={{ minHeight: 4 }}> {loading && <LinearProgress />}</div>
          <Paper variant="outlined" style={{ padding: 12, borderRadius: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} container spacing={1}>
                <Button onClick={handleFilterMenuClick}>
                  <FilterListRounded
                    style={{
                      height: 20,
                      paddingRight: 10,
                      margin: 0,
                      color: "#5f6368",
                    }}
                  />
                  <Typography variant="body1" style={{ color: "#5f6368", textTransform: "none" }}>
                    {t("alerts.allAlerts")}
                  </Typography>
                  <ArrowDropDownRounded style={{ color: "#5f6368" }} />
                </Button>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={openFilterMenu} onClose={() => setOpenFilterMenu(false)}>
                  <MenuItem onClick={() => setOpenFilterMenu(false)}>
                    <CheckRounded color="primary" style={{ marginRight: 15 }} />
                    <Typography variant="body1"> {t("alerts.allAlerts")}</Typography>
                  </MenuItem>
                </Menu>
              </Grid>

              <Grid item xs={12} container spacing={1} justify="center">
                <Grid item xs={6}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    openTo="year"
                    inputVariant="outlined"
                    fullWidth
                    views={["year", "month"]}
                    label={t("from")}
                    maxDate={new Date()}
                    value={startDate}
                    onChange={(value) => {
                      setStartDate(value);
                      setFilteredDates({ ...filteredDates, start: value });
                    }}
                  />
                </Grid>
                <IconButton
                  variant="outlined"
                  disabled
                  style={{
                    width: 34,
                    zIndex: 1,
                    borderRadius: "50%",
                    color: "#3c4043",
                    height: 34,
                    margin: "14px -13px",
                    padding: 5,
                    border: "1px solid #dadce0",
                    borderColor: "#3c4043",
                    backgroundColor: "white",
                  }}
                >
                  <DateRangeOutlined />
                </IconButton>

                <Grid item xs={6} style={{ marginRight: -8 }}>
                  <DatePicker
                    autoOk
                    variant="inline"
                    openTo="year"
                    inputVariant="outlined"
                    fullWidth
                    label={t("to")}
                    views={["year", "month"]}
                    maxDate={new Date()}
                    minDate={startDate}
                    minDateMessage={t("dateShouldNotBeBefore")}
                    value={endDate}
                    onChange={(value) => {
                      setEndDate(value);
                      setFilteredDates({ ...filteredDates, end: value });
                    }}
                  />
                </Grid>

                {/* <Grid
                  item
                  container
                  xs={12}
                  md={4}
                  justify="flex-end"
                  style={{ marginRight: -8 }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ height: 56 }}
                  >
                    {t("availableLastAlerts")}
                  </Button>
               </Grid>*/}
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginTop: 20,
                      marginBottom: -24,
                      borderRadius: 25,
                      textTransform: "none",
                      fontFamily: "Roboto",
                    }}
                    onClick={searchAlerts}
                  >
                    <Search style={{ paddingRight: 8 }} />
                    {t("search")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </MuiPickersUtilsProvider>

        <Grid alignItems="center" item xs={12} style={{ marginTop: 32 }}>
          <Typography
            variant="h3"
            style={{
              color: "#202124",
              letterSpacing: "normal",
              font: " 400 20px/24px 'Google Sans','Roboto'",
              marginBottom: 16,
            }}
          >
            {loading ? <Skeleton variant="rect" width={300} height={24} /> : `${moment(filteredDates.start).format("MMMM YYYY")} - ${moment(filteredDates.end).format("MMMM YYYY")}`}
          </Typography>
        </Grid>
      </Grid>

      {alerts.length === 0 && !loading && (
        <Grid item xs={12} style={{ paddingTop: 16 }}>
          <Paper variant="outlined" style={{ padding: 24, backgroundColor: red[100], borderRadius: 16 }} onClick={() => setOpenExampleModal(true)}>
            <Grid item xs={12} container justify="center">
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{
                    font: "500 16px/20px 'Google Sans',Roboto,Arial,sans-serif",
                  }}
                >
                  {t("device.tab.alerts.fake")}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      <Grid xs={12} container spacing={3}>
        {loading && [0, 1, 2, 3, 4, 5].map(() => <SkeletonAlertCard />)}
        {!loading &&
          alerts.map((alert) => (
            <>
              {alert.event_type > 1 && alert.event_type <= 6 && (
                <Grid item xs={12} sm={6} xl={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: "16px 0",
                      backgroundColor: alert.event_type == 4 && red[50],
                      borderRadius: 16,
                    }}
                  >
                    <Grid container alignItems="center" direction="row">
                      <Grid item xs={3} lg={3} container justify="center">
                        {alert.event_type == 4 && <ReportProblem fontSize="large" style={{ color: red[600] }} />}
                        {alert.event_type == 1 && <Usb fontSize="large" />}
                        {alert.event_type == 2 && <SettingsInputAntennaIcon fontSize="large" />}
                        {alert.event_type == 3 && <Wifi fontSize="large" />}
                        {alert.event_type == 6 && <Usb fontSize="large" />}
                        {(alert.event_type < 1 || alert.event_type > 4) && alert.event_type != 6 && <Info fontSize="large" />}
                      </Grid>
                      <Grid item xs={9} xl={7} container spacing={2} direction="column">
                        <Grid item container direction="row">
                          <Grid item xs={6} xl={12}>
                            <Typography variant="body1" color="secondary">
                              {t("device.tab.alerts.date")}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                              {moment(alert.event_datetime).format("YYYY / MM / DD")}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} xl={12}>
                            <Typography variant="body1" color="secondary">
                              {t("device.tab.alerts.time")}
                            </Typography>

                            <Typography variant="body1" color="textSecondary">
                              {alert.event_type == 2 || alert.event_type == 6 ? moment.utc(alert.event_datetime).local().format("HH : mm : ss") : moment(alert.event_datetime).format("HH : mm : ss")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="secondary">
                            {t("device.tab.alerts.desciption")}
                          </Typography>
                          {alert.event_type == 2 && (
                            <>
                              {alert.event_sigfox_version == undefined || alert.event_sigfox_version == 0 ? (
                                <Typography>{t("device.tab.alerts.eSYGFOX_1_" + alert.event_id)}</Typography>
                              ) : (
                                <Typography>{t("device.tab.alerts.eSYGFOX_" + alert.event_sigfox_version + "_" + alert.event_id)}</Typography>
                              )}
                            </>
                          )}
                          {alert.event_type == 4 && <Typography>{t("device.tab.alerts.eERR_" + alert.event_id)}</Typography>}
                          {alert.event_type == 6 && <Typography>{t("device.tab.alerts.patient_usage")}</Typography>}
                          {alert.event_type != 4 && alert.event_type != 2 && alert.event_type != 6 && <Typography>{t("device.tab.alerts.eREG_" + alert.event_id)}</Typography>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            </>
          ))}
      </Grid>
      <Dialog open={openExampleModal} onClose={() => setOpenExampleModal(false)}>
        <img src={exampleImage} />
      </Dialog>
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
    </Grid>
  );
};

export default DeviceAlertTab;

const SkeletonAlertCard = () => (
  <Grid item xs={12} lg={6} xl={4}>
    <Paper
      variant="outlined"
      style={{
        padding: "16px 0",
      }}
    >
      <Grid container alignItems="center" direction="row">
        <Grid item xs={3} container justify="center">
          <Skeleton variant="circle" width={44} height={44} />
        </Grid>
        <Grid item xs={11} lg={9} xl={7} container spacing={2} direction="column">
          <Grid item container direction="column">
            <Grid item xs={6} xl={12}>
              <Typography variant="body1" color="secondary">
                <Skeleton />
              </Typography>
              <Typography variant="body1" color="textSecondary">
                <Skeleton />
              </Typography>
            </Grid>
            <Grid item xs={6} xl={12}>
              <Typography variant="body1" color="secondary">
                <Skeleton />
              </Typography>
              <Typography>
                <Skeleton />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} xl={12}>
            <Typography variant="body1" color="secondary">
              <Skeleton />
            </Typography>
            <Typography>
              <Skeleton />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);
