import { FormControl, Select, MenuItem, InputLabel, Snackbar, List, ListSubheader, ListItem, ListItemText, Grid, Button, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as DATANET from "../DataNet/DataNet";
import { Alert, AlertTitle } from "@material-ui/lab";
import ErrorDialog from "../ErrorDialog";
import { AuthUserContext } from "../Session";
import ButtonLoader from "../ButtonLoader";
import { R100ConfigChangedModal } from "../R100ConfigChangedModal";
import getDeviceComunications from "../../functions/getDeviceComunications";

const MoveDeviceSelect = ({ device, user, setNewGroup }) => {
  const { t } = useTranslation();

  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(device?.groupid ?? 0);
  const [selectedProgram, setSelectedProgram] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [responseState, setResponseState] = useState(0); // 0 off, -1 err , 1 ok
  const [responseMSG, setResponseMSG] = useState("");
  const [openWarningSettingsModal, setOpenWarningSettingsModal] = useState(false);
  const [isFormInitialized, setisFormInitialized] = useState(false);

  const handleGroup = (e) => {
    setSelectedGroup(e.target.value);
    setResponseState(0);
  };

  const handleProgram = (e) => {
    const newValue = e.target.value;
    if (newValue !== selectedProgram) setSelectedGroup(0);
    setSelectedProgram(newValue);
    setResponseState(0);
  };

  function findProgram(id, programList) {
    setisFormInitialized(true);
    return programList.findIndex((p) => p.id === id);
  }

  useEffect(() => {
    console.log("voy a cambiar los datos del programa", !!user && !!device);
    !!user && !!device && !isFormInitialized && setSelectedProgram(findProgram(device.programid, user.programas));
  }, [user, device]);

  const moveGroup = () => {
    setIsLoading(true);
    if (device.model == "Reanibex 100") {
      setOpenWarningSettingsModal(true);
    } else {
      confirmMove(true);
    }
  };

  const confirmMove = (confirm) => {
    setOpenWarningSettingsModal(false);
    if (confirm) {
      DATANET.editDevice({ device: { ...device, groupid: selectedGroup, programid: user?.programas[selectedProgram]?.id }, groupid: device.groupid, programid: device.programid }, device.id).then(
        (snap) => {
          console.log("consulta de mover", snap);
          if (snap.status < 0) {
            setConnectionErrorMsg(t(`error.connection_${snap.status}`));
          } else {
            setIsLoading(false);
            if (snap.status != 1) {
              setResponseState(snap.status);
              setResponseMSG(t(`moveDeviceStatus_${snap.status}`));
            } else {
              setNewGroup(selectedGroup, user?.programas[selectedProgram]?.id);
              setResponseState(1);
              setResponseMSG(t("El equipo se ha movido correctamente"));
            }
          }
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  console.log("Tipo de coms del grupo", selectedGroup, user?.programas[selectedProgram], user?.programas[selectedProgram]?.groups.filter((g) => g.id == selectedGroup)[0]?.comsTypes);

  return (
    <>
      <Grid item xs={12} md={5}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="programselect_label">{t("common.program")}</InputLabel>
          <Select
            labelId="programselect_label"
            id="programselect"
            error={responseState == -1}
            value={selectedProgram}
            onChange={handleProgram}
            label={t("common.program")}
            disabled={user?.programas.length == 1}
            MenuProps={{
              style: {
                maxHeight: 320,
              },
            }}
          >
            {/*!!groupList && groupList.map((e) => e.program_id == device.programid && <MenuItem value={e.id}>{e.name}</MenuItem>)*/}
            {user?.programas?.map((e, i) => (
              <MenuItem value={i}>{e.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={5}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="GroupSelect_label">{t("common.group")}</InputLabel>
          <Select
            labelId="GroupSelect_label"
            id="GroupSelect"
            value={selectedGroup}
            error={responseState == -1}
            onChange={handleGroup}
            label={t("common.group")}
            MenuProps={{ style: { maxHeight: 320 } }}
          >
            <MenuItem value={0} disabled>
              {t("seleccione grupo")}
            </MenuItem>

            {user?.programas[selectedProgram]?.groups.map((e) => (
              <MenuItem value={e.id} disabled={!e.comsTypes?.every((t) => getDeviceComunications(device?.options).includes(t))}>
                {console.log("select", e.comsTypes, getDeviceComunications(device?.options))}
                {e.name}
                {!e.comsTypes?.every((t) => getDeviceComunications(device?.options).includes(t)) && " · Tipos no compatibles"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Tooltip
        title={
          device.groupid === selectedGroup || selectedGroup === 0 || isLoading || responseState !== 0
            ? t("Selecciona un grupo o programa diferente para poder mover el dispositivo")
            : t("Pulsa para mover el dispositivo")
        }
      >
        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            style={{ height: "100%" }}
            disabled={device.groupid === selectedGroup || selectedGroup === 0 || isLoading || responseState !== 0}
            onClick={moveGroup}
          >
            {isLoading && <ButtonLoader />}
            {t("mover")}
          </Button>
        </Grid>
      </Tooltip>

      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} severity={responseState == 1 ? "success" : "error"} open={responseState != 0}>
        <Alert onClose={() => setResponseState(0)} severity={responseState == 1 ? "success" : "error"}>
          {responseMSG}
        </Alert>
      </Snackbar>
      <ErrorDialog errorMsg={connectionErrorMsg} clearError={() => setConnectionErrorMsg("")} />
      <R100ConfigChangedModal open={openWarningSettingsModal} handleClose={confirmMove} />
    </>
  );
};

export default MoveDeviceSelect;
