import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { FlashOn, Notifications } from "@material-ui/icons";
import { red, grey } from "@material-ui/core/colors";
import LoadingPage from "../LoadingPage";
import moment from "moment";
// getNotificationHistory

const DeviceUsageHistory = ({ notifications }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        <Typography variant="h6" color="secondary" style={{ padding: "30px 20px 10px 10px" }}>
          {t("use_notifications.history.title")}
        </Typography>
      </Grid>
      {/*!isNotificationsLoaded && <LoadingPage/>*/}
      {notifications.length === 0 ? (
        <EmptyNotification t={t} />
      ) : (
        <>
          {[...notifications].reverse().map((x) => (
            <UseNotification date={x.creation_date} t={t}></UseNotification>
          ))}
        </>
      )}
    </>
  );
};

const EmptyNotification = ({ t }) => {
  return (
    <Grid item xs={12} container alignItems="center">
      <Typography variant="body1" color="textPrimary" style={{ padding: "2px 20px 2px 4px" }}>
        {t("use_notifications.history.empty")}
      </Typography>
    </Grid>
  );
};

const UseNotification = ({ date, t }) => (
  <Grid item xs={12} container alignItems="center">
    <FlashOn style={{ marginRight: 16, color: grey[600] }} fontSize="medium" />
    <Typography variant="body1" color="secondary" style={{ padding: "2px 20px 2px 0px" }}>
      {t("use_notifications.item")}
    </Typography>
    <Typography variant="body1" color="textPrimary">
      {moment.utc(date).local().format("YYYY-MM-DD HH:mm:ss")}
    </Typography>
  </Grid>
);

export default DeviceUsageHistory;
