import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Typography,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";

const AddUser = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [email, setEmail] = useState();

  return (
    <>
      <Dialog
        id="accessory_add_dialog"
        fullScreen
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("user.new.title")}
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container justify="center" style={{ marginTop: 24 }}>
          <Grid container spacing={3} item xs={12} lg={9}>
            <Grid item xs={12}>
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  marginTop: 18,
                }}
              >
                {t("user.new.userDate")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("user.new.email.label")}
                placeholder={t("user.new.email.placeholder")}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("user.info.password")}
                placeholder={t("user.info.password")}
                variant="outlined"
                type="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="userLastNAme"
                label={t("user.info.repeatPassword")}
                placeholder={t("user.info.repeatPassword")}
                variant="outlined"
                type="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("user.new.name.label")}
                placeholder={t("user.new.name.placeholder")}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("user.new.last_name.label")}
                placeholder={t("user.new.last_name.placeholder")}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("user.new.phone.label")}
                placeholder={t("user.new.phone.placeholder")}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("user.info.edit.role.label")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label={t("user.info.edit.role.label")}
                >
                  <MenuItem value={10}>Admin</MenuItem>
                  <MenuItem value={20}>Coordinaor</MenuItem>
                  <MenuItem value={30}>usuario</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  marginTop: 18,
                }}
              >
                {t("user.new.asignedGroup.title")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AddUser;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
