import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Dialog, AppBar, Toolbar, Slide, Typography, IconButton, TextField, Checkbox, FormControlLabel, FormGroup, FormControl, FormLabel, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const AddAccessory = ({ open, type, models, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [accType, setaccType] = useState(type);
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [isExpirationDateCorrect, setisExpirationDateCorrect] = useState(true);
  const [compatibility, setCompatibility] = useState([]);
  const [total, setTotal] = useState(1);
  //const [isTotalCorrect, setisTotalCorrect] = useState(false);
  const [serial, setserial] = useState("");
  const [reference, setreference] = useState("");

  const handleDateChange = (date) => {
    setExpirationDate(date);
    setisExpirationDateCorrect(true);
  };

  const handleTotal = (event) => {
    const value = event.target.value;
    //setisTotalCorrect(value >= 0);
    setTotal(value < 1 ? 1 : value);
  };

  const handleReference = (event) => {
    setreference(event.target.value);
  };

  const handleCompatibility = (event) => {
    const name = event.target.name;
    event.target.checked ? compatibility.push(event.target.name) : compatibility.splice(compatibility.indexOf(name));
    setCompatibility(compatibility);
  };

  const handleSerial = (event) => {
    setserial(event.target.value);
  };

  var isFormValid = compatibility.length > 0 && total > 0 && serial !== "" && isExpirationDateCorrect;

  return (
    <>
      <Dialog id="accessory_add_dialog" fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => handleClose(false)} aria-label="close">
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("accessories.new.title")}
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container justify="center" style={{ marginTop: 24 }}>
          <Grid container spacing={3} item xs={12} lg={9}>
            <Grid item xs={12}>
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  marginTop: 18,
                }}
              >
                {type == "t_battery" ? t("accessories.new.battery") : t("accessories.new.pads")}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <TextField
                value={serial}
                label={type == "t_battery" ? t("common.serial") : t("common.lot")}
                placeholder={type == "t_battery" ? t("common.serial") : t("common.lot")}
                variant="outlined"
                onChange={handleSerial}
                fullWidth
              />
            </Grid>

            {type == "t_pads" && (
              <Grid item xs={12} lg={3}>
                <TextField
                  value={reference}
                  label={t("common.reference")}
                  placeholder={t("common.reference")}
                  variant="outlined"
                  onChange={handleReference}
                  //disabled={model !== "other"}
                  fullWidth
                />
              </Grid>
            )}

            <Grid item xs={12} lg={3}>
              <TextField
                value={total}
                label={t("accessories.new.ammount")}
                placeholder={t("accessories.new.totalPads")}
                variant="outlined"
                //error={!isTotalCorrect}
                type="number"
                onChange={handleTotal}
                disabled={type == "t_battery"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  label={t("device.details.accesories.expirationDate")}
                  format="MM/yyyy"
                  onInvalid={() => setisExpirationDateCorrect(false)}
                  views={["year", "month"]}
                  invalidDateMessage={t("accessories.new.invalidDate")}
                  minDateMessage={t("accessories.new.invalidDateMin")}
                  minDate={new Date()}
                  value={expirationDate}
                  onChange={handleDateChange}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 36 }}>
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  fontSize: 22,
                  marginTop: 18,
                }}
              >
                {t("common.compatibility")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormGroup row>
                  {models.map((model) => (
                    <FormControlLabel control={<Checkbox onChange={handleCompatibility} name={model} />} label={model} />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} container justify="flex-end">
              <Button variant="contained" color="primary" disabled={!isFormValid} onClick={() => handleClose(false)}>
                {t("common.add")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {/*
      <Dialog open={openAlert}>
        <DialogTitle id="alert-dialog-title">
          {t("devices.new.dialog.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("devices.new.dialog.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)} color="primary">
            {t("devices.new.dialog.disagree")}
          </Button>
          <Button
            onClick={() => {
              setOpenAlert(false);
              resetForm();
            }}
            variant="contained"
            style={{ backgroundColor: red[500], color: "white" }}
            autoFocus
          >
            {t("devices.new.dialog.agree")}
          </Button>
        </DialogActions>
        </Dialog>*/}
    </>
  );
};

export default AddAccessory;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
