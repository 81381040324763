import React from "react";
import { MenuItem, Select, InputLabel, FormControl, FormHelperText } from "@material-ui/core";

const SelectConfig = ({ values, value, onChange, label, info, auto, kv, plusone, error, helperText }) => {
  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} onChange={onChange} defaultValue={""}>
        {!!info && <MenuItem value="">{info}</MenuItem>}
        {auto && values.map((v, i) => <MenuItem value={i}>{v}</MenuItem>)}
        {!auto && !kv && !plusone && values.map((v) => <MenuItem value={v}>{v}</MenuItem>)}
        {kv && values.map((e) => <MenuItem value={e?.key}>{e?.value}</MenuItem>)}
        {plusone && values.map((v, i) => <MenuItem value={i + 1}>{v}</MenuItem>)}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectConfig;
